import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  isEmpty,
} from 'lodash';
import {
  API,
  AdCategoryHelper,
  Field2 as Field,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';

class Information extends PureComponent {
  constructor(props) {
    super(props);

    const {
      user,
      partnerId,
    } = props;

    const canMessageUpload = user.can('message.upload');
    const canAdvertisementUpload = user.can('advertisement.upload');
    const canAdvertisementRequest = user.can('advertisement.request');

    const messageTypeOptions = [
      {
        value: '',
        label: '',
      },
    ];

    switch (user.accountType) {
      case 'standard':
        messageTypeOptions.push({
          value: 'std',
          label: 'Regular',
        });
        if (canMessageUpload) {
          // Allow the user to upload a regular message
          messageTypeOptions.push({
            value: 'ext',
            label: 'Regular (Upload)',
          });
        }

        if (user.isAdminCompany) {
          if (canAdvertisementRequest) {
            // Allow user to create an Advertisement
            messageTypeOptions.push({
              value: 'ad',
              label: 'Ad',
            });
          }

          if (canAdvertisementUpload) {
            // Allow user to upload an Advertisement
            messageTypeOptions.push({
              value: 'ext-ad',
              label: 'Ad (Upload)',
            });
          }

          // Allow user to create an Air Check
          messageTypeOptions.push({
            value: 'air',
            label: 'Air Check',
          });

          // Allow user to create an Spec Check
          messageTypeOptions.push({
            value: 'spec',
            label: 'Spec Spot',
          });
        }
        break;

      case 'partner':
        if (canAdvertisementRequest) {
          // Partner User that can create ads
          messageTypeOptions.push({
            value: 'ad',
            label: 'Ad',
          });
        } else if (!isEmpty(partnerId)) {
          messageTypeOptions.push({
            value: 'ad',
            label: 'Ad',
          });
        }

        if (canAdvertisementUpload) {
          // Allow the user to upload an ad
          messageTypeOptions.push({
            value: 'ext-ad',
            label: 'Ad (Upload)',
          });
        } else if (!isEmpty(partnerId)) {
          messageTypeOptions.push({
            value: 'ext-ad',
            label: 'Ad (Upload)',
          });
        }
        break;
      default:
        break;
    }

    this.state = {
      messageTypeOptions,
    };
  }

  /**
   * When a field is changed
   */
  onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const {
      onUpdate,
    } = this.props;

    onUpdate({
      [name]: value,
    });
  };

  /**
   * When the message type field is changed
   */
  onChangeType = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const {
      type,
      onUpdate,
    } = this.props;

    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    const data = {
      [name]: value,
      advertiserId: '',
      advertiserName: '',
    };

    if (value === 'air' || value === 'spec') {
      // Air Check, Spec Spot force location to Vibenomics ACAF
      // Force company to admin company
      data.companyId = adminCompanyId;
      data.companyName = 'Vibenomics, Inc.';

      data.startDate = '';
      data.endDate = '';
      data.locations = [{
        companyId: adminCompanyId,
        locationId: '*',
      }];
      data.locationsData = {
        companies: [{
          _id: adminCompanyId,
          locations: [],
          locationCount: '*',
        }],
        locations: [],
      };
      data.messageLists = [];
      data.messageBlocks = [];
    } else if (type === 'air' || type === 'spec') {
      // switching from ad (upload), air, or spec to not air, or spec, clear locations
      data.locations = [];
      data.locationsData = { companies: [], locations: [] };
    }

    if (value !== 'ad' || value !== 'ext-ad') {
      // If the previous type was ad, hide the selector
      // Remove the advertiser if one was selected
      data.advertiserId = '';
      data.advertiserName = '';
    }

    // if (value !== 'ad' || value !== 'ext-ad') {
    //   // If the previous type was ad, empty the company
    //   data.companyId = '';
    //   data.companyName = '';
    // }

    data.file = undefined;

    onUpdate(data);
  };

  /**
   * When the tag field is changed
   */
  onChangeTag = (data) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate(data);
  };

  onChangeDropdown = ({
    name,
    value,
    // data,
  }) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate({
      [name]: value,
    });
  };

  /**
   * When the company field is changed
   */
  onSetCompany = ({
    id: companyId,
    value: companyName,
  }) => {
    const {
      type,
      companyId: currCompanyId,
      tags,
      onUpdate,
    } = this.props;

    const data = !companyId
      ? {
        allLocations: false,
        locations: [],
        locationsData: {
          companies: [],
          locations: [],
        },
        tags: currCompanyId !== companyId
          ? tags.filter(tag => tag.type === 'admin')
          : tags,
      }
      : {};

    // force ACAF locations if an external ad request
    if (type === 'ext-ad') {
      data.locations = [{
        companyId,
        locationId: '*',
      }];

      data.locationsData = {
        companies: [{
          _id: companyId,
          name: companyName,
          locations: [],
          locationCount: '*',
        }],
        locations: [],
      };
    }

    onUpdate({
      companyId,
      companyName,
      ...data,
    });
  };

  /**
   * When the advertiser field is changed
   */
  onChangeAdvertiser = (data) => {
    const {
      onUpdate,
    } = this.props;

    const {
      id,
      value,
    } = data;

    const updateData = {
      advertiserName: value,
    };

    if (id) {
      updateData.advertiserId = id;
    }

    onUpdate(updateData);
  };

  isCompanyDisabled = () => {
    const {
      type,
      isNew,
      user,
    } = this.props;

    if (!isNew
      || user.accountType === 'partner'
      || (type === 'ad' || type === 'ext-ad' || type === 'air' || type === 'spec')
    ) {
      return true;
    }

    return false;
  };

  render() {
    const {
      user,
      name,
      type,
      companyId,
      companyName,
      partnerId,
      partnerName,
      advertiserName,
      category,
      tags,
      voiceTalentId,
      approverType,
      disableInput,
      isNew,
    } = this.props;

    const {
      messageTypeOptions,
    } = this.state;

    const isVoiceTalent = user.voiceTalent && user._id === voiceTalentId;
    const canSetCompany = user.sysAdmin;

    const isAd = type === 'ad' || type === 'ext-ad';
    const showPartner = isAd && user.can('admin.ism_admin');
    const showCompany = !isAd && (canSetCompany || isVoiceTalent);
    const showAdSelector = isAd;

    return (
      <div className="Information">
        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            className="details-title"
            xs={6}
            item
          >
            Information
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={showCompany || showPartner
              ? 6
              : 12}
            item
          >
            <Field
              type="text"
              label="Message Request Name"
              name="name"
              placeholder="Message Request Name"
              value={name}
              tabIndex={1}
              disabled={disableInput}
              onChange={this.onChange}
              required
              autoFocus
            />
          </Grid>

          {showCompany && (
            <Grid xs={6} item>
              <Field
                type="company"
                label="Company"
                name="companyName"
                value={companyName}
                tabIndex={2}
                companyProps={{
                  companyId,
                  companyName,
                }}
                disabled={this.isCompanyDisabled()}
                onChange={this.onSetCompany}
                required
              />
            </Grid>
          )}

          {showPartner && (
            <Grid xs={6} item>
              <Field
                type="partner"
                label="Partner"
                name="partnerName"
                value={partnerName}
                tabIndex={2}
                partnerProps={{
                  partnerId,
                  partnerName,
                }}
                disabled
                required
              />
            </Grid>
          )}
        </Grid>

        {!isVoiceTalent && (
          <>
            <Grid
              className="row"
              spacing={2}
              container
            >
              <Grid xs={6} item>
                <Field
                  type={messageTypeOptions.length > 1
                    ? 'select'
                    : 'hidden'}
                  label="Type"
                  name="messageType"
                  value={type}
                  tabIndex={3}
                  options={messageTypeOptions}
                  disabled={disableInput || !isNew}
                  onChange={this.onChangeType}
                  required
                />
              </Grid>

              {showAdSelector && (
                <>
                  <Grid xs={6} item>
                    <Field
                      type="dropdown"
                      label="Advertiser"
                      name="advertiser"
                      value={advertiserName}
                      tabIndex={4}
                      dropdownProps={{
                        type: 'advertiser',
                        attr: 'name',
                        fetch: API.Advertiser.list,
                        create: API.Advertiser.create,
                        canCreate: user.can('advertiser.create'),
                        successMessage: 'ADVERTISER.CREATED',
                      }}
                      disabled={disableInput || !isNew}
                      onChange={this.onChangeAdvertiser}
                      required
                    />
                  </Grid>

                  <Grid xs={12} item>
                    <Field
                      type="dropdown"
                      label="Ad Category"
                      name="category"
                      value={category}
                      dropdownProps={{
                        type: 'category',
                        attr: 'value',
                        collection: AdCategoryHelper.list(),
                      }}
                      onChange={this.onChangeDropdown}
                      tabIndex={4}
                    />
                  </Grid>
                </>
              )}
            </Grid>

            {approverType !== 'partner' && (
              <Grid
                className="row"
                spacing={2}
                container
              >
                <Grid xs={12} item>
                  <Field
                    type="tags"
                    label="Tags"
                    name="tags"
                    tagProps={{
                      companyId,
                      tags,
                      allowTypes: ['client', 'admin'],
                      showAs: 'list',
                      assign: user.can('message.assign_tag'),
                    }}
                    disabled={disableInput}
                    onChange={this.onChangeTag}
                    tabIndex={5}
                  />
                </Grid>
              </Grid>
            )}
          </>
        )}
      </div>
    );
  }
}

Information.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  partnerId: PropTypes.string,
  partnerName: PropTypes.string,
  advertiserName: PropTypes.string,
  category: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  voiceTalentId: PropTypes.string,
  disableInput: PropTypes.bool,
  isNew: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Information.defaultProps = {
  name: '',
  type: 'std',
  companyId: '',
  companyName: '',
  partnerId: '',
  partnerName: '',
  advertiserName: '',
  category: '',
  tags: {},
  voiceTalentId: '',
  disableInput: false,
  isNew: false,
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Information);
