import React, { Component } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import {
  GlobalActions,
  NavigationHelper,
  API,
  TableMessages,
  viAdd,
  withRouter,
} from 'vibeguide';
import MessageSidebar from '../NewMessageRequest/Sidebar/MessageSidebar';
import SubNavigation from '../../Header/SubNavigation';
import './RequestsContainer.scss';

export class RequestsContainerClass extends Component {
  constructor(props) {
    super(props);

    this.sidebarTimeout = null;

    this.state = {
      numWorkQueue: 0,
      numAwaitingApproval: 0,
    };

    // listen for when sidebar data changes
    document.addEventListener('onSaveMessage', this.onSave);
  }

  componentDidMount() {
    const qs = NavigationHelper.getParams() || {};
    const isNew = qs.type === 'new';
    const isView = qs.messageId && qs.type !== 'new';

    this.getRequestCounts();

    if (isNew || isView) {
      this.sidebarTimeout = setTimeout(() => {
        this.sidebar(qs.type, qs.messageId);
      }, 1500);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        search,
      },
    } = this.props;

    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.messageId || qs.type === 'new') {
        this.sidebar(qs.type, qs.messageId);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onSaveMessage', this.onSave);
    clearTimeout(this.sidebarTimeout);
  }

  /**
   * When the sidebar is closed
   */
  onCloseSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      messageId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  /**
   * When a message is saved, fetch new counts
   */
  onSave = () => {
    this.getRequestCounts();
  };

  /**
   * Get the number of requests in each category
   */
  getRequestCounts = async () => {
    const {
      user,
    } = this.props;

    const isVoiceTalent = user.voiceTalent;

    if (isVoiceTalent) {
      // No need to fetch counts for voice talents since only 1 category is shown
      return;
    }

    const getWorkQueue = this.getFetchMethod();
    const workQueue = await getWorkQueue({
      filters: {
        active: true,
      },
    });
    const awaitingApproval = user.can('message.request')
      ? await API.MessageRequest.listReview({
        filters: {
          active: true,
        },
      })
      : {};

    this.setState({
      numWorkQueue: get(workQueue, 'totalItems', 0),
      numAwaitingApproval: get(awaitingApproval, 'totalItems', 0),
    });
  };

  /**
   * Get the fetch method for the table
   */
  getFetchMethod = () => {
    const {
      user,
    } = this.props;

    const isContentCurator = user.can('message.content_curator');
    const isVoiceTalent = user.voiceTalent;

    if (isContentCurator) {
      return API.MessageRequest.listCuration;
    }

    if (isVoiceTalent) {
      return API.MessageRequest.listRough;
    }

    return API.MessageRequest.listProcessing;
  };

  getColumns = () => {
    const {
      user,
    } = this.props;

    const isISMAdmin = user.accountType !== 'partner' && user.can('admin.ism_admin');
    const isContentCurator = user.can('message.content_curator');
    const isClientUser = user.accountType !== 'partner';

    if (isISMAdmin || isContentCurator) {
      return [
        '.',
        'Message Name',
        'Company',
        'Partner',
        'Requester',
        'Step',
        'Due Date',
        'Requested',
        'Last Updated',
        'Type',
        'Script',
        'Script Type',
        'Assigned Voice Talent',
        'Tags',
        '...',
      ];
    }

    if (isClientUser) {
      return [
        '.',
        'Message Name',
        'Company',
        'Requester',
        'Step',
        'Due Date',
        'Requested',
        'Last Updated',
        'Type',
        'Script',
        'Script Type',
        'Assigned Voice Talent',
        'Tags',
        '...',
      ];
    }

    return [
      '.',
      'Message Name',
      'Requester',
      'Step',
      'Due Date',
      'Requested',
      'Last Updated',
      'Type',
      'Script',
      'Script Type',
      'Assigned Voice Talent',
      'Tags',
      '...',
    ];
  };

  /**
   * Determine whether or not to show or hide the sidebar
   */
  sidebar = (type, messageId = null) => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: true,
      backdrop: type === 'new',
      children: (
        <MessageSidebar
          type="request"
          messageId={messageId}
          isNew={type === 'new'}
        />
      ),
      onClose: this.onCloseSidebar,
    });
  };

  render() {
    const {
      user,
    } = this.props;

    const {
      numWorkQueue,
      numAwaitingApproval,
    } = this.state;

    const fetchMethod = this.getFetchMethod();

    const columns = this.getColumns();

    return (
      <div className={classNames('MessageRequestsContainer', { 'hide-side-menu': user.voiceTalent })}>
        <SubNavigation
          title="Message Requests"
          buttons={[
            {
              text: 'New Message',
              helpText: 'Request or upload a new message.',
              icon: viAdd,
              url: NavigationHelper.updateParams({
                type: 'new',
              }),
              userAnyOf: [
                'message.request',
                'advertisement.request',
              ],
            },
          ]}
        />

        <TableMessages
          columns={columns}
          messageRequest
          fetch={fetchMethod}
          defaultSortBy={{
            label: 'Requested',
            attr: 'createdDate',
            direction: 'desc',
          }}
          paginator
          paginatorProps={{
            label: 'Messages',
            urlPaging: true,
            urlFilters: true,
          }}
          toggle
          toggleProps={{
            label: 'Show Archived Messages',
            tooltip: true,
          }}
          bulk
          bulkProps={{
            edit: false,
            add: false,
            block: false,
            remove: false,
            archive: true,
          }}
          submenu
          submenuProps={{
            rootUrl: '/messages/requests',
            items: [
              {
                name: 'Work Queue',
                label: `Work Queue ${numWorkQueue > 0 ? `(${numWorkQueue})` : ''}`,
                show: user.can('message.view') || user.can('advertisement.view'),
                enabled: true,
                paginatorLabel: 'Messages',
              },
              {
                name: 'Awaiting Approval',
                label: `Awaiting Approval ${numAwaitingApproval > 0 ? `(${numAwaitingApproval})` : ''}`,
                // custom columns for the tab (remove the step column)
                columns: columns.filter(column => column !== 'Step'),
                show: (
                  (user.can('message.view') && user.hasAnyOf(['message.request', 'message.approve']))
                  || (user.can('advertisement.view')
                    && user.hasAnyOf(['advertisement.request', 'advertisement.approve']))
                ),
                enabled: true,
                fetch: API.MessageRequest.listReview,
              },
            ],
          }}
          rowLink={{
            messageId: '_id',
          }}
          menuItems={(row) => {
            const isAd = row.messageType === 'ad'
              || row.messageType === 'ext-ad';

            return [{
              name: 'Archive',
              userCan: isAd
                ? 'advertisement.delete'
                : 'message.delete',
            }];
          }}
        />
      </div>
    );
  }
}

// RequestsContainerClass.propTypes = {
//   /* URL Parameter Match */
//   match: PropTypes.shape({
//     isExact: PropTypes.bool.isRequired,
//     params: PropTypes.shape({}).isRequired,
//     path: PropTypes.string,
//     url: PropTypes.string.isRequired,
//   }).isRequired,
// };

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequestsContainerClass));
