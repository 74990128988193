import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  API,
  GlobalActions,
  ExpandedLocationsHelper,
  LoadingContent,
  viUser,
  viUserGroup,
  viCogOutline,
  viSpeakerOutline,
} from 'vibeguide';
import AccountType from './AccountType';
import UserDetails from './UserDetails';
import PartnerUserDetails from './PartnerUserDetails';
import SystemUserDetails from './SystemUserDetails';
import PlayerUserDetails from './PlayerUserDetails';
import './UserSidebar.scss';

function UserSidebar({
  user: userProp,
  userId,
  accountType: propAccountType,
  isNew,
  isDuplicate,
  setPanel,
}) {
  const [user, setUser] = useState({});
  const [hasChosenAccountType, setHasChosenAccountType] = useState(false);
  const [accountType, setAccountType] = useState({
    userAccount: {
      label: 'User',
      value: 'userAccount',
      description: 'Grants access to the web application',
      icon: viUser,
      selected: propAccountType === 'user' || propAccountType === 'standard',
    },
    partnerAccount: {
      label: 'Partner User',
      value: 'partnerAccount',
      description: 'Grants access to ISM',
      icon: viUserGroup,
      selected: propAccountType === 'partner',
    },
    systemAccount: {
      label: 'System',
      value: 'systemAccount',
      description: 'This is a system integration account',
      icon: viCogOutline,
      selected: propAccountType === 'system' || propAccountType === 'integration',
    },
    playerAccount: {
      label: 'Player',
      value: 'playerAccount',
      description: 'A device connected to each location',
      icon: viSpeakerOutline,
      selected: propAccountType === 'player' || propAccountType === 'player_service',
    },
  });

  const setSelectedAccountType = (selectedAccountType) => {
    switch (selectedAccountType) {
      case 'system':
      case 'integration':
      case 'systemAccount': {
        setAccountType({
          userAccount: {
            ...accountType.userAccount,
            selected: false,
          },
          partnerAccount: {
            ...accountType.partnerAccount,
            selected: false,
          },
          systemAccount: {
            ...accountType.systemAccount,
            selected: true,
          },
          playerAccount: {
            ...accountType.playerAccount,
            selected: false,
          },
        });

        break;
      }

      case 'player':
      case 'player_service':
      case 'playerAccount': {
        setAccountType({
          userAccount: {
            ...accountType.userAccount,
            selected: false,
          },
          partnerAccount: {
            ...accountType.partnerAccount,
            selected: false,
          },
          systemAccount: {
            ...accountType.systemAccount,
            selected: false,
          },
          playerAccount: {
            ...accountType.playerAccount,
            selected: true,
          },
        });

        break;
      }

      case 'partner':
      case 'partnerAccount': {
        setAccountType({
          userAccount: {
            ...accountType.userAccount,
            selected: false,
          },
          partnerAccount: {
            ...accountType.partnerAccount,
            selected: true,
          },
          systemAccount: {
            ...accountType.systemAccount,
            selected: false,
          },
          playerAccount: {
            ...accountType.playerAccount,
            selected: false,
          },
        });

        break;
      }

      case 'user':
      case 'standard':
      case 'userAccount':
      default: {
        setAccountType({
          userAccount: {
            ...accountType.userAccount,
            selected: true,
          },
          partnerAccount: {
            ...accountType.partnerAccount,
            selected: false,
          },
          systemAccount: {
            ...accountType.systemAccount,
            selected: false,
          },
          playerAccount: {
            ...accountType.playerAccount,
            selected: false,
          },
        });

        break;
      }
    }
  };

  useEffect(() => {
    switch (propAccountType) {
      case 'system':
        setSelectedAccountType('systemAccount');
        break;
      case 'partner':
        setSelectedAccountType('partnerAccount');
        break;
      case 'user':
        setSelectedAccountType('userAccount');
        break;
      case 'player':
        setSelectedAccountType('playerAccount');
        break;
      default:
        break;
    }
  }, [propAccountType]);

  const onAccountTypeUpdate = (newAccountType) => {
    setSelectedAccountType(newAccountType);
    setHasChosenAccountType(true);
  };

  const getUser = async () => {
    let user;
    let expandedLocations;
    let newAccountType;

    if (get(accountType, 'systemAccount.selected')) {
      // System User Account
      newAccountType = 'system';
    } else if (get(accountType, 'partnerAccount.selected')) {
      // Partner User Account
      newAccountType = 'partner';
    } else {
      // User Account
      newAccountType = 'user';
    }

    if (propAccountType !== newAccountType) {
      switch (propAccountType) {
        case 'system':
          user = await API.IntegrationUser.getById(userId);
          expandedLocations = await API.IntegrationUser.getExpandedLocations(userId);
          break;
        case 'partner':
          user = await API.PartnerUser.get(userId);
          expandedLocations = await API.PartnerUser.getExpandedLocations({
            _id: userId,
          });
          break;
        default:
          user = await API.User.getById(userId);
          expandedLocations = await API.User.getExpandedLocations(userId);
          break;
      }
    } else if (get(accountType, 'systemAccount.selected')) {
      // System User Account
      user = await API.IntegrationUser.getById(userId);
      expandedLocations = await API.IntegrationUser.getExpandedLocations(userId);
    } else if (get(accountType, 'partnerAccount.selected')) {
      // Partner User Account
      user = await API.PartnerUser.get(userId);
      expandedLocations = await API.PartnerUser.getExpandedLocations({
        _id: userId,
      });
    } else {
      // User Account
      user = await API.User.getById(userId);
      expandedLocations = await API.User.getExpandedLocations(userId);
    }

    // If copying user, add all existing tags to new user
    if (isDuplicate) {
      user.tags = user.tags.map(tag => {
        return {
          ...tag,
          status: 'add',
        };
      });
    }

    // Set the location data
    user.locationsData = ExpandedLocationsHelper.getLocationsData(expandedLocations);

    // rename locationsAllowed from the API to locations
    user.locations = user.locationsAllowed;
    delete user.locationsAllowed;

    // save permission IDs and remove the permissions object
    user.permissionIds = user.permissions.map(permission => permission._id);
    delete user.permissions;

    setUser(user);
  };

  const setCompany = () => {
    setUser({
      companyName: userProp.companyName,
      companyId: userProp.companyId,
    });
  };

  useEffect(() => {
    if (userId && userId !== 'new') {
      getUser();
    } else if (isNew && !userProp.sysAdmin) {
      setCompany();
    }
  }, [userId, userProp]);

  useEffect(() => {
    if (isNew && userId) {
      getUser();
    }
  }, [isNew]);

  const onUpdate = (data) => {
    setUser({
      ...user,
      ...data,
    });
  };

  const onClose = () => {
    setPanel({
      show: false,
    });
  };

  const onCloseExtraPanel = () => {
    setPanel({
      extraPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        extraPanel: {
          children: null,
        },
      });
    }, 900);
  };

  return (
    <div className="UserSidebar">
      {isNew && !hasChosenAccountType ? (
        <AccountType
          accountType={accountType}
          user={userProp}
          onUpdate={onAccountTypeUpdate}
          onClose={onClose}
        />
      ) : (
        <>
          {(accountType.userAccount.selected && user) && (
            <UserDetails
              selectedAccountType={accountType.userAccount}
              editUser={user}
              isNew={isNew}
              isDuplicate={isDuplicate}
              onClose={onClose}
              onCloseExtraPanel={onCloseExtraPanel}
              onUpdate={onUpdate}
              onRefresh={getUser}
            />
          )}

          {(accountType.partnerAccount.selected && user) && (
            <PartnerUserDetails
              selectedAccountType={accountType.partnerAccount}
              editUser={user}
              isNew={isNew}
              isDuplicate={isDuplicate}
              onClose={onClose}
              onCloseExtraPanel={onCloseExtraPanel}
              onUpdate={onUpdate}
              onRefresh={getUser}
            />
          )}

          {(accountType.systemAccount.selected && user) && (
            <SystemUserDetails
              selectedAccountType={accountType.systemAccount}
              editUser={user}
              isNew={isNew}
              isDuplicate={isDuplicate}
              onClose={onClose}
              onCloseExtraPanel={onCloseExtraPanel}
              onUpdate={onUpdate}
              onRefresh={getUser}
            />
          )}

          {(accountType.playerAccount.selected && user) && (
            <PlayerUserDetails
              selectedAccountType={accountType.playerAccount}
              editUser={user}
              isNew={isNew}
              isDuplicate={isDuplicate}
              onClose={onClose}
              onCloseExtraPanel={onCloseExtraPanel}
              onUpdate={onUpdate}
              onRefresh={getUser}
            />
          )}
        </>
      )}

      {userId && !user._id && (
        <LoadingContent />
      )}
    </div>
  );
}

UserSidebar.propTypes = {
  userId: PropTypes.string,
  accountType: PropTypes.string,
  isNew: PropTypes.bool,
  isDuplicate: PropTypes.bool,
};

UserSidebar.defaultProps = {
  userId: null,
  accountType: '',
  isNew: false,
  isDuplicate: false,
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSidebar);
