import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  SegmentTypeHelper,
  MediaFormatHelper,
  SpotTypeHelper,
  SpotSelectionStrategyHelper,
  Field2 as Field,
  VibeModal,
  color,
  VibeCheckbox,
} from 'vibeguide';
import Grid from '@mui/material/Grid';

// list of hours to select for start/end time
const hourOptions = [];

for (let i = 0; i <= 24; i++) {
  const hour = i < 10
    ? `0${i}:00`
    : `${i}:00`;

  hourOptions.push({
    label: hour,
    value: hour,
  });
}

function Information({
  className,
  name,
  mediaFormat,
  locationSpec,
  spotType,
  spotSelectionStrategy,
  allocateRemainder,
  frequencyCap,
  frequencyCapped,
  allowBackToBackAdDelivery,
  disableInput,
  onUpdate,
  segmentType,
}) {
  const [confirmChangeMediaFormat, setConfirmChangeMediaFormat] = useState(false);
  const [newMediaFormat, setNewMediaFormat] = useState(null);

  /**
   * When the Media Format field is changed
   * ask to confirm removing all locations for the segment
   */
  const onChangeMediaFormat = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    if (locationSpec.length > 0) {
      // show confirmation modal that the locations will be reset
      setConfirmChangeMediaFormat(true);
      setNewMediaFormat(value);
    } else {
      // segment has no locations, allow changing without confirmation
      onUpdate({
        mediaFormat: value,
      });
    }
  };

  /**
   * Confirm changing the Media Format
   */
  const onConfirmChangeMediaFormat = () => {
    onUpdate({
      mediaFormat: newMediaFormat,
      // reset the locations when the media format is changed
      locations: {},
      locationSpec: [],
      locationsData: {
        companies: [],
        locations: [],
      },
      locationCount: 0,
    });

    setConfirmChangeMediaFormat(false);
    setNewMediaFormat(null);
  };

  /**
   * Cancel changing the Media Format
   */
  const onCancelChangeMediaFormat = () => {
    setConfirmChangeMediaFormat(false);
    setNewMediaFormat(null);
  };

  /**
   * When a field is changed
   */
  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  const onToggleChange = (e) => {
    const {
      name,
      checked,
    } = e;

    onUpdate({
      [name]: checked,
    });
  };

  const onChangeAllocateRemainder = allocateRemainder => {
    onUpdate({
      allocateRemainder,
    });
  };

  const onChangeAdPlays = () => {
    onUpdate({
      allowBackToBackAdDelivery: !allowBackToBackAdDelivery,
    });
  };

  const onChangeSegmentType = (e) => {
    const {
      name,
      value,
    } = e.target;

    const data = {};

    if (value === 'added-value') {
      // remove the budget and cpm for added value flights
      data.budget = 0;
      data.cpm = 0;
      data.lockCalculator = false;
    }

    onUpdate({
      [name]: value,
      ...data,
    });
  };

  return (
    <div className={classNames('Information', 'segment', className)}>
      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid
          className="details-title"
          xs={6}
          item
        >
          Information
        </Grid>
      </Grid>

      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid
          xs={12}
          item
        >
          <Field
            type="text"
            label="Name"
            name="name"
            placeholder="Name"
            value={name}
            disabled={disableInput}
            tabIndex={20}
            onChange={onChange}
            autoFocus
            required
          />
        </Grid>

        <Grid
          xs={12}
          item
        >
          <Field
            type="select"
            label="Segment Type"
            name="segmentType"
            value={segmentType}
            options={SegmentTypeHelper.list({ includeEmpty: true })}
            disabled={disableInput}
            tabIndex={21}
            onChange={onChangeSegmentType}
            required
          />
        </Grid>

        <Grid xs={12} item>
          <Field
            type="select"
            label="Media Format"
            name="mediaFormat"
            value={mediaFormat}
            options={MediaFormatHelper.list({ includeEmpty: true })}
            disabled={disableInput}
            tabIndex={22}
            onChange={onChangeMediaFormat}
            required
          />
        </Grid>

        <Grid
          xs={12}
          item
        >
          <Field
            type="select"
            label="Spot Type"
            name="spotType"
            value={spotType}
            options={SpotTypeHelper.list({ includeEmpty: true })}
            disabled={disableInput}
            tabIndex={23}
            onChange={onChange}
            required
          />
        </Grid>

        <Grid
          xs={12}
          item
        >
          <Field
            type="select"
            label="Spot Selection Strategy"
            name="spotSelectionStrategy"
            value={spotSelectionStrategy}
            options={SpotSelectionStrategyHelper.list({ includeEmpty: true })}
            disabled={disableInput}
            tabIndex={24}
            onChange={onChange}
            required
          />
        </Grid>
        {(spotSelectionStrategy !== 'spots-per-hour') && (
          <Grid
            xs={8}
            item
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              className="toggle-container"
            >
              <VibeCheckbox
                size={25}
                color={color.violetVibe}
                name="frequencyCapped"
                checked={frequencyCapped}
                onChange={onToggleChange}
              />

              <div>
                Add Frequency Capping
              </div>
            </div>
          </Grid>
        )}

        {((spotSelectionStrategy !== 'spots-per-hour') && frequencyCapped) && (
          <Grid
            xs={4}
            item
          >
            <Field
              type="number"
              name="frequencyCap"
              placeholder="Frequency Cap"
              value={frequencyCap}
              tabIndex={26}
              numberProps={{
                min: 1,
              }}
              onChange={onChange}
              autoFocus
              required
            />
          </Grid>
        )}

        {spotSelectionStrategy !== 'spots-per-hour' && (
          <Grid
            xs={12}
            item
          >
            <Field
              type="toggle"
              label="Allocate Remainder"
              toggleProps={{
                label: 'Allocate Remainder',
              }}
              value={allocateRemainder ? 'on' : 'off'}
              tabIndex={27}
              disabled={disableInput}
              onChange={onChangeAllocateRemainder}
            />
          </Grid>
        )}

        <Grid
          xs={12}
          item
        >
          <Field
            type="toggle"
            label="Ad Plays"
            toggleProps={{
              label: 'Allow Duplicate Ads Back to Back',
              helpText: 'Disabling restricts available inventory to ad programs.',
            }}
            labelProps={{
              tooltip: (
                <div>
                  If disabled on company, company setting<br />
                  will take priority
                </div>
              ),
              tooltipPlacement: 'right',
              informationIcon: true,
            }}
            value={allowBackToBackAdDelivery
              ? 'on'
              : 'off'}
            tabIndex={28}
            disabled={disableInput}
            onChange={onChangeAdPlays}
          />
        </Grid>
      </Grid>

      <VibeModal
        show={confirmChangeMediaFormat}
        type="confirm"
        title="Change Media Format"
        text={(
          <div>
            Changing the media format will reset the assigned locations for this segment
            <br />
            <br />
            This action cannot be undone.
          </div>
        )}
        confirmProps={{
          text: 'Confirm',
          color: color.aquaForest,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmChangeMediaFormat}
        onClose={onCancelChangeMediaFormat}
      />
    </div>
  );
}

Information.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  mediaFormat: PropTypes.string,
  locationSpec: PropTypes.arrayOf(PropTypes.object),
  spotType: PropTypes.string,
  spotSelectionStrategy: PropTypes.string,
  frequencyCap: PropTypes.number,
  frequencyCapped: PropTypes.bool,
  allocateRemainder: PropTypes.bool,
  allowBackToBackAdDelivery: PropTypes.bool.isRequired,
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Information.defaultProps = {
  className: '',
  name: '',
  mediaFormat: '',
  locationSpec: [],
  spotType: '',
  spotSelectionStrategy: '',
  frequencyCap: 0,
  frequencyCapped: false,
  allocateRemainder: false,
  disableInput: false,
  onUpdate: () => {},
};

export default Information;
