import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  find,
} from 'lodash';
import {
  API,
  PageLayout,
  Users,
  EmptyState,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  SidePanelFooter,
  VibeIcon,
  VibeButton,
  SearchByHelper,
  LocationHelper,
  viChevronLeft,
  viUser,
  color,
} from 'vibeguide';
import './AssignApprover.scss';

class AssignApprover extends PureComponent {
  constructor(props) {
    super(props);

    const {
      approverId,
    } = this.props;

    this.state = {
      filteredApprovers: [],
      approversList: [],
      approverId,
    };
  }

  componentDidMount() {
    this.getApprovers();
  }

  onFilter = (data) => {
    const {
      collection,
    } = data;

    this.setState({
      filteredApprovers: collection,
    });
  };

  onSelectUser = (userId) => {
    const {
      onChoose,
      onClose,
      approverId,
    } = this.props;

    const {
      filteredApprovers,
    } = this.state;

    if (approverId) {
      this.setState({
        approverId: userId,
      });
    } else {
      const approver = find(filteredApprovers, { _id: userId });

      onChoose(userId, approver.fname, approver.lname);
      onClose();
    }
  };

  getApprovers = async () => {
    const {
      companyId,
      partnerId,
      locations,
      approverType,
    } = this.props;

    const group = LocationHelper.group(locations);

    const approversList = approverType === 'partner'
      ? await API.MessageRequest.Approval.getPartnerApprovers({ partnerId, locations: group })
      : await API.MessageRequest.Approval.getApprovers({ companyId, locations: group });

    this.setState({
      approversList,
    });
  };

  /**
   * Save Approver Selection
   */
  save = async () => {
    const {
      onClose,
      onChoose,
    } = this.props;

    const {
      approverId,
      filteredApprovers,
    } = this.state;

    const approver = find(filteredApprovers, { _id: approverId });

    if (!approverId) {
      // No approver selected
      return;
    }

    onChoose(approverId, approver.fname, approver.lname);
    onClose();
  };

  render() {
    const {
      onClose,
    } = this.props;

    const {
      filteredApprovers,
      approversList,
      approverId,
    } = this.state;

    const approver = find(filteredApprovers, { _id: approverId });
    const approverName = approver ? `${approver.fname} ${approver.lname}` : null;

    return (
      <div className="AssignApprover">
        <SidePanelContainer>
          <SidePanelHeader className="panel-header">
            <div className="panel-header-container">
              <VibeIcon
                icon={viChevronLeft}
                color={color.manatee}
                hoverColor={color.obsidian}
                size={24}
                onClick={onClose}
              />
              <PageLayout
                className="page-layout"
                searchOptions={SearchByHelper.users}
                collection={approversList}
                onFilter={this.onFilter}
                disableView
                disableSort
              />
            </div>
          </SidePanelHeader>

          <SidePanelContent>
            <div className="approvers-list">
              {filteredApprovers && filteredApprovers.length
                ? (
                  <Users
                    users={filteredApprovers}
                    selectedIds={[approverId]}
                    onSelect={this.onSelectUser}
                    allowClick
                    slim
                  />
                )
                : (
                  <EmptyState
                    title="No Available Approvers"
                    description="No users found that can approve this message"
                    style={{
                      paddingBottom: 20,
                    }}
                  />
                )}

            </div>
          </SidePanelContent>

          {approverId ? (
            <SidePanelFooter className="panel-footer">
              <div className="approver-container">
                <div className="selected-approver">
                  <VibeIcon
                    icon={viUser}
                    color={color.aquaForest}
                    size={16}
                  />

                  <div className="selected-name">
                    {approverName} Selected
                  </div>
                </div>

                <div className="toolbar">
                  <VibeButton
                    className="assign-btn"
                    text="Assign"
                    btnColor="green"
                    textColor="white"
                    onClick={this.save}
                  />

                  <VibeButton
                    text="Cancel"
                    btnColor="transparent"
                    textColor="manatee"
                    onClick={onClose}
                  />
                </div>
              </div>
            </SidePanelFooter>
          ) : null}
        </SidePanelContainer>
      </div>
    );
  }
}

AssignApprover.propTypes = {
  companyId: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  partnerId: PropTypes.string,
  approverId: PropTypes.string,
  approverType: PropTypes.string,
  onChoose: PropTypes.func,
  onClose: PropTypes.func,
};

AssignApprover.defaultProps = {
  approverId: '',
  partnerId: '',
  approverType: 'client',
  onChoose: PropTypes.func,
  onClose: () => { },
};

export default AssignApprover;
