import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  API,
  Field2 as Field,
  SidePanelFooter,
  VibeButtonNew,
  VibeIcon,
  viClose,
  color,
} from 'vibeguide';
import './RequestChanges.scss';

function RequestChanges({
  messageId,
  messageType,
  approverType,
  onClose,
  onRefresh,
}) {
  const [changes, setChanges] = useState('');

  const isRequestChanges = messageType !== 'ext'
    && messageType !== 'ext-ad';

  const onChange = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    setChanges(value);
  };

  const onSave = async () => {
    await API.MessageRequest.Approval.requestChanges({
      _id: messageId,
      response: 'request-change',
      comment: changes,
      approvalType: approverType === 'partner' ? 'partner' : 'client',
    });

    onRefresh();
    onClose();
  };

  return (
    <div className="RequestChanges">
      <div className="header">
        <div
          className="title"
          style={{
            color: isRequestChanges
              ? color.flamingo
              : color.fireBrick,
          }}
        >
          {isRequestChanges
            ? 'Request Changes'
            : 'Deny Message'}
        </div>

        <VibeIcon
          className="close"
          icon={viClose}
          color={color.manatee}
          hoverColor={color.obsidian}
          size={24}
          onClick={onClose}
        />
      </div>

      <div className="content">
        <Field
          className="changes-textarea"
          type="textarea"
          placeholder={isRequestChanges
            ? 'Let us know what needs changed here.'
            : 'Let us know why you\'re denying this message.'}
          value={changes}
          autoFocus
          onChange={onChange}
        />
      </div>

      <SidePanelFooter>
        <VibeButtonNew
          text={isRequestChanges
            ? 'Request Changes'
            : 'Deny Message'}
          color={isRequestChanges
            ? color.flamingo
            : color.fireBrick}
          disabled={!changes}
          onClick={onSave}
        />

        <VibeButtonNew
          variant="text"
          text="Cancel"
          color={color.manatee}
          style={{
            marginLeft: 8,
          }}
          onClick={onClose}
        />
      </SidePanelFooter>
    </div>
  );
}

RequestChanges.propTypes = {
  messageId: PropTypes.string.isRequired,
  messageType: PropTypes.string.isRequired,
  approverType: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onRefresh: PropTypes.func,
};

RequestChanges.defaultProps = {
  onClose: () => {},
  onRefresh: () => {},
};

export default RequestChanges;
