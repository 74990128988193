import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';
import MessageRequest from '../../models/MessageRequest';

const baseUrl = `${process.env.VAPI_BASE_URL}/messagerequests`;

export default {
  list: (data, options, category) => Http.post(`${baseUrl}/${category}`, data, options),
  listCuration: (data, options) => Http.post(`${baseUrl}/curation`, data, options),
  listProcessing: (data, options) => Http.post(`${baseUrl}/processing`, data, options),
  listRough: (data, options) => Http.post(`${baseUrl}/rough`, data, options),
  listReview: (data, options) => Http.post(`${baseUrl}/review`, data, options),
  getById: (id, options) => {
    options = addVersion('8.1.0', options);

    return Http.get(`${baseUrl}/${id}`, options)
      .then((result) => {
        return new MessageRequest(result);
      });
  },
  getExpandedLocations: (id, options) => Http.get(`${baseUrl}/${id}/locations/expanded`, options),
  create: (data, options) => {
    options = addVersion('8.1.0', options);
    return Http.post(baseUrl, data, options);
  },
  assignVoiceTalent: (data) => Http.post(`${baseUrl}/${data._id}/assign`, data),
  uploadRough: (id, file, onProgress) => Http.postFileUpload(
    `${baseUrl}/${id}/rough`, file, 'media', onProgress,
  ),
  uploadFinal: (id, file, onProgress) => Http.postFileUpload(
    `${baseUrl}/${id}/final`, file, 'media', onProgress,
  ),
  uploadAttachment: (id, file, onProgress) => Http.postFileUpload(
    `${baseUrl}/${id}/attachment`, file, 'attachment', onProgress,
  ),
  scriptHistory: (id) => Http.get(`${baseUrl}/${id}/script/history`),
  uploadHistory: (id) => Http.get(`${baseUrl}/${id}/upload/history`),
  rename: (data) => Http.post(`${baseUrl}/${data._id}/name`, data),
  changeDates: (data) => Http.post(`${baseUrl}/${data._id}/dates`, data),
  changePublishMode: (data) => Http.post(`${baseUrl}/${data._id}/publishmode`, data),
  changeMessageBlocks: (data) => Http.post(`${baseUrl}/${data._id}/messageLists`, data),
  changeScript: (data) => Http.post(`${baseUrl}/${data._id}/script`, data),
  changeInstructions: (data) => Http.post(`${baseUrl}/${data._id}/instructions`, data),
  changeTags: (data) => Http.post(`${baseUrl}/${data._id}/tags`, data),
  changeLocations: (data) => Http.post(`${baseUrl}/${data._id}/locations`, data),
  changeTrackingUrls: (data) => Http.post(`${baseUrl}/${data._id}/trackingurls`, data),
  changeCategory: (data) => Http.post(`${baseUrl}/${data._id}/category`, data),
  addComment: (id, data) => Http.post(`${baseUrl}/${id}/comment`, data),
  modify: (data, options = {}) => {
    options = addVersion('8.1.0', options);
    return Http.put(`${baseUrl}/${data._id}`, data, options);
  },
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
  deactivateBulk: (requests) => Http.delete(baseUrl, {
    requests,
  }),
  deleteAttachment: (id, attachmentId) => Http.delete(`${baseUrl}/${id}/attachment/${attachmentId}`),
  sendApprovalNotification: (id, data) => Http.post(`${baseUrl}/${id}/notification/approve`, data),

  // Segments
  Approval: {
    getApprovers: (data, options) => Http.post(`${baseUrl}/approvers`, data, options),
    getPartnerApprovers: (data, options) => Http.post(`${baseUrl}/approvers/partner`, data, options),
    requestChanges: (data, options) => {
      options = addVersion('7.34.0', options);
      return Http.post(`${baseUrl}/${data._id}/review`, data, options);
    },
    approve: (data, options) => {
      options = addVersion('7.34.0', options);
      return Http.post(`${baseUrl}/${data._id}/review`, data, options);
    },
  },
};
