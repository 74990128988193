import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isFinite from 'lodash/isFinite';
import {
  color,
} from 'vibeguide';
import Chip from '@mui/material/Chip';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { withStyles } from '@mui/styles';
import './InfoCard.scss';

const StyleChip = withStyles({
  root: {
    height: 24,
    backgroundColor: color.white,
    color: color.violetVibe,
    fontSize: 14,
    fontWeight: 400,
  },
})(Chip);

function InfoCard({
  valueLabel,
  valueLabelBadge,
  value,
  valueBadge,
  subValues,
  percentage,
  type,
  align,
  isViewOnly,
}) {
  // force showing 100% as a max
  const usePercentage = percentage <= 100
    ? percentage
    : 100;

  const formatValue = (value) => {
    if (type === 'currency') {
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    }

    return value.toLocaleString();
  };

  return (
    <div className={classNames('InfoCard', { 'view-only': isViewOnly })}>
      <div
        className="snippet-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: align === 'center'
            ? 'center'
            : 'flex-start',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              fontSize: 14,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {valueLabel}
          </div>

          {valueLabelBadge && (
            <StyleChip
              label={valueLabelBadge}
              sx={{
                marginLeft: '8px',
              }}
            />
          )}
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 16,
            fontSize: 24,
            fontWeight: 'bold',
          }}
        >
          {value && formatValue(value)}

          {valueBadge && (
            <StyleChip
              label={valueBadge}
              sx={{
                marginLeft: '8px',
              }}
            />
          )}
        </div>

        {isFinite(usePercentage) && (
          <LinearProgress
            variant="determinate"
            value={usePercentage < 5 && usePercentage > 0
              // display 5% by default if progress is greater than 0 but less than 5%
              ? 5
              // display the actual percentage
              : usePercentage}
            sx={{
              height: 8,
              marginTop: '16px',
              [`&.${linearProgressClasses.colorPrimary}`]: {
                borderRadius: 5,
                backgroundColor: isViewOnly
                  ? color.gray300
                  : color.white,
              },
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 5,
                backgroundColor: isViewOnly
                  ? color.obsidian
                  : color.violetVibe,
              },
            }}
          />
        )}

        {subValues.length > 0 && (
          <div className="snippet-footer">
            {subValues.map((subValue) => (
              <div
                key={`subvalue-${subValue.label}`}
                className="subvalue"
              >
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: 14,
                  }}
                >
                  {subValue.label}
                </div>

                <div
                  style={{
                    marginLeft: 8,
                    fontSize: 14,
                  }}
                >
                  {formatValue(subValue.value)}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

InfoCard.propTypes = {
  type: PropTypes.oneOf([
    'number',
    'currency',
  ]),
  align: PropTypes.oneOf([
    'top',
    'center',
  ]),
  valueLabel: PropTypes.string,
  valueLabelBadge: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  valueBadge: PropTypes.string,
  subValues: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })),
  percentage: PropTypes.number,
  isViewOnly: PropTypes.bool,
};

InfoCard.defaultProps = {
  type: 'number',
  align: 'top',
  valueLabel: '',
  valueLabelBadge: '',
  value: '',
  valueBadge: '',
  subValues: [],
  percentage: null,
  isViewOnly: false,
};

export default InfoCard;
