import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  find,
  isEqual,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../../types/tablePropsType';
import {
  getSettings,
  getFiltersFromUrl,
  getData,
} from '../../../helpers/Table';
import {
  getParam,
} from '../../../helpers/Navigation';
import API from '../../../api';
import VibeTable from '../VibeTable';
import VibeModal from '../../VibeModal/VibeModal';
import VibeButtonNew from '../../VibeButton/VibeButtonNew';
import Field from '../../Field/Field2';
import Avatar from '../../Avatar/Avatar';
import CellTooltip from '../CellTypes/CellTooltip';
import ContentTags from '../CellTypes/ContentTags';
import LabelCell from '../CellTypes/LabelCell';
import VibeIcon from '../../VibeIcon/VibeIcon';
import viAddCircle from '../../../icons/viAddCircle';
import viCloseCircle from '../../../icons/viCloseCircle';
import viEye from '../../../icons/viEye';
import viEdit from '../../../icons/viEdit';
import viClose from '../../../icons/viClose';
import viCheck from '../../../icons/viCheck';
import viDownload from '../../../icons/viDownload';
import defaultCompanyImage from '../../../assets/default_company.png';
import color from '../../../sass/color.scss';

const tableId = 'table:partners';
const permissionPrefix = 'partner';

class TablePartners extends PureComponent {
  constructor(props) {
    super(props);

    const {
      columns: columnNames,
      defaultSortBy,
      pageSize,
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
    } = props;

    const columnsDef = [{
      name: '.',
      defaultWidth: 40,
    },
    {
      name: 'Add/Remove',
      defaultWidth: 77,
      icon: viAddCircle,
      locked: true,
    },
    {
      name: 'Name',
      searchAttr: 'name',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      sortable: true,
      autoFocus: true,
    },
    {
      name: 'Company',
      searchAttr: 'companyId',
      sortAttr: 'companyName',
      valueAttr: 'companies',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      searchSelector: 'company',
    },
    {
      name: 'Sensitive Data',
      searchAttr: 'sensitiveData',
      defaultWidth: 175,
      resizable: true,
      searchable: true,
      sortable: true,
      editable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Hide',
          value: 'hidden',
        },
        {
          label: 'View Only',
          value: 'view-only',
        },
      ],
    },
    {
      name: 'Tags',
      searchAttr: 'tags.name',
      valueAttr: 'tags',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
    },
    {
      name: '...',
      defaultWidth: 72,
    }];

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.state = {
      columnsDef,
      columnNames,
      rows: [],
      // row changes being edited in "Edit Mode"
      rowsEdit: [],
      loading: true,
      totalItems: 0,
      active: !urlFilters || (urlFilters && getParam('active') !== 'false'),
      pageNumber: urlPaging
        ? parseInt(getParam('page') || 1, 10)
        : 1,
      pageSize: get(settings, 'pageSize', pageSize),
      sortBy: {
        label: get(settings, 'sortBy.label', defaultSortBy.label),
        attr: get(settings, 'sortBy.attr', defaultSortBy.attr),
        direction: get(settings, 'sortBy.direction', defaultSortBy.direction),
      },
      filters: urlFilters
        ? getFiltersFromUrl({ columns: columnsDef })
        : {},
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
      isEditMode: false,
      errorIds: [],
    };

    // listen for when sidebar data changes
    document.addEventListener('onSavePartner', this.onUpdateTableData);
    document.addEventListener('onUpdateTableAssignedPartners', this.onUpdateTableAssignedData);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {
      fetch,
      collection,
    } = this.props;

    const {
      collection: prevCollection,
    } = prevProps;

    if (!fetch && !isEqual(collection, prevCollection)) {
      this.onUpdate({
        refresh: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onSavePartner', this.onUpdateTableData);
    document.removeEventListener('onUpdateTableAssignedPartners', this.onUpdateTableAssignedData);
  }

  /**
   * When an event asks the table to update the table data
   */
  onUpdateTableData = () => {
    this.onUpdate({
      refresh: true,
    });
  };

  onSelectMenuItem = (itemName, _rowId) => {
    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });

    if (!row) {
      console.error('Row not found matching ID', _rowId);
      return;
    }

    switch (itemName) {
      case 'archive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to archive ${row.name}?`,
          confirmApproveText: 'Yes, Archive',
          confirmApproveColor: color.fireBrick,
        });

        break;
      }

      case 'unarchive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to unarchive ${row.name}?`,
          confirmApproveText: 'Yes, Unarchive',
          confirmApproveColor: color.aquaForest,
        });

        break;
      }

      default:
        break;
    }
  };

  onConfirmModal = async () => {
    const {
      collection,
      onRemove,
    } = this.props;

    const {
      confirmAction,
      confirmRow: {
        _id,
      },
    } = this.state;

    if (!_id) {
      console.error('onConfirmModal no row ID');
      return;
    }

    switch (confirmAction) {
      case 'archive': {
        try {
          const response = await API.Partner.deactivate(_id);

          const successText = 'PARTNER.DEACTIVATED';
          const success = get(response, '[0].type') === successText;

          if (success) {
            // if using a local collection, remove from the table
            if (collection.length > 0) {
              const items = collection.filter(item => item._id === _id);

              items.forEach((item) => {
                // remove the item from the table
                onRemove(item);
              });
            }

            // tell listening components to update the counts and table data
            this.onUpdate({
              refresh: true,
            });
          }
        } catch (e) {
          console.error('Error Archiving', e);
        }

        break;
      }

      case 'unarchive': {
        try {
          const response = await API.Partner.reactivate({
            _id,
          });

          const successText = 'PARTNER.REACTIVATED';
          const success = get(response, '[0].type') === successText;

          if (success) {
            // tell listening components to update the counts and table data
            this.onUpdate({
              refresh: true,
            });
          }
        } catch (e) {
          console.error('Error Unarchiving', e);
        }

        break;
      }

      default:
        break;
    }

    this.resetConfirmModal();
  };

  /**
   * When the Bulk Archive Completes
   */
  onBulkArchive = () => {
    // tell listening components to update the counts and table data
    this.onUpdate({
      refresh: true,
    });
  };

  /**
   * Remove a Partner from the table
   */
  onRemovePartner = (e, _rowId) => {
    const {
      onRemove,
    } = this.props;

    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });
    onRemove(row);
  };

  /**
   * Add a Partner to the table
   */
  onAddPartner = (e, _rowId) => {
    const {
      onAdd,
    } = this.props;

    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });
    onAdd(row);
  };

  /**
   * Reset to Default Settings
   */
  onReset = () => {
    const {
      defaultSortBy,
      pageSize,
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        sortBy: defaultSortBy,
        pageSize: pageSize || 50,
      },
    });
  };

  onUpdate = ({
    data,
    refresh = false,
  }) => {
    if (refresh) {
      // refresh the table data
      this.setState(data, this.getData);
    } else {
      this.setState(data);
    }
  };

  /**
   * When an event asks the table to update the assigned table
   */
  onUpdateTableAssignedData = () => {
    const {
      assigned,
    } = this.props;

    if (assigned) {
      this.onUpdate({
        refresh: true,
      });
    }
  };

  /**
   * Toggle the table edit mode
   */
  onClickEditMode = () => {
    this.setState({
      isEditMode: true,
    });
  };

  /**
   * Cancel edit mode changes
   */
  onClickEditModeCancel = () => {
    this.setState({
      isEditMode: false,
      // reset any edits made to rows
      rowsEdit: [],
      // reset any errors
      errorIds: [],
    });
  };

  /**
   * Save edit mode changes
   */
  onClickEditModeSave = async () => {
    const {
      onSaveEditModeChanges,
    } = this.props;

    const {
      rows,
      rowsEdit,
    } = this.state;

    // if (rowsEdit.length <= 0) {
    //   console.warn('No rows have been edited');
    //   return;
    // }

    const newRows = rows.map((row) => {
      const editRow = find(rowsEdit, { _id: row._id });
      // return the edited row information, or the original row if one wasn't found
      return editRow ?? row;
    });

    const missingData = newRows.filter(row => !row.sensitiveData);

    if (missingData.length > 0) {
      // do not allow saving until all required data is set
      this.setState({
        errorIds: missingData.map(row => row._id),
      });
    } else {
      // save edit mode changes
      onSaveEditModeChanges(newRows);

      // reset edit mode state
      this.onClickEditModeCancel();
    }
  };

  /**
   * When a field is changed during edit mode
   */
  onChangeEditField = (e) => {
    const {
      target: {
        name,
        value,
        dataset: {
          id: _id,
        },
      },
    } = e;

    this.setState((state) => {
      // current row data
      const currRow = find(state.rows, { _id }) || {};
      // is the row already being edited
      const rowEditExists = find(state.rowsEdit, { _id }) !== undefined;

      return {
        rowsEdit: rowEditExists
          // the row is already being edited
          ? state.rowsEdit.map((row) => {
            if (row._id === _id) {
              return {
                // current row data (all)
                ...currRow,
                // current edited attributes for the row (some)
                ...row,
                // new value for the changed attribute
                [name]: value,
              };
            }

            return row;
          })
          // need to add the row to edit
          : [...state.rowsEdit, {
            // current row data (all)
            ...currRow,
            _id,
            // new value for the changed attribute
            [name]: value,
          }],
      };
    });
  };

  /**
   * When a field is blurred during edit mode
   */
  onBlurEditField = (e) => {
    const {
      target: {
        name,
        value,
        dataset: {
          id: _id,
        },
      },
    } = e;

    const {
      columnsDef,
    } = this.state;

    const column = find(columnsDef, { searchAttr: name });
    let fieldValue = value;

    if (column.dataType === 'array') {
      // keep value an array
      fieldValue = value.split(',').map(item => item.trim()).filter(item => item !== '');

      this.setState((state) => {
        // current row data
        const currRow = find(state.rows, { _id }) || {};
        // is the row already being edited
        const rowEditExists = find(state.rowsEdit, { _id }) !== undefined;

        return {
          rowsEdit: rowEditExists
            // the row is already being edited
            ? state.rowsEdit.map((row) => {
              if (row._id === _id) {
                return {
                  // current row data (all)
                  ...currRow,
                  // current edited attributes for the row (some)
                  ...row,
                  // new value for the changed attribute
                  [name]: fieldValue,
                };
              }

              return row;
            })
            // need to add the row to edit
            : [...state.rowsEdit, {
              // current row data (all)
              ...currRow,
              _id,
              // new value for the changed attribute
              [name]: fieldValue,
            }],
        };
      });
    }
  };

  onExport = () => {
    this.getData({
      export: true,
    });
  };

  getData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    if (!state.loading && !config.export) {
      this.setState({
        loading: true,
      });
    }

    const {
      rows,
      filters,
      totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    if (rows) {
      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        filters,
        totalItems,
      });
    }
  };

  /**
   * Get the style for the partner access setting tag
   */
  getSettingStyle = (setting) => {
    switch (setting) {
      case 'hidden':
        return {
          text: 'HIDE',
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };

      case 'view-only':
        return {
          text: 'View Only',
          textStyle: {
            background: color.violetVibe16,
            color: color.violetVibe,
          },
          icon: viEye,
        };

      default:
        return {
          text: setting || 'Unknown',
          textStyle: {
            background: color.flamingo16,
            color: color.flamingo,
          },
        };
    }
  };

  /**
   * Reset the confirm modal data
   */
  resetConfirmModal = () => {
    this.setState({
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
    });
  };

  renderCell = ({
    column,
    row,
  }) => {
    const {
      forceEditMode,
      highlight,
    } = this.props;

    const {
      isEditMode,
    } = this.state;

    // get the attribute with data for the cell
    const attr = column.valueAttr || column.searchAttr;
    const value = get(row, attr, '');

    switch (column.name) {
      case 'Add/Remove': {
        const assigned = find(highlight, { _id: row._id }) !== undefined;

        return (
          <VibeIcon
            name={row._rowId}
            icon={assigned
              ? viCloseCircle
              : viAddCircle}
            color={assigned
              ? color.fireBrick
              : color.aquaForest}
            hoverColor={assigned
              ? color.fireBrick75
              : color.aquaForest75}
            size={24}
            tooltip={assigned
              ? 'Remove Partner'
              : 'Add Partner'}
            tooltipProps={{
              placement: 'right',
            }}
            onClick={assigned
              ? this.onRemovePartner
              : this.onAddPartner}
          />
        );
      }

      case 'Name':
        return (
          <Avatar
            imageUrl={row.imageUrl}
            imageDefault={defaultCompanyImage}
            text={value}
            tooltipProps={{
              placement: 'top',
              enterDelay: 500,
            }}
            displayText
          />
        );

      case 'Sensitive Data': {
        if (column.editable && (isEditMode || forceEditMode)) {
          return (
            <Field
              type="select"
              dataId={row._id}
              name={column.searchAttr}
              placeholder="Edit..."
              value={value}
              options={column.dropdownItems}
              tabIndex={200}
              onBlur={this.onBlurEditField}
              onChange={this.onChangeEditField}
            />
          );
        }

        const {
          text,
          textStyle,
          icon,
        } = this.getSettingStyle(value);

        return (
          <LabelCell
            value={text}
            background={textStyle.background}
            color={textStyle.color}
            icon={icon}
            inline
          />
        );
      }

      case 'Tags':
        return (
          <ContentTags
            className="cell-content"
            tags={row.tags}
            hideIcon
          />
        );

      default:
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {value}
            </div>
          </CellTooltip>
        );
    }
  };

  render() {
    const {
      className,
      toolbarProps,
      rowLink,
      editMode,
      forceEditMode,
      errorIds: propErrorIds,
      csv,
    } = this.props;

    const {
      columnsDef,
      columnNames,
      rows,
      rowsEdit,
      loading,
      totalItems,
      active,
      pageNumber,
      pageSize,
      sortBy,
      filters,
      confirm,
      confirmText,
      confirmApproveText,
      confirmApproveColor,
      isEditMode,
      errorIds,
    } = this.state;

    const customButtons = [];

    // merge row data with edit row data
    const newRows = isEditMode || forceEditMode
      // only merge when in edit mode
      ? rows.map((row) => {
        const rowEdit = find(rowsEdit, { _id: row._id }) || {};

        return {
          ...row,
          ...rowEdit,
        };
      })
      // not in edit mode, use existing row data
      : rows;

    if (editMode && !isEditMode && !forceEditMode) {
      customButtons.push(
        <VibeButtonNew
          key="btn-edit-mode"
          variant="outlined"
          text="Edit Mode"
          color={color.violetVibe}
          icon={viEdit}
          tooltip="Edit all cells in the table"
          tooltipProps={{
            placement: 'top',
          }}
          onClick={this.onClickEditMode}
        />,
      );
    } else if (isEditMode || forceEditMode) {
      customButtons.push(
        <VibeButtonNew
          key="btn-edit-mode-cancel"
          variant="outlined"
          text="Cancel"
          color={color.violetVibe}
          icon={viClose}
          onClick={this.onClickEditModeCancel}
        />,
      );

      customButtons.push(
        <VibeButtonNew
          key="btn-edit-mode-save"
          variant="outlined"
          text="Done"
          color={color.aquaForest}
          icon={viCheck}
          onClick={this.onClickEditModeSave}
        />,
      );
    }

    if (csv) {
      customButtons.push(
        <VibeButtonNew
          key="btn-export"
          variant="outlined"
          text="Export"
          color={color.violetVibe}
          icon={viDownload}
          tooltip={`Export ${totalItems} Partners to CSV`}
          tooltipProps={{
            placement: 'top',
          }}
          onClick={this.onExport}
        />,
      );
    }

    // add export button
    const newToolbarProps = {
      ...toolbarProps,
      customButtons: [
        ...toolbarProps.customButtons,
        ...customButtons,
      ],
    };

    return (
      <div className={classNames('Table', 'TablePartners', className)}>
        <VibeTable
          {...this.props}
          toolbarProps={newToolbarProps}
          errorIds={[
            ...propErrorIds,
            ...errorIds,
          ]}
          rowLink={!isEditMode && !forceEditMode
            ? rowLink
            : null}
          tableId={tableId}
          columnsDef={columnsDef}
          columnNames={columnNames}
          rows={newRows}
          loading={loading}
          permissionPrefix={permissionPrefix}
          sortBy={sortBy}
          filters={filters}
          totalItems={totalItems}
          active={active}
          pageNumber={pageNumber}
          pageSize={pageSize}
          renderCell={this.renderCell}
          // bulkArchive={API.Partner.deactivateBulk}
          onSelectMenuItem={this.onSelectMenuItem}
          onBulkArchive={this.onBulkArchive}
          onReset={this.onReset}
          onUpdate={this.onUpdate}
        />

        <VibeModal
          show={confirm}
          type="confirm"
          confirmProps={{
            text: confirmApproveText,
            color: confirmApproveColor,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          text={confirmText}
          onConfirm={this.onConfirmModal}
          onClose={this.resetConfirmModal}
        />
      </div>
    );
  }
}

TablePartners.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Force edit mode to remain open when required data is missing */
  forceEditMode: PropTypes.bool,
  ...tablePropsType,
};

TablePartners.defaultProps = {
  forceEditMode: false,
  ...tablePropsDefault,
};

export default withRouter(TablePartners);
