import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  find,
} from 'lodash';
import {
  AdProviderHelper,
  SpotTypeHelper,
  MediaFormatHelper,
  Field2 as Field,
  VibeModal,
  VibeButtonNew,
  viClose,
  color,
} from 'vibeguide';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ProviderHeader from '../ProviderHeader';
import '../AdProviders.scss';

function ProviderVistar({
  className,
  style,
  providerKey,
  provider,
  onValidate,
  onChange,
  onAddItem,
  onRemoveItem,
  onRemoveProvider,
}) {
  const [removeItemId, setRemoveItemId] = useState({});
  const [confirmRemoveItem, setConfirmRemoveItem] = useState(false);
  const [confirmRemoveProvider, setConfirmRemoveProvider] = useState(false);

  useEffect(() => {
    onAddItem({
      mediaFormat: '',
      spotType: '',
      networkId: '',
      apiKey: '',
    }, providerKey);
  }, [providerKey]);

  /**
   * When a user clicks remove on an item, show confirmation modal
   */
  const onClickRemoveItem = (itemId) => {
    setRemoveItemId(itemId);
    setConfirmRemoveItem(true);
  };

  /**
   * When the user confirms removing an item
   */
  const onConfirmRemoveItem = () => {
    onRemoveItem(removeItemId, providerKey);

    setConfirmRemoveItem(false);
    setRemoveItemId(null);
  };

  /**
   * When the user cancels removing an item
   */
  const onCancelRemoveItem = () => {
    setConfirmRemoveItem(false);
    setRemoveItemId(null);
  };

  /**
   * When a user clicks remove on an item, show confirmation modal
   */
  const onClickRemoveProvider = () => {
    setConfirmRemoveProvider(true);
  };

  /**
   * When the user confirms removing the provider
   */
  const onConfirmRemoveProvider = () => {
    setConfirmRemoveProvider(false);
    onRemoveProvider(providerKey);
  };

  /**
   * When the user cancels removing the provider
   */
  const onCancelRemoveProvider = () => {
    setConfirmRemoveProvider(false);
  };

  /**
   * Validate Field
   */
  const onValidateField = (data) => {
    onValidate(data, providerKey);
  };

  // get the name of the ad provider
  const adProviderName = get(find(AdProviderHelper.list, { value: providerKey }), 'name', 'Unknown');

  return (
    <div
      className={classNames('ProviderVistar', className)}
      style={style}
    >
      <ProviderHeader
        title={adProviderName}
        showNew
        showRemove
        onClickNew={() => onAddItem({
          mediaFormat: '',
          spotType: '',
          networkId: '',
          apiKey: '',
        }, providerKey)}
        onClickRemove={onClickRemoveProvider}
      />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>MEDIA FORMAT <span className="field-dot" /></TableCell>
              <TableCell>SPOT TYPE <span className="field-dot" /></TableCell>
              <TableCell>NETWORK ID <span className="field-dot" /></TableCell>
              <TableCell>API KEY <span className="field-dot" /></TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {provider.map((item) => (
              <TableRow
                key={`provider-item-${item._id}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Field
                    type="select"
                    name="mediaFormat"
                    dataId={`mediaFormat-${item._id}`}
                    value={item.mediaFormat}
                    options={MediaFormatHelper.list({ includeEmpty: true })}
                    validation={{
                      check: true,
                      checkEmpty: true,
                      events: ['pre-init', 'onBlur'],
                    }}
                    onValidate={onValidateField}
                    onChange={(e) => onChange(e, item._id, providerKey)}
                    required
                  />
                </TableCell>

                <TableCell>
                  <Field
                    type="select"
                    name="spotType"
                    dataId={`spotType-${item._id}`}
                    value={item.spotType}
                    options={SpotTypeHelper.list({ includeEmpty: true })}
                    validation={{
                      check: true,
                      checkEmpty: true,
                      events: ['pre-init', 'onBlur'],
                    }}
                    onValidate={onValidateField}
                    onChange={(e) => onChange(e, item._id, providerKey)}
                    required
                  />
                </TableCell>

                <TableCell>
                  <Field
                    type="text"
                    name="networkId"
                    dataId={`networkId-${item._id}`}
                    placeholder="Network ID"
                    value={item.networkId}
                    validation={{
                      check: true,
                      checkEmpty: true,
                      events: ['pre-init', 'onBlur'],
                    }}
                    onValidate={onValidateField}
                    onChange={(e) => onChange(e, item._id, providerKey)}
                    required
                  />
                </TableCell>

                <TableCell>
                  <Field
                    type="text"
                    name="apiKey"
                    dataId={`apiKey-${item._id}`}
                    placeholder="API Key"
                    value={item.apiKey}
                    validation={{
                      check: true,
                      checkEmpty: true,
                      events: ['pre-init', 'onBlur'],
                    }}
                    onValidate={onValidateField}
                    onChange={(e) => onChange(e, item._id, providerKey)}
                    required
                  />
                </TableCell>

                <TableCell>
                  <VibeButtonNew
                    variant="text"
                    text="Remove"
                    icon={viClose}
                    color={color.fireBrick}
                    style={{
                      fontSize: 14,
                    }}
                    onClick={() => onClickRemoveItem(item._id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <VibeModal
        show={confirmRemoveItem}
        type="confirm"
        title="Removing Configuration"
        text={(
          <div>
            You are about to remove a configuration from
            <span
              style={{
                padding: '0 4px',
                color: color.fireBrick,
                fontWeight: 'bold',
              }}
            >
              {adProviderName}.
            </span>
            Do you want to proceed?
          </div>
        )}
        confirmProps={{
          text: 'Remove Configuration',
          color: color.fireBrick,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmRemoveItem}
        onClose={onCancelRemoveItem}
      />

      <VibeModal
        show={confirmRemoveProvider}
        type="confirm"
        title="Removing Ad Provider"
        text={(
          <div>
            You are about to remove the
            <span
              style={{
                padding: '0 4px',
                color: color.fireBrick,
                fontWeight: 'bold',
              }}
            >
              {adProviderName}
            </span>
            Ad Provider and all configurations. Do you want to proceed?
          </div>
        )}
        confirmProps={{
          text: 'Remove Ad Provider',
          color: color.fireBrick,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmRemoveProvider}
        onClose={onCancelRemoveProvider}
      />
    </div>
  );
}

ProviderVistar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  providerKey: PropTypes.string.isRequired,
  provider: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    apiKey: PropTypes.string,
    mediaFormat: PropTypes.oneOf([
      '',
      'audio',
      'visual',
    ]),
    networkId: PropTypes.string,
    spotType: PropTypes.oneOf([
      '',
      'general',
      'endemic',
      'non-endemic',
      'adult-beverage',
    ]),
  })),
  onChange: PropTypes.func,
  onAddItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  onRemoveProvider: PropTypes.func,
};

ProviderVistar.defaultProps = {
  className: '',
  style: {},
  provider: [],
  onChange: () => {},
  onAddItem: () => {},
  onRemoveItem: () => {},
  onRemoveProvider: () => {},
};

export default ProviderVistar;
