import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import TablePartners from '../VibeTable/Tables/TablePartners';
import defaultCompanyImage from '../../assets/default_company.png';
import './PartnersAssigned.scss';

class PartnersAssigned extends PureComponent {
  constructor(props) {
    super(props);

    const {
      selected,
    } = props;

    this.state = {
      filteredItems: selected,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      selected,
    } = this.props;

    const {
      selected: prevSelected,
    } = prevProps;

    if (JSON.stringify(selected) !== JSON.stringify(prevSelected)) {
      this.setState({
        filteredItems: selected,
      });
    }
  }

  /**
   * When edit mode changes are saved
   */
  onSaveEditModeChanges = (rows) => {
    const {
      onChange,
    } = this.props;

    onChange(rows);
  };

  onAdd = (item) => {
    const {
      onChange,
    } = this.props;

    this.setState((state) => {
      const items = [
        ...state.filteredItems,
        item,
      ];

      onChange(items);

      return {
        filteredItems: items,
      };
    });
  };

  onRemove = (items) => {
    const {
      onChange,
    } = this.props;

    if (!isArray(items)) {
      items = [items];
    }

    const itemIds = items.map(item => item._id);

    this.setState((state) => {
      const items = state.filteredItems.filter(item => !itemIds.includes(item._id));
      onChange(items);

      return {
        filteredItems: items,
      };
    });
  };

  render() {
    const {
      selected,
      allowCustomOrder,
      allowChanges,
      customToolbar,
      onChange,
    } = this.props;

    const {
      filteredItems,
    } = this.state;

    const columns = [
      'Name',
      'Sensitive Data',
      '...',
    ];

    if (allowChanges) {
      columns.unshift('Add/Remove');
    }

    const missingData = selected.filter(partner => !partner.sensitiveData).length > 0;

    return (
      <div className="PartnersAssigned">
        <div className="title-container">
          <div className="title">
            Assigned Partners
          </div>

          {customToolbar}
        </div>

        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TablePartners
            columns={columns}
            collection={filteredItems}
            defaultSortBy={{
              label: 'Name',
              attr: 'name',
              direction: 'asc',
            }}
            paginator
            paginatorProps={{
              label: 'Partners',
              urlPaging: false,
              urlFilters: false,
            }}
            emptyProps={{
              image: defaultCompanyImage,
              title: 'No Partners Assigned',
              description: 'This object has not been assigned any partners.',
            }}
            editMode={allowChanges}
            forceEditMode={missingData}
            draggable={allowCustomOrder}
            droppable={allowCustomOrder}
            assigned
            highlight={filteredItems}
            onAdd={this.onAdd}
            onRemove={this.onRemove}
            onSaveEditModeChanges={this.onSaveEditModeChanges}
            onChange={onChange}
          />
        </div>
      </div>
    );
  }
}

PartnersAssigned.propTypes = {
  /** Allow custom order of objects in the table */
  allowCustomOrder: PropTypes.bool,
  /** Allow changes to the selection */
  allowChanges: PropTypes.bool,
  /** Custom toolbar */
  customToolbar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  selected: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
};

PartnersAssigned.defaultProps = {
  allowCustomOrder: false,
  allowChanges: false,
  customToolbar: null,
  selected: [],
  onChange: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(PartnersAssigned);
