import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Field2 as Field,
  DaySelector,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';

class PublishingOptions extends PureComponent {
  componentDidMount() {
    const {
      onUpdate,
    } = this.props;

    const dueDate = this.calculateDueDate();

    onUpdate({
      dueDate,
    });
  }

  /**
   * When the start date is changed
   */
  onChangeStartDate = (startDate) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate({
      startDate,
    });
  };

  /**
   * When the stop date is changed
   */
  onChangeStopDate = (endDate) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate({
      endDate,
    });
  };

  /**
   * When the due date is changed
   */
  onChangeDueDate = (dueDate) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate({
      dueDate,
    });
  };

  /**
   * Changing the selected days of the week
   */
  onChangeDays = (days) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate({
      daysOfWeek: days,
    });
  };

  calculateDueDate = () => {
    const {
      isNew,
      dueDate,
    } = this.props;

    if (!isNew) {
      return dueDate;
    }

    // Default Due Date = +2 Business Days From Today
    let daysToAdd = 3;
    const dayBefore = moment().day();
    const dayAfter = moment().add(daysToAdd, 'days').day();

    // If rolled over to the next week or Saturday, skip the weekend
    if (dayBefore > dayAfter || dayAfter === 6) {
      daysToAdd += 2;
    }

    const defaultDate = moment()
      .add(daysToAdd, 'days')
      .format('YYYY-MM-DD');

    return defaultDate;
  };

  render() {
    const {
      type,
      startDate,
      endDate,
      dueDate,
      disableInput,
      daysOfWeek,
      allowDueDate,
      isAd,
      isNew,
      isRequest,
    } = this.props;

    return (
      <div className="PublishingOptions">
        <Grid
          className="row extra-space"
          spacing={2}
          container
        >
          <Grid
            className="details-title"
            xs={12}
            item
          >
            Publishing Options
          </Grid>
        </Grid>

        {!isAd && (
          <Grid
            className="row"
            spacing={2}
            container
          >
            <Grid xs={6} item>
              <Field
                type="datepicker"
                label="Start"
                value={startDate}
                tabIndex={8}
                dateProps={{
                  startOfDay: true,
                  format: 'YYYY-MM-DD',
                  tooltipDate: startDate
                    ? moment(startDate)
                      .startOf('day')
                      .format('MMMM DD, YYYY [at] hh:mm:ss A')
                    : '',
                  maxDate: moment(endDate).toDate(),
                }}
                disabled={type === 'air' || type === 'spec' || type === 'ext-ad' || disableInput}
                onChange={this.onChangeStartDate}
              />
            </Grid>

            <Grid xs={6} item>
              <Field
                type="datepicker"
                label="End"
                value={endDate}
                tabIndex={9}
                dateProps={{
                  endOfDay: true,
                  format: 'YYYY-MM-DD',
                  tooltipDate: endDate
                    ? moment(endDate)
                      .endOf('day')
                      .format('MMMM DD, YYYY [at] hh:mm:ss A')
                    : '',
                  minDate: moment(startDate).toDate(),
                }}
                disabled={type === 'air' || type === 'spec' || type === 'ext-ad' || disableInput}
                onChange={this.onChangeStopDate}
              />
            </Grid>
          </Grid>
        )}

        <Grid
          className="row"
          spacing={2}
          container
        >
          {!isAd && (
            <Grid xs={6} item>
              <Field
                type="custom"
                label="Days of the Week"
              >
                <DaySelector
                  dayFormat="dd"
                  disabled={type === 'air' || type === 'spec' || type === 'ext-ad' || type === 'ad' || disableInput}
                  selectedDays={daysOfWeek}
                  toggleAll
                  onChange={this.onChangeDays}
                />
              </Field>
            </Grid>
          )}

          {(isRequest || isNew) && allowDueDate && (
            <Grid xs={6} item>
              <Field
                type="datepicker"
                label="Due Date"
                value={dueDate}
                tabIndex={8}
                dateProps={{
                  endOfDay: true,
                  format: 'YYYY-MM-DD',
                  minDate: new Date(`${dueDate}T00:00:00`),
                  tooltip: 'Expected completion in 3-5 business days',
                }}
                disabled={disableInput}
                onChange={this.onChangeDueDate}
                required
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

PublishingOptions.propTypes = {
  type: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  dueDate: PropTypes.string,
  daysOfWeek: PropTypes.arrayOf(PropTypes.string),
  disableInput: PropTypes.bool,
  allowDueDate: PropTypes.bool,
  isAd: PropTypes.bool,
  isNew: PropTypes.bool,
  isRequest: PropTypes.bool,
  onUpdate: PropTypes.func,
};

PublishingOptions.defaultProps = {
  type: '',
  startDate: '',
  endDate: '',
  dueDate: '',
  daysOfWeek: [],
  disableInput: false,
  allowDueDate: false,
  isAd: false,
  isNew: false,
  isRequest: false,
  onUpdate: () => {},
};

export default PublishingOptions;
