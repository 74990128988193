// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidePanelHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 72px;
  min-height: 72px;
  padding: 0 24px;
  box-sizing: border-box;
  border-bottom: 1px solid #E1E1E9;
  overflow: hidden;
}
.SidePanelHeader .header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.SidePanelHeader .header-container .header-content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  min-width: 0;
}
.SidePanelHeader .header-container .header-content .title {
  flex-grow: 1;
  font-size: 18px;
  line-height: 24px;
  font-weight: 900;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.SidePanelHeader .header-container .flex-horizontal {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.SidePanelHeader .toolbar {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.SidePanelHeader .toolbar .VibeIcon {
  padding: 24px 4px;
}
.SidePanelHeader .toolbar .VibeIcon:first-child {
  padding-left: 24px;
}
.SidePanelHeader .toolbar .VibeIcon.left-icon {
  padding: 0;
  margin-right: 4px;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/SidePanel/SidePanelHeader.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,sBAAA;EACA,gCAAA;EACA,gBAAA;AAuFF;AArFE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAuFJ;AArFI;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AAuFN;AArFM;EACE,YAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AAuFR;AAnFI;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AAqFN;AAjFE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AAmFJ;AAhFI;EACE,iBAAA;AAkFN;AAhFM;EACE,kBAAA;AAkFR;AA1EM;EACE,UAAA;EACA,iBAAA;AA4ER","sourcesContent":["@import '../../sass/color.scss';\n\n.SidePanelHeader {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: 72px;\n  min-height: 72px;\n  padding: 0 24px;\n  box-sizing: border-box;\n  border-bottom: 1px solid $whiteSmoke;\n  overflow: hidden;\n\n  .header-container {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    .header-content {\n      flex-grow: 1;\n      display: flex;\n      align-items: center;\n      min-width: 0;\n\n      .title {\n        flex-grow: 1;\n        font-size: 18px;\n        line-height: 24px;\n        font-weight: 900;\n        overflow: hidden;\n        white-space: nowrap;\n        text-overflow: ellipsis;\n      }\n    }\n\n    .flex-horizontal {\n      display: flex;\n      align-items: center;\n      overflow: hidden;\n    }\n  }\n\n  .toolbar {\n    display: flex;\n    align-items: center;\n    white-space: nowrap;\n\n    // Overwrite the icon component style to pad toolbar icons\n    .VibeIcon {\n      padding: 24px 4px;\n\n      &:first-child {\n        padding-left: 24px;\n      }\n\n      // &:last-child {\n      //   padding-right: 24px;\n      // }\n\n      // Do not allow padding for the left icon\n      &.left-icon {\n        padding: 0;\n        margin-right: 4px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aquaForest": `#00a474`,
	"aquaForest8": `rgba(0, 164, 116, 0.08)`,
	"aquaForest16": `rgba(0, 164, 116, 0.16)`,
	"aquaForest75": `rgba(0, 164, 116, 0.75)`,
	"alertYellow": `#FDB022`,
	"black": `#000000`,
	"boxRed": `#cf0838`,
	"boxPink": `#f57a7a`,
	"boxOrange": `#ff892e`,
	"boxYellow": `#ffd22e`,
	"boxGreen": `#95c653`,
	"boxDarkGreen": `#00a474`,
	"boxBlue": `#33ace1`,
	"boxDarkBlue": `#3361e1`,
	"boxPurple": `#6d6db5`,
	"boxDarkPurple": `#332a4b`,
	"carrot": `#ff892e`,
	"carrot16": `rgba(255, 137, 46, 0.16)`,
	"cerulean": `#33ace1`,
	"cerulean16": `rgba(51, 172, 225, 0.16)`,
	"cosmicVibe": `#332a4b`,
	"cosmicVibe25": `rgba(51, 42, 75, 0.25)`,
	"cosmicVibe50": `rgba(51, 42, 75, 0.5)`,
	"cosmicVibe75": `rgba(51, 42, 75, 0.75)`,
	"crimson": `#c90a31`,
	"darkGray": `#a4a7b7`,
	"darkSlateGray": `#2f2947`,
	"deleteRed": `#BE2A3D`,
	"dimGray": `#545454`,
	"fireBrick": `#cf0838`,
	"fireBrick8": `rgba(207, 8, 56, 0.08)`,
	"fireBrick10": `rgba(207, 8, 56, 0.1)`,
	"fireBrick16": `rgba(207, 8, 56, 0.16)`,
	"fireBrick20": `rgba(207, 8, 56, 0.2)`,
	"fireBrick50": `rgba(207, 8, 56, 0.5)`,
	"fireBrick75": `rgba(207, 8, 56, 0.75)`,
	"fireBrick85": `rgba(207, 8, 56, 0.85)`,
	"flamingo": `#f66733`,
	"flamingo16": `rgba(246, 103, 51, 0.16)`,
	"flamingo75": `rgba(246, 103, 51, 0.75)`,
	"grainsboro": `#d5d8e1`,
	"graphBlue": `#1570EF`,
	"gray": `#757575`,
	"gray50": `#F9FAFB`,
	"gray200": `#EAECF0`,
	"gray300": `#D0D5DD`,
	"gray500": `#667085`,
	"gray700": `#344054`,
	"gray900": `#101828`,
	"lavendarVibe": `#eae9fc`,
	"lightBlue": `#ccdfe8`,
	"lightDimGray": `#6b6b6b`,
	"lighterGray": `#e3e7f4`,
	"lightGray": `#f6f6f6`,
	"lightSlateGray": `#8a8fa3`,
	"lightSmoke": `#f5f4f5`,
	"manatee": `#959aad`,
	"manatee16": `rgba(149, 154, 173, 0.16)`,
	"manatee50": `rgba(149, 154, 173, 0.5)`,
	"mediumGray": `#e0e0e0`,
	"mediumOrchid": `#9932cc`,
	"lightOrchid": `#7F56D9`,
	"obsidian": `#383838`,
	"periwinkle": `#e7e7ff`,
	"rainboots": `#3361e1`,
	"rainboots16": `rgba(51, 97, 225, 0.16)`,
	"royalBlue": `#4085de`,
	"royalBlue16": `rgba(64, 133, 222, 0.16)`,
	"sushi": `#95c653`,
	"teal": `#029a69`,
	"vibeLightPurple": `#e2e1fe`,
	"vibeMediumPurple": `#e7e7ff`,
	"violetVibe": `#6d6db5`,
	"violetVibe16": `rgba(109, 109, 181, 0.16)`,
	"violetVibe40": `rgba(109, 109, 181, 0.4)`,
	"violetVibe50": `rgba(109, 109, 181, 0.5)`,
	"violetVibe60": `rgba(109, 109, 181, 0.6)`,
	"warning50": `#FFFAEB`,
	"warning100": `#FEF0C7`,
	"white": `#ffffff`,
	"whiteSmoke": `#E1E1E9`,
	"yellow": `#ffc107`,
	"yellow16": `rgba(255, 193, 7, 0.16)`,
	"yellow75": `rgba(255, 193, 7, 0.75)`,
	"lemon": `#ffd22e`
};
export default ___CSS_LOADER_EXPORT___;
