import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  API,
  NewMessageRequestActions,
  GlobalActions,
  NavigationHelper,
  TableMessages,
  VibeButton,
  VibeIcon,
  viAdd,
  color,
  withRouter,
} from 'vibeguide';
import MessageSidebar from '../../../../Messages/NewMessageRequest/Sidebar/MessageSidebar';
import './Messages.scss';

class Messages extends Component {
  constructor(props) {
    super(props);

    this.sidebarTimeout = null;
  }

  componentDidMount() {
    const qs = NavigationHelper.getParams();
    const isNew = qs.type === 'new';
    const isView = qs.messageId && qs.type !== 'new';

    if (isNew || isView) {
      this.sidebarTimeout = setTimeout(() => {
        this.sidebar(qs.type, qs.messageId);
      }, 1500);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        search,
      },
    } = this.props;

    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.messageId || qs.type === 'new') {
        this.sidebar(qs.type, qs.messageId);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.sidebarTimeout);
  }

  /**
   * When the sidebar is closed
   */
  onCloseSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      messageId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  /**
   * Determine whether or not to show or hide the sidebar
   */
  sidebar = (type, messageId = null) => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: true,
      backdrop: type === 'new',
      children: (
        <MessageSidebar
          type={type === 'new'
            ? 'request'
            : 'library'}
          messageId={messageId}
          isNew={type === 'new'}
          isDuplicate={type === 'new' && messageId !== null}
        />
      ),
      onClose: this.onCloseSidebar,
    });
  };

  render() {
    const {
      currentLocation: {
        _id: locationId,
      },
      user,
    } = this.props;

    return (
      <div className="Messages">
        <TableMessages
          columns={[
            '.',
            'Message Name',
            'Last Updated',
            'Requester',
            'Start Date',
            'End Date',
            'Duration',
            'Script',
            'Assigned Voice Talent',
            'Tags',
            '...',
          ]}
          fetch={API.Location.listMessages}
          fetchProps={{
            filters: {
              _id: locationId,
            },
          }}
          defaultSortBy={{
            label: 'Last Updated',
            attr: 'modifiedDate',
            direction: 'desc',
          }}
          paginator
          paginatorProps={{
            label: 'Messages',
            urlPaging: true,
            urlFilters: true,
          }}
          toggle
          toggleProps={{
            label: 'Show Archived Messages',
            tooltip: true,
          }}
          bulk
          bulkProps={{
            edit: true,
            add: false,
            block: false,
            remove: false,
            archive: true,
          }}
          submenu
          submenuProps={{
            rootUrl: `/location/${locationId}/assignments/messages`,
            style: {
              borderTop: `1px solid ${color.whiteSmoke}`,
            },
            items: [
              {
                name: 'Regular',
                show: user.can('message.view'),
                enabled: true,
                paginatorLabel: 'Messages',
              },
              {
                name: 'Ads',
                show: user.can('advertisement.view'),
                enabled: true,
                filters: {
                  messageType: 'ad',
                },
              },
              {
                name: 'External Ads',
                show: user.can('advertisement.view'),
                enabled: true,
                filters: {
                  messageType: 'ext-ad',
                },
              },
              {
                name: 'Sounders',
                show: user.isAdminCompany && user.can('message.view'),
                enabled: false,
                filters: {
                  messageType: 'sounder',
                },
              },
              {
                name: 'Air Checks',
                show: user.isAdminCompany && user.can('message.view'),
                enabled: true,
                filters: {
                  messageType: 'air',
                },
              },
              {
                name: 'Spec Spots',
                show: user.isAdminCompany && user.can('message.view'),
                enabled: true,
                filters: {
                  messageType: 'spec',
                },
              },
            ],
          }}
          rowLink={{
            messageId: '_id',
          }}
          menuItems={(row) => {
            const isAd = row.messageType === 'ad'
              || row.messageType === 'ext-ad';

            return [{
              name: 'Archive',
              userCan: isAd
                ? 'advertisement.delete'
                : 'message.delete',
            }];
          }}
          toolbarProps={{
            customButtons: user.can('message.request') || user.can('advertisement.request')
              ? [
                (
                  <VibeButton
                    key="button-new-message"
                    text="New Message"
                    btnColor="green"
                    btnLink={NavigationHelper.updateParams({
                      type: 'new',
                    })}
                    textColor="white"
                    icon={(
                      <VibeIcon
                        icon={viAdd}
                        color={color.white}
                        size={18}
                      />
                    )}
                  />
                ),
              ]
              : [],
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

const mapDispatchToProps = {
  setMessageCompany: NewMessageRequestActions.setMessageCompany,
  addMessageLocation: NewMessageRequestActions.addLocation,
  setLastUrl: GlobalActions.setLastUrl,
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Messages));
