import viSpeaker from '../icons/viSpeaker';
import viTv from '../icons/viTv';
import viHelp from '../icons/viHelp';

/**
 * List of supported Media Formats
 * @param options object of options to build the list
 * @param options.includeEmpty boolean to include an empty name/value pair at the beginning
 */
export function list(options = {}) {
  const list = [
    {
      label: 'Audio',
      value: 'audio',
    },
    {
      label: 'Visual',
      value: 'visual',
    },
  ];

  if (options.includeEmpty === true) {
    list.unshift({
      label: '',
      value: '',
    });
  }

  return list;
}

/**
 * Get icon for the media format
 */
export function getIcon(mediaFormat) {
  switch (mediaFormat) {
    case 'visual':
      return viTv;

    case 'audio':
      return viSpeaker;

    default:
      return viHelp;
  }
}

/**
 * Get tooltip for the media format
 */
export function getTooltip(mediaFormat) {
  switch (mediaFormat) {
    case 'visual':
      return 'Visual';

    case 'audio':
      return 'Audio';

    default:
      return 'Unknown';
  }
}

export default {
  list,
  getIcon,
  getTooltip,
};
