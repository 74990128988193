import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import moment from 'moment';
import {
  API,
  NavigationHelper,
  TableLocations,
  VibeButtonNew,
  viAdd,
  color,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../../Header/SubNavigation';
import defaultCompanyImage from '../../../assets/default_company.png';
import './LocationViewTable.scss';

function onExport(data) {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
  const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
  saveAs(blob, `locations-${timestamp}.csv`);
}

function LocationViewTable({
  user,
  onClickNewLocation,
}) {
  const qs = NavigationHelper.getParams() || {};

  // do not automatically load locations if the user has more than 50 locations assigned to them
  // or if they are assigned to ACAF companies and/or locations
  // exception if the URL has a parameter to forcibly load locations
  const suppressLocationLookup = !qs.force
    && (user.locationsAllowed.length > 50
    || user.locationsAllowed.some(({ companyId, locationId }) => companyId === '*' || locationId === '*'));

  const [requireFilter, setRequireFilter] = useState(suppressLocationLookup);

  /**
   * Ensure a filter is provided if required
   */
  const onFetchComplete = ({
    requireFilter = false,
  }) => {
    setRequireFilter(requireFilter);
  };

  /**
   * Fetch locations when the force flag is changed
   */
  useEffect(() => {
    const suppressLocationLookup = !qs.force
      && (user.locationsAllowed.length > 50
        || user.locationsAllowed.some(({ companyId, locationId }) => companyId === '*' || locationId === '*'));

    if (requireFilter !== suppressLocationLookup) {
      setRequireFilter(suppressLocationLookup);
      document.dispatchEvent(new Event('onSaveLocation'));
    }
  }, [qs.force]);

  const isPartnerUser = user.accountType === 'partner';
  const locationDetailsRoute = isPartnerUser ? '' : '/location/{_id}/schedule';
  const getLocations = isPartnerUser ? API.PartnerUser.getLocations : API.User.getLocations;

  return (
    <div className="LocationViewTable">
      <SubNavigation
        title="Locations"
        buttons={[
          {
            text: 'New Location',
            icon: viAdd,
            userCan: [
              'location.create',
              'admin.system_admin',
            ],
            onClick: onClickNewLocation,
          },
        ]}
      />

      <TableLocations
        columns={[
          '.',
          'Name',
          'Company',
          'Category',
          'Status',
          'Media Format',
          'Address 1',
          'Address 2',
          'Country',
          'City',
          'State/Province',
          'Postal Code',
          'Latitude',
          'Longitude',
          'DMA',
          'Ad Network',
          'Ad Programs',
          'Content Network Owner',
          'Content Delivery System',
          'Ad Delivery System',
          'Ad Network Owner',
          'SAN',
          'Tags',
          '...',
        ]}
        fetch={getLocations}
        fetchProps={{
          filters: {
            _id: user._id,
          },
          // only fetch when a filter is supplied
          requireFilter,
        }}
        defaultSortBy={{
          label: 'Name',
          attr: 'name',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Locations',
          urlPaging: true,
          urlFilters: true,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Locations',
          tooltip: true,
        }}
        bulk={user.can('location.modify') || user.can('location.delete')}
        bulkProps={{
          edit: true,
          add: false,
          block: false,
          remove: false,
          archive: true,
        }}
        emptyProps={{
          image: defaultCompanyImage,
          imageStyle: {
            maxHeight: 150,
          },
          title: !requireFilter
            ? 'No Results'
            : 'A Search Query is Required',
          description: !requireFilter
            ? 'Nothing was found, try changing your filters'
            : 'You must include at least one filter to view results',
          buttonHtml: !requireFilter
            ? null
            : (
              <VibeButtonNew
                text="View All Locations"
                color={color.violetVibe}
                link={NavigationHelper.updateParams({
                  force: true,
                })}
              />
            ),
        }}
        rowLink={locationDetailsRoute}
        menuItems={!isPartnerUser ? [
          {
            name: 'Quick View',
            link: {
              locationId: '_id',
            },
            userCan: 'location.view',
          },
          { name: 'Archive', userCan: 'location.delete' },
        ] : []}
        csv
        csvProps={{
          onExport,
        }}
        onFetchComplete={onFetchComplete}
      />
    </div>
  );
}

LocationViewTable.propTypes = {
  /** When new location is selected */
  onClickNewLocation: PropTypes.func,
};

LocationViewTable.defaultProps = {
  onClickNewLocation: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default withRouter(connect(mapStateToProps)(LocationViewTable));

// import React, { useState } from 'react';
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
// import { saveAs } from 'file-saver';
// import moment from 'moment';
// import {
//   isEqual,
// } from 'lodash';
// import {
//   API,
//   StorageUtil,
//   TableLocations,
//   VibeIcon,
//   viAdd,
//   viLocationOn,
//   viLocationOff,
//   viZoomInMap,
//   viZoomOutMap,
//   color,
// } from 'vibeguide';
// import SubNavigation from '../../Header/SubNavigation';
// import LocationViewMap from './LocationViewMap';
// import './LocationViewTable.scss';

// function onExport(data) {
//   const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
//   const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
//   saveAs(blob, `locations-${timestamp}.csv`);
// }

// function LocationViewTable({
//   user,
//   onClickNewLocation,
// }) {
//   const [filters, setFilters] = useState({
//     active: true,
//   });
//   const [showMap, setShowMap] = useState(StorageUtil.getLocal('locations:map', true));
//   const [mapFullView, setMapFullView] = useState(StorageUtil.getLocal('locations:mapFullView', false));

//   const rootStyle = mapFullView
//     // map is expanded to the full view
//     ? {
//       height: 154,
//       flexGrow: 0,
//     }
//     // map is collapsed to the small view
//     : {};

//   /**
//    * Update the map view with the filters used in the table
//    */
//   const onFetchComplete = ({
//     filters: newFilters,
//   }) => {
//     if (!isEqual(filters, newFilters)) {
//       setFilters(newFilters);
//     }
//   };

//   /**
//    * Toggle the map view
//    */
//   const onToggleMap = () => {
//     const newShowMap = !showMap;

//     StorageUtil.setLocal('locations:map', newShowMap);

//     if (!newShowMap) {
//       // hiding the map, ensure it is also not expanded
//       StorageUtil.setLocal('locations:mapFullView', false);
//       setMapFullView(false);
//     }

//     setShowMap(newShowMap);
//   };

//   /**
//    * Toggle the map full view
//    */
//   const onToggleMapFullView = () => {
//     StorageUtil.setLocal('locations:mapFullView', !mapFullView);
//     setMapFullView(!mapFullView);
//   };

//   return (
//     <div className="LocationViewTable">
//       <SubNavigation
//         title="Locations"
//         buttons={[
//           {
//             text: 'New Location',
//             icon: viAdd,
//             userCan: [
//               'location.create',
//               'admin.system_admin',
//             ],
//             onClick: onClickNewLocation,
//           },
//         ]}
//       />

//       {showMap && (
//         <LocationViewMap
//           filters={filters}
//           height={mapFullView
//             ? 'auto'
//             : 200}
//           style={{
//             borderTop: `1px solid ${color.whiteSmoke}`,
//           }}
//         />
//       )}

//       <TableLocations
//         rootStyle={rootStyle}
//         // allow the header row to be scrolled when the map is expanded
//         allowHeaderRowScroll={mapFullView}
//         columns={[
//           '.',
//           'Name',
//           'Company',
//           'Status',
//           'Address 1',
//           'Address 2',
//           'City',
//           'State',
//           'Zip',
//           'DMA',
//           'Ads',
//           'Tags',
//           '...',
//         ]}
//         fetch={API.User.getLocations}
//         fetchProps={{
//           filters: {
//             _id: user._id,
//           },
//         }}
//         defaultSortBy={{
//           label: 'Name',
//           attr: 'name',
//           direction: 'asc',
//         }}
//         paginator
//         paginatorProps={{
//           label: 'Locations',
//           urlPaging: true,
//           urlFilters: true,
//         }}
//         toggle
//         toggleProps={{
//           label: 'Show Archived Locations',
//           tooltip: true,
//         }}
//         bulk
//         bulkProps={{
//           edit: true,
//           add: false,
//           block: false,
//           remove: false,
//           archive: true,
//         }}
//         toolbarProps={{
//           customHtmlRight: (
//             <div>
//               <VibeIcon
//                 className="density-icon"
//                 id="toggle-show-map"
//                 icon={showMap
//                   ? viLocationOn
//                   : viLocationOff}
//                 color={showMap
//                   ? color.violetVibe
//                   : color.whiteSmoke}
//                 hoverColor={color.cosmicVibe}
//                 size={24}
//                 tooltip={showMap
//                   ? 'Hide Map'
//                   : 'Show Map'}
//                 tooltipProps={{
//                   placement: 'top',
//                 }}
//                 onClick={onToggleMap}
//               />

//               {showMap && (
//                 <VibeIcon
//                   className="density-icon"
//                   id="toggle-map-full-view"
//                   icon={mapFullView
//                     ? viZoomInMap
//                     : viZoomOutMap}
//                   color={color.manatee}
//                   hoverColor={color.cosmicVibe}
//                   size={24}
//                   tooltip={mapFullView
//                     ? 'Collapse Map'
//                     : 'Expand Map'}
//                   tooltipProps={{
//                     placement: 'top',
//                   }}
//                   onClick={onToggleMapFullView}
//                 />
//               )}
//             </div>
//           ),
//         }}
//         rowLink="/location/{_id}/schedule"
//         menuItems={[
//           {
//             name: 'Quick View',
//             link: {
//               locationId: '_id',
//             },
//             userCan: 'location.view',
//           },
//           { name: 'Archive', userCan: 'location.delete' },
//         ]}
//         csv
//         csvProps={{
//           onExport,
//         }}
//         onFetchComplete={onFetchComplete}
//       />
//     </div>
//   );
// }

// LocationViewTable.propTypes = {
//   /** When new location is selected */
//   onClickNewLocation: PropTypes.func,
// };

// LocationViewTable.defaultProps = {
//   onClickNewLocation: () => {},
// };

// function mapStateToProps(state) {
//   return {
//     user: state.login.user,
//   };
// }

// export default connect(mapStateToProps)(LocationViewTable);
