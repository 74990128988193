// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PhoneField {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #E1E1E9;
}
.PhoneField.readonly .MuiOutlinedInput-notchedOutline {
  border: none;
}
.PhoneField.readonly input {
  -webkit-text-fill-color: #383838;
}
.PhoneField .MuiOutlinedInput-notchedOutline {
  border-color: #E1E1E9 !important;
}
.PhoneField .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #6d6db5 !important;
  border-width: 1px !important;
}
.PhoneField input {
  font-size: 11px;
  height: 32px !important;
  padding: 8px;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  color: #383838 !important;
}
.PhoneField .MuiTelInput-IconButton {
  width: 28px;
  height: auto;
}
.PhoneField .MuiTelInput-IconButton:hover {
  background: transparent;
}

.MuiTelInput-Menu {
  z-index: 10000 !important;
}
.MuiTelInput-Menu .MuiTelInput-ListItemText-country span {
  font-size: 11px !important;
}
.MuiTelInput-Menu .MuiTelInput-ListItemIcon-flag {
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/Field/PhoneField.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,kBAAA;EACA,yBAAA;AAuFF;AAnFI;EACE,YAAA;AAqFN;AAlFI;EACE,gCAAA;AAoFN;AAhFE;EACE,gCAAA;AAkFJ;AA7EI;EACE,gCAAA;EACA,4BAAA;AA+EN;AA3EE;EACE,eAAA;EACA,uBAAA;EACA,YAAA;EACA,sBAAA;EACA,+BAAA;EACA,yBAAA;AA6EJ;AA1EE;EACE,WAAA;EACA,YAAA;AA4EJ;AA1EI;EACE,uBAAA;AA4EN;;AAvEA;EACE,yBAAA;AA0EF;AAtEI;EACE,0BAAA;AAwEN;AApEE;EACE,eAAA;AAsEJ","sourcesContent":["@import '../../sass/color.scss';\r\n\r\n.PhoneField {\r\n  width: 100%;\r\n  border-radius: 4px;\r\n  border: 1px solid $whiteSmoke;\r\n\r\n  &.readonly {\r\n\r\n    .MuiOutlinedInput-notchedOutline {\r\n      border: none;\r\n    }\r\n\r\n    input {\r\n      -webkit-text-fill-color: rgba($obsidian, 1);\r\n    }\r\n  }\r\n\r\n  .MuiOutlinedInput-notchedOutline {\r\n    border-color: $whiteSmoke !important;\r\n  }\r\n\r\n  .Mui-focused {\r\n\r\n    .MuiOutlinedInput-notchedOutline {\r\n      border-color: $violetVibe !important;\r\n      border-width: 1px !important;\r\n    }\r\n  }\r\n\r\n  input {\r\n    font-size: 11px;\r\n    height: 32px !important;\r\n    padding: 8px;\r\n    box-sizing: border-box;\r\n    font-family: 'Lato', sans-serif;\r\n    color: $obsidian !important;\r\n  }\r\n\r\n  .MuiTelInput-IconButton {\r\n    width: 28px;\r\n    height: auto;\r\n\r\n    &:hover {\r\n      background: transparent;\r\n    }\r\n  }\r\n}\r\n\r\n.MuiTelInput-Menu {\r\n  z-index: 10000 !important;\r\n\r\n  .MuiTelInput-ListItemText-country {\r\n\r\n    span {\r\n      font-size: 11px !important;\r\n    }\r\n  }\r\n\r\n  .MuiTelInput-ListItemIcon-flag {\r\n    margin-right: 0;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aquaForest": `#00a474`,
	"aquaForest8": `rgba(0, 164, 116, 0.08)`,
	"aquaForest16": `rgba(0, 164, 116, 0.16)`,
	"aquaForest75": `rgba(0, 164, 116, 0.75)`,
	"alertYellow": `#FDB022`,
	"black": `#000000`,
	"boxRed": `#cf0838`,
	"boxPink": `#f57a7a`,
	"boxOrange": `#ff892e`,
	"boxYellow": `#ffd22e`,
	"boxGreen": `#95c653`,
	"boxDarkGreen": `#00a474`,
	"boxBlue": `#33ace1`,
	"boxDarkBlue": `#3361e1`,
	"boxPurple": `#6d6db5`,
	"boxDarkPurple": `#332a4b`,
	"carrot": `#ff892e`,
	"carrot16": `rgba(255, 137, 46, 0.16)`,
	"cerulean": `#33ace1`,
	"cerulean16": `rgba(51, 172, 225, 0.16)`,
	"cosmicVibe": `#332a4b`,
	"cosmicVibe25": `rgba(51, 42, 75, 0.25)`,
	"cosmicVibe50": `rgba(51, 42, 75, 0.5)`,
	"cosmicVibe75": `rgba(51, 42, 75, 0.75)`,
	"crimson": `#c90a31`,
	"darkGray": `#a4a7b7`,
	"darkSlateGray": `#2f2947`,
	"deleteRed": `#BE2A3D`,
	"dimGray": `#545454`,
	"fireBrick": `#cf0838`,
	"fireBrick8": `rgba(207, 8, 56, 0.08)`,
	"fireBrick10": `rgba(207, 8, 56, 0.1)`,
	"fireBrick16": `rgba(207, 8, 56, 0.16)`,
	"fireBrick20": `rgba(207, 8, 56, 0.2)`,
	"fireBrick50": `rgba(207, 8, 56, 0.5)`,
	"fireBrick75": `rgba(207, 8, 56, 0.75)`,
	"fireBrick85": `rgba(207, 8, 56, 0.85)`,
	"flamingo": `#f66733`,
	"flamingo16": `rgba(246, 103, 51, 0.16)`,
	"flamingo75": `rgba(246, 103, 51, 0.75)`,
	"grainsboro": `#d5d8e1`,
	"graphBlue": `#1570EF`,
	"gray": `#757575`,
	"gray50": `#F9FAFB`,
	"gray200": `#EAECF0`,
	"gray300": `#D0D5DD`,
	"gray500": `#667085`,
	"gray700": `#344054`,
	"gray900": `#101828`,
	"lavendarVibe": `#eae9fc`,
	"lightBlue": `#ccdfe8`,
	"lightDimGray": `#6b6b6b`,
	"lighterGray": `#e3e7f4`,
	"lightGray": `#f6f6f6`,
	"lightSlateGray": `#8a8fa3`,
	"lightSmoke": `#f5f4f5`,
	"manatee": `#959aad`,
	"manatee16": `rgba(149, 154, 173, 0.16)`,
	"manatee50": `rgba(149, 154, 173, 0.5)`,
	"mediumGray": `#e0e0e0`,
	"mediumOrchid": `#9932cc`,
	"lightOrchid": `#7F56D9`,
	"obsidian": `#383838`,
	"periwinkle": `#e7e7ff`,
	"rainboots": `#3361e1`,
	"rainboots16": `rgba(51, 97, 225, 0.16)`,
	"royalBlue": `#4085de`,
	"royalBlue16": `rgba(64, 133, 222, 0.16)`,
	"sushi": `#95c653`,
	"teal": `#029a69`,
	"vibeLightPurple": `#e2e1fe`,
	"vibeMediumPurple": `#e7e7ff`,
	"violetVibe": `#6d6db5`,
	"violetVibe16": `rgba(109, 109, 181, 0.16)`,
	"violetVibe40": `rgba(109, 109, 181, 0.4)`,
	"violetVibe50": `rgba(109, 109, 181, 0.5)`,
	"violetVibe60": `rgba(109, 109, 181, 0.6)`,
	"warning50": `#FFFAEB`,
	"warning100": `#FEF0C7`,
	"white": `#ffffff`,
	"whiteSmoke": `#E1E1E9`,
	"yellow": `#ffc107`,
	"yellow16": `rgba(255, 193, 7, 0.16)`,
	"yellow75": `rgba(255, 193, 7, 0.75)`,
	"lemon": `#ffd22e`
};
export default ___CSS_LOADER_EXPORT___;
