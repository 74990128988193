import React from 'react';
import InfoCard from './InfoCard';

function InfoCards({
  data,
}) {
  const deliveredImpressions = data.deliveredImpressions || 0;
  const targetImpressions = data.targetImpressions || 0;
  const totalImpressions = data.totalImpressions || 0;
  const deliveredSpots = data.deliveredSpots || 0;
  const totalSpots = data.totalSpots || 0;
  const mediaCost = data.mediaCost || 0;
  const budget = data.budget || 0;
  const cpm = data.cpm || data.weightedCpm || 0;
  const mediaValuePercent = (mediaCost / budget) * 100 || 0;
  const isViewOnly = data.partnerAccessLevel === 'view-only'
    || data.partnerAccessLevel === 'hidden';
  const isHidden = data.partnerAccessLevel === 'hidden';

  return (
    <div
      className="InfoCards"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: 16,
        margin: '2rem',
      }}
    >
      <InfoCard
        valueLabel="Impressions Delivered"
        valueLabelBadge={data.segmentType === 'added-value'
          ? 'Added Value'
          : ''}
        value={deliveredImpressions}
        subValues={[
          {
            label: 'Target Budgeted Impressions',
            value: targetImpressions,
          },
          {
            label: 'Total Allocated Impressions',
            value: totalImpressions,
          },
        ]}
        percentage={(deliveredImpressions / targetImpressions) * 100 || 0}
        isViewOnly={isViewOnly}
      />

      <InfoCard
        valueLabel="Spots Delivered"
        value={deliveredSpots}
        subValues={[
          {
            label: 'Total Spots',
            value: totalSpots,
          },
        ]}
        percentage={(deliveredSpots / totalSpots) * 100 || 0}
        isViewOnly={isViewOnly}
      />

      <InfoCard
        valueLabel="Media Value Delivered"
        value={mediaCost}
        valueBadge={mediaValuePercent >= 1
          // only show the badge when percent complete reaches at least 1%
          ? `${Math.floor(mediaValuePercent)}%`
          : ''}
        subValues={!isHidden
          ? [{
            label: 'Target Budget',
            value: budget,
          }]
          : []}
        percentage={!isHidden
          ? mediaValuePercent
          : null}
        type="currency"
        isViewOnly={isViewOnly}
      />

      <InfoCard
        valueLabel="Weighted CPM (ECPM)"
        value={cpm}
        align="center"
        type="currency"
        isViewOnly={isViewOnly}
      />
    </div>
  );
}

export default InfoCards;
