import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  isArray,
} from 'lodash';
import {
  setPanel,
} from '../../actions/Global/GlobalActions';
import {
  group,
} from '../../helpers/Location';
import Location from '../../models/ExpandedLocations/Location';
import Company from '../../models/ExpandedLocations/Company';
import API from '../../api';
import AssignObject from './AssignObject';
import Dropdown from '../Dropdown/Dropdown';
import TableLocations from '../VibeTable/Tables/TableLocations';
import TableCompanies from '../VibeTable/Tables/TableCompanies';
import VibeIcon from '../VibeIcon/VibeIcon';
import viClose from '../../icons/viClose';
import viViewDayTrack from '../../icons/viViewDayTrack';
import viCalendar from '../../icons/viCalendar';
import viMicrophone from '../../icons/viMicrophone';
import viMicrophoneList from '../../icons/viMicrophoneList';
import viPlaylist from '../../icons/viPlaylist';
import viStation from '../../icons/viStation';
// import viUser from '../../icons/viUser';
import color from '../../sass/color.scss';
import './LocationsUnassigned.scss';

class LocationsUnassigned extends PureComponent {
  constructor(props) {
    super(props);

    const {
      user,
      type,
      companyId,
      locations,
    } = props;

    // only highlight company IDs with all locations
    const locationItems = type === 'locations'
      ? locations
      : locations.filter(location => location.locationId === '*');

    // only show the objects the user has access to
    const assignObjectItems = [];

    if (user.can('baseline.view')) {
      assignObjectItems.push({
        name: 'Baseline',
        value: 'baseline',
        icon: viViewDayTrack,
      });
    }

    if (user.can('event.view')) {
      assignObjectItems.push({
        name: 'Event',
        value: 'event',
        icon: viCalendar,
      });
    }

    if (user.can('message.view') || user.can('advertisement.view')) {
      assignObjectItems.push({
        name: 'Message',
        value: 'message',
        icon: viMicrophone,
      });
    }

    if (user.can('messagelist.view')) {
      assignObjectItems.push({
        name: 'Message Block',
        value: 'message block',
        icon: viMicrophoneList,
      });
    }

    if (user.can('playlist.view')) {
      assignObjectItems.push({
        name: 'Playlist',
        value: 'playlist',
        icon: viPlaylist,
      });
    }

    if (user.can('mix.view')) {
      assignObjectItems.push({
        name: 'Station',
        value: 'station',
        icon: viStation,
      });
    }

    // if (user.can('user.view')) {
    //   assignObjectItems.push({
    //     name: 'User',
    //     value: 'user',
    //     icon: viUser,
    //   });
    // }

    const locationFuncType = user.getLocationFunc(companyId);

    this.state = {
      highlightIds: type === 'locations'
        ? locationItems.map(location => location.locationId)
        : locationItems.map(location => location.companyId),
      locationFuncType,
      assignObjectType: 'locations',
      assignObjectItems,
    };
  }

  /**
   * Get Locations Function by User Type
   */
  getLocationsFunc = () => {
    const {
      user,
      partnerId,
    } = this.props;

    if (partnerId && user.accountType !== 'partner') {
      return API.Partner.getLocations;
    }

    if (user.accountType === 'partner') {
      return API.PartnerUser.getLocations;
    }

    return API.User.getLocations;
  };

  /**
   * Get Companies Function by User Type
   */
  getCompaniesFunc = () => {
    const {
      user,
      partnerId,
    } = this.props;

    if (partnerId && user.accountType !== 'partner') {
      return API.Partner.getCompanies;
    }

    if (user.accountType === 'partner') {
      return API.PartnerUser.getCompanies;
    }

    return API.User.getCompanies;
  };

  /**
   * When the assign object is changed
   */
  onChangeAssignObject = (name) => {
    if (this._scrollRef) {
      this._scrollRef.scrollTop = 0;
    }

    this.setState({
      assignObjectType: name,
    });
  };

  /**
   * Assign an objects locations to this object
   */
  onAssignObjectLocations = ({
    locations,
    locationsData,
  }) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate({
      locations,
      locationsData,
    });

    this.onCloseAvailable();
  };

  onAddLocation = (locationItems) => {
    const {
      onAddLocation,
    } = this.props;

    if (!isArray(locationItems)) {
      locationItems = [locationItems];
    }

    const newLocationItems = locationItems.map(item => new Location(item));

    this.setState((state) => {
      const locationIds = locationItems.map(location => location._id);

      return {
        highlightIds: [...state.highlightIds, ...locationIds],
      };
    });

    onAddLocation(newLocationItems);
    document.dispatchEvent(new Event('onUpdateTableAssignedLocations'));
  };

  onAddCompany = (companyItems) => {
    const {
      onAddCompany,
    } = this.props;

    if (!isArray(companyItems)) {
      companyItems = [companyItems];
    }

    const newCompanyItems = companyItems.map(item => new Company(item));

    this.setState((state) => {
      const companyIds = companyItems.map(company => company._id);

      return {
        highlightIds: [...state.highlightIds, ...companyIds],
      };
    });

    onAddCompany(newCompanyItems);
    document.dispatchEvent(new Event('onUpdateTableAssignedCompanies'));
  };

  onRemoveLocation = (locationItems) => {
    const {
      onRemoveLocation,
    } = this.props;

    if (!isArray(locationItems)) {
      locationItems = [locationItems];
    }

    const locationIds = locationItems.map(location => location._id);

    this.setState((state) => {
      return {
        highlightIds: state.highlightIds.filter(id => !locationIds.includes(id)),
      };
    });

    onRemoveLocation(locationItems);
  };

  onRemoveCompany = (companyItems) => {
    const {
      onRemoveCompany,
    } = this.props;

    if (!isArray(companyItems)) {
      companyItems = [companyItems];
    }

    const companyIds = companyItems.map(company => company._id);

    this.setState((state) => {
      return {
        highlightIds: state.highlightIds.filter(id => !companyIds.includes(id)),
      };
    });

    onRemoveCompany(companyItems);
  };

  /**
   * Close the third panel on the sidebar
   */
  onCloseAvailable = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      thirdPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        thirdPanel: {
          children: null,
        },
      });
    }, 900);
  };

  render() {
    const {
      user,
      type,
      companyId,
      partnerId,
      scopeLimit,
      fetchUnassignedLocations,
      fetchUnassignedCompanies,
      filtersUnassigned,
    } = this.props;

    const {
      highlightIds,
      assignObjectType,
      assignObjectItems,
      locationFuncType,
    } = this.state;

    const filters = locationFuncType === 'company'
      ? {
        companyId,
        ...filtersUnassigned,
      }
      : filtersUnassigned;

    if (scopeLimit.length > 0) {
      filters.scopeLimit = group(scopeLimit);
    }

    if (partnerId) {
      filters.partnerId = partnerId;
    }

    const highlight = highlightIds.map((_id) => {
      return {
        _id,
      };
    });

    return (
      <div className="LocationsUnassigned">
        <div className="title-container">
          <div className="title">
            Assign {type === 'locations' ? 'Locations' : 'Companies'}
          </div>

          {assignObjectItems.length > 0 ? (
            <div className="assign-object">
              <Dropdown
                title="Assign by Object..."
                width={156}
                themeColor={color.aquaForest}
                defaultValue="locations"
                items={assignObjectItems}
                // disabled={!allowChanges}
                onChange={this.onChangeAssignObject}
              />
            </div>
          ) : null}

          <VibeIcon
            className="close"
            icon={viClose}
            color={color.white}
            hoverColor={color.lightGray}
            size={24}
            onClick={this.onCloseAvailable}
          />
        </div>

        {assignObjectType === 'locations' ? (
          <div className="locations-content">
            {type === 'locations' ? (
              <TableLocations
                columns={[
                  'Add/Remove',
                  'Name',
                  'Company',
                  'Media Format',
                  'Address 1',
                  'Address 2',
                  'Country',
                  'City',
                  'State/Province',
                  'Postal Code',
                  'DMA',
                  'Ad Network',
                  'Ad Programs',
                  'Tags',
                  '...',
                ]}
                fetch={fetchUnassignedLocations ?? this.getLocationsFunc()}
                fetchProps={{
                  filters: {
                    _id: user._id,
                  },
                }}
                filters={filters}
                defaultSortBy={{
                  label: 'Name',
                  attr: 'name',
                  direction: 'asc',
                }}
                paginator
                paginatorProps={{
                  label: 'Locations',
                  urlPaging: false,
                  urlFilters: false,
                }}
                menuItems={[
                  { name: 'Archive', userCan: 'location.delete' },
                ]}
                available
                highlight={highlight}
                onAdd={this.onAddLocation}
                onRemove={this.onRemoveLocation}
              />
            ) : (
              <TableCompanies
                columns={[
                  'Add/Remove',
                  'Company',
                  'Category',
                  'Address 1',
                  'Address 2',
                  'City',
                  'State',
                  'Zip',
                  'Salesforce ID',
                  'Tags',
                  '...',
                ]}
                fetch={fetchUnassignedCompanies ?? this.getCompaniesFunc()}
                fetchProps={{
                  filters: {
                    _id: user._id,
                  },
                }}
                filters={filters}
                defaultSortBy={{
                  label: 'Company',
                  attr: 'name',
                  direction: 'asc',
                }}
                paginator
                paginatorProps={{
                  label: 'Companies',
                  urlPaging: false,
                  urlFilters: false,
                }}
                menuItems={[
                  { name: 'Archive', userCan: 'company.delete' },
                ]}
                available
                highlight={highlight}
                onAdd={this.onAddCompany}
                onRemove={this.onRemoveCompany}
              />
            )}
          </div>
        ) : (
          <AssignObject
            className="object-content"
            companyId={companyId}
            type={assignObjectType}
            onAssignObjectLocations={this.onAssignObjectLocations}
          />
        )}
      </div>
    );
  }
}

LocationsUnassigned.propTypes = {
  /** Show locations or companies */
  type: PropTypes.oneOf([
    'locations',
    'companies',
  ]).isRequired,
  /** Company ID */
  companyId: PropTypes.string.isRequired,
  partnerId: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.object),
  /** Locations to use as a limit when querying the API for available locations */
  scopeLimit: PropTypes.arrayOf(PropTypes.object),
  /** Custom API endpoint to fetch locations */
  fetchUnassignedLocations: PropTypes.func,
  /** Custom API endpoint to fetch companies */
  fetchUnassignedCompanies: PropTypes.func,
  /** Custom filters for the API endpoint on unasssigned locations */
  filtersUnassigned: PropTypes.object,
  /** When an item is added */
  onAddLocation: PropTypes.func,
  onAddCompany: PropTypes.func,
  /** When an item is removed */
  onRemoveLocation: PropTypes.func,
  onRemoveCompany: PropTypes.func,
  /** Update the object data */
  onUpdate: PropTypes.func,
};

LocationsUnassigned.defaultProps = {
  partnerId: '',
  locations: [],
  scopeLimit: [],
  fetchUnassignedLocations: null,
  fetchUnassignedCompanies: null,
  filtersUnassigned: {},
  onAddLocation: () => {},
  onAddCompany: () => {},
  onRemoveLocation: () => {},
  onRemoveCompany: () => {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsUnassigned);
