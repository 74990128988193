import omit from 'lodash/omit';
import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/orders`;

export default {
  list: (data, options) => Http.post(`${baseUrl}/search`, data, options),
  get: (id, options = {}) => Http.get(`${baseUrl}/${id}`, options),
  summary: (id, options) => Http.get(`${baseUrl}/${id}/summary`, options),
  history: (data, options) => Http.post(`${baseUrl}/${data._id}/history`, data, options),
  pause: (data, options) => Http.post(`${baseUrl}/${data._id}/pause`, data, options),
  resume: (data, options) => Http.post(`${baseUrl}/${data._id}/resume`, data, options),
  copy: (data, options) => Http.post(`${baseUrl}/${data._id}/copy`, data, options),
  cancel: (data, options) => Http.post(`${baseUrl}/${data._id}/cancel`, data, options),
  dailyStatistics: (data, options) => Http.post(`${baseUrl}/${data._id}/dailystatistics`, data, options),

  // Order Segments
  Segment: {
    list: (data, options) => Http.post(`${baseUrl}/${data._id}/segments/search`, data, options),
    get: (data, options) => {
      options = addVersion('7.22.0', options);
      return Http.get(`${baseUrl}/${data._id}/segments/${data.segmentId}`, options);
    },
    getExpandedLocations: (data, options) => {
      return Http.get(`${baseUrl}/${data._id}/segments/${data.segmentId}/locations/expanded`, options);
    },
    history: (data, options) => Http.post(`${baseUrl}/${data._id}/segments/${data.segmentId}/history`, data, options),
    addNote: (data, options) => {
      const postData = omit(data, ['_id', 'segmentId']);
      return Http.post(`${baseUrl}/${data._id}/segments/${data.segmentId}/notes`, postData, options);
    },
    removeNote: (data, options) => {
      return Http.delete(
        `${baseUrl}/${data._id}/segments/${data.segmentId}/notes/${data.segmentNoteId}`,
        data,
        options,
      );
    },
    updateDayParts: (data, options) => {
      const postData = omit(data, '_id', 'segmentId');
      return Http.post(`${baseUrl}/${data._id}/segments/${data.segmentId}/updatecreatives`, postData, options);
    },
  },

  // Order Reporting
  Reporting: {
    getStatusSummaries: (data, options) => Http.post(`${baseUrl}/reporting/overview/status/summary`, data, options),

    // Paged List Views
    Lists: {
      Overview: {
        underPacing: (data, options) => Http.post(`${baseUrl}/reporting/overview/underpacing`, data, options),
        ending: (data, options) => Http.post(`${baseUrl}/reporting/overview/ending`, data, options),
        missingCreative: (data, options) => Http.post(`${baseUrl}/reporting/overview/missingcreatives`, data, options),
      },

      Live: {
        serving: (data, options) => Http.post(`${baseUrl}/reporting/live/serving`, data, options),
        ending: (data, options) => Http.post(`${baseUrl}/reporting/live/ending`, data, options),
      },

      Pending: {
        upcoming: (data, options) => Http.post(`${baseUrl}/reporting/pending/upcoming`, data, options),
      },

      Paused: {
        current: (data, options) => Http.post(`${baseUrl}/reporting/paused/current`, data, options),
        ending: (data, options) => Http.post(`${baseUrl}/reporting/paused/ending`, data, options),
      },

      Completed: {
        past: (data, options) => Http.post(`${baseUrl}/reporting/completed/past`, data, options),
      },

      Cancelled: {
        past: (data, options) => Http.post(`${baseUrl}/reporting/cancelled/past`, data, options),
      },
    },

    // Summary Views
    Summaries: {
      Overview: {
        underPacing: (data, options) => Http.post(`${baseUrl}/reporting/overview/underpacing/summary`, data, options),
        ending: (data, options) => Http.post(`${baseUrl}/reporting/overview/ending/summary`, data, options),
        missingCreative: (data, options) => {
          return Http.post(`${baseUrl}/reporting/overview/missingcreatives/summary`, data, options);
        },
      },

      Live: {
        serving: (data, options) => Http.post(`${baseUrl}/reporting/live/serving/summary`, data, options),
        ending: (data, options) => Http.post(`${baseUrl}/reporting/live/ending/summary`, data, options),
      },

      Pending: {
        upcoming: (data, options) => Http.post(`${baseUrl}/reporting/pending/upcoming/summary`, data, options),
      },

      Paused: {
        current: (data, options) => Http.post(`${baseUrl}/reporting/paused/current/summary`, data, options),
        ending: (data, options) => Http.post(`${baseUrl}/reporting/paused/ending/summary`, data, options),
      },

      Completed: {
        past: (data, options) => Http.post(`${baseUrl}/reporting/completed/past/summary`, data, options),
      },

      Cancelled: {
        past: (data, options) => Http.post(`${baseUrl}/reporting/cancelled/past/summary`, data, options),
      },
    },
  },
};
