import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  GlobalActions,
  CompanySelection,
  VibeButtonNew,
  VibeIcon,
  viClose,
  color,
} from 'vibeguide';

function MessageCompanies({
  allowChanges,
  companies: propCompanies,
  setPanel,
  onUpdate,
}) {
  const [companies, setCompanies] = useState(propCompanies);

  const isACAF = companies.length === 1 && companies[0]._id === '*';

  /**
   * When the companies are changed
   */
  const onChange = (companies) => {
    setCompanies(companies);

    onUpdate({
      locations: companies.map(company => {
        return {
          companyId: company._id,
          locationId: '*',
        };
      }),
      locationsData: {
        companies: companies.map(company => {
          return {
            ...company,
            locations: [],
            locationCount: '*',
          };
        }),
        locations: [],
      },
    });
  };

  /**
   * Close the third panel on the sidebar
   */
  const onCloseAvailable = () => {
    setPanel({
      thirdPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        thirdPanel: {
          children: null,
        },
      });
    }, 900);
  };

  const onClickAssign = () => {
    setPanel({
      thirdPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <CompanySelection
            selected={companies}
            customToolbar={(
              <VibeIcon
                icon={viClose}
                color={color.white}
                hoverColor={color.white}
                size={24}
                onClick={onCloseAvailable}
              />
            )}
            assigning
            allowChanges
            onChange={onChange}
          />
        ),
      },
    });
  };

  const onClose = () => {
    setPanel({
      extraPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        extraPanel: {
          children: null,
        },
      });
    }, 900);
  };

  return (
    <CompanySelection
      selected={companies}
      customToolbar={(
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          {allowChanges && !isACAF && (
            <VibeButtonNew
              text="Assign Companies"
              color={color.aquaForest}
              variant="outlined"
              onClick={onClickAssign}
            />
          )}

          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        </div>
      )}
      allowChanges={allowChanges}
      allowACAF={false}
      onChange={onChange}
    />
  );
}

MessageCompanies.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object),
  /** Allow changes to the selection */
  allowChanges: PropTypes.bool,
  onUpdate: PropTypes.func,
};

MessageCompanies.defaultProps = {
  companies: [],
  allowChanges: false,
  onUpdate: () => {},
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(null, mapDispatchToProps)(MessageCompanies);
