import {
  each,
  isUndefined,
  isArray,
  find,
  sortBy,
} from 'lodash';

const {
  ADMIN_COMPANY_ID: adminCompanyId,
  NODE_ENV: nodeEnv,
} = process.env;

class User {
  constructor(data = {}) {
    this._id = data._id || null;
    this.active = data.active || false;
    this.accountType = data.accountType || null;
    this.allLocations = data.allLocations || false;
    this.aud = data.aud || null;
    this.auth_time = data.auth_time || null;
    this.companyId = data.companyId || null;
    this.companyName = data.companyName || '';
    this.createdBy = data.createdBy || null;
    this.createdDate = data.createdDate || null;
    this.email = data.email || null;
    this.email_verified = data.email_verified || false;
    this.event_id = data.event_id || null;
    this.exp = data.exp || 0;
    this.family_name = data.family_name || null;
    this.fname = data.fname || null;
    this.iat = data.iat || 0;
    this.imageUrl = data.imageUrl || null;
    this.imageFile = data.imageFile || null;
    this.iss = data.iss || null;
    this.lastLoginDate = data.lastLoginDate || null;
    this.lname = data.lname || null;
    this.locationsAllowed = data.locationsAllowed || [];
    this.modifiedBy = data.modifiedBy || null;
    this.modifiedDate = data.modifiedDate || null;
    this.name = data.name || null;
    this.notifications = data.notifications || {
      email: false,
      sms: false,
    };
    this.partnerId = data.partnerId || null;
    this.partnerName = data.partnerName || null;
    // this.permissions = data.permissions || '';
    this.phone = data.phone || null;
    this.role = data.role || '';
    this.sub = data.sub || null;
    // this.sysAdmin = this.can('admin.system_admin');
    this.tags = data.tags || [];
    this.title = data.title || null;
    this.token_use = data.token_use || null;
    this.sysIntegrator = data.sysIntegrator || null;

    const permissions = data.permissions || [];
    const sortedPermissions = sortBy(permissions, ['name']);

    // Set user permissions
    this.permissions = sortedPermissions;

    // Check if user is a system admin
    this.sysAdmin = this.can('admin.system_admin');

    // Check if user is part of the admin company
    this.isAdminCompany = this.companyId === adminCompanyId;

    // Check if user can assign all current and future locations
    this.allowAllCurrentAndFuture = this.canAllCurrentAndFuture();

    // Check if the user is the voice talent
    this.voiceTalent = this.permissions.length <= 3
      && (this.can('voice.talent') || this.can('voice.talent_administrator'));

    // TODO: Switch to camelCase
    this['cognito:username'] = data['cognito:username'] || null;
    this['custom:vibenomics_id'] = data['custom:vibenomics_id'] || null;

    // Get users browser locale
    this.locale = navigator.language;

    each(data, (val, key) => {
      // Convert the key to camelcase
      // const camelKey = camelCase(key);

      if (isUndefined(this[key])) {
        // Add the property to the model
        this[key] = val;

        console.warn(`${key} is not added to User Model`);
      }
    });

    if (nodeEnv === 'development' && this._id) {
      // Log user data for authenticated users in dev environments
      console.log('User Data', this);
    }
  }

  /**
   * Check if the User has all the permissions being checked
   *
   * @param {string|array} permissions
   */
  can(permissions) {
    let userCan = true;

    if (isArray(permissions)) {
      // Multiple permissions passed
      permissions.forEach(permission => {
        const hasPermission = find(this.permissions, { name: permission });

        if (!hasPermission) {
          userCan = false;
        }
      });
    } else {
      // Single permission passed
      const hasPermission = find(this.permissions, { name: permissions });

      if (!hasPermission) {
        userCan = false;
      }
    }

    return userCan;
  }

  /**
   * Check if the User has any of the permissions being checked.
   * Returns true if they have any of the permissions being passed
   *
   * @param {array} permissions
   */
  hasAnyOf(permissions) {
    let userCan = false;

    permissions.forEach(permission => {
      const hasPermission = find(this.permissions, { name: permission });

      if (hasPermission) {
        userCan = true;
      }
    });

    return userCan;
  }

  /**
   * Check if the user has access to a company
   * this is used when they can modift objects, but not Vibenomics owned objects
   *
   * @param {string} companyId
   */
  hasAccessToCompany(companyId) {
    if (!companyId) {
      // no company ID to check for
      return true;
    }

    const hasCompany = find(this.locationsAllowed, { companyId: '*' }) !== undefined
      || find(this.locationsAllowed, { companyId }) !== undefined;

    return hasCompany;
  }

  /**
   * Check if the user can assign all current and future
   *
   */
  canAllCurrentAndFuture() {
    const {
      locationsAllowed: locations,
    } = this;

    let allowAllCurrentAndFuture = false;

    for (let i = 0; i < locations.length; i++) {
      const location = locations[i];
      const {
        companyId,
        locationId,
      } = location;

      if (companyId === '*') {
        // User is assigned every location from every company
        allowAllCurrentAndFuture = true;
        break;
      } else if (locationId === '*') {
        // User is assigned every location from a specific company
        allowAllCurrentAndFuture = true;
        break;
      }
    }

    return allowAllCurrentAndFuture;
  }

  /**
   * Check if the object being modified is using all locations based on
   * the assigned company, the object locations, and if the user is an admin
   *
   * @param {Object} data
   * @param {array} data.locations - Object locations
   * @param {boolean} data.companyId - Company ID the object is assigned
   * @param {boolean} data.adminCompanyOnly - Only allow ACAF selection under the admin company
   */
  isAllLocations({
    locations,
    companyId,
    adminCompanyOnly = false,
  }) {
    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    let allLocations;

    if (companyId === adminCompanyId && !adminCompanyOnly) {
      // Object is assigned to Vibenomics. (and not set to adminCompanyOnly preventing ALL companies/locations)
      allLocations = this.sysAdmin
        ? find(locations, { companyId: '*' }) !== undefined
        : find(locations, { companyId: this.companyId, locationId: '*' }) !== undefined;
    } else {
      // Object is assigned to a company that is not Vibenomics.
      allLocations = find(locations, { companyId, locationId: '*' }) !== undefined;
    }

    return allLocations;
  }

  /**
   * Get the location spec for the user for an All Current and Future (ACAF) object
   * For adminCompanyOnly refer to the UserLocations for a System Integration Account
   * @param {string} companyId
   * @param {bool} adminCompanyOnly Only allow ACAF for the admin company (do not allow { companyId: '*' })
   *
   */
  getAllCurrentAndFutureSpec(companyId, adminCompanyOnly = false) {
    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    if (this.sysAdmin && companyId === adminCompanyId && !adminCompanyOnly) {
      // Admin user with an object assigned to the admin company. ACAF spec is all companies/locations
      // adminOnlyCompany is not true, allowing ALL companies/locations { companyId: '*' }
      return [{
        companyId: '*',
      }];
    }

    // Not an admin user with object assigned to the admin company or
    // adminOnlyCompany is true, preventing ALL companies/locations { companyId: 'ADMIN_COMPANY_ID', locationId: '*' }
    return [{
      companyId,
      locationId: '*',
    }];
  }

  /**
   * Get the location string for searching
   * i.e. "locations:loc(companyId,locationId)"
   */
  getLocationString() {
    const locations = this.locationsAllowed.map((location) => {
      if (location.companyId === '*') {
        return '*';
      }

      return `${location.companyId},${location.locationId}`;
    }).join('|');

    return locations;
  }

  /**
   * Get locations for an object being created or modified
   *
   * @param {Object} data
   * @param {array} data.locations - Locations selected
   * @param {boolean} data.allLocations - All current and future selected
   * @param {string} data.companyId - Company ID the object is assigned
   * @param {boolean} data.isOtherAdmin - Overwrite if the user being checked against is an admin (used for new users)
   */
  getLocations({
    locations,
    allLocations,
    companyId,
    isOtherAdmin,
  }) {
    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    if (isUndefined(isOtherAdmin)) {
      // Do not compare this user with the other user admin
      isOtherAdmin = this.sysAdmin;
    }

    if (!companyId) {
      // Company ID was not sent. Use this users company
      // eslint-disable-next-line prefer-destructuring
      companyId = this.companyId;
    }

    // Determine all locations across all companies or all locations with one company
    let allLocationsObj;

    if (
      adminCompanyId === companyId
      && allLocations
      && isOtherAdmin
      && this.sysAdmin
    ) {
      // Admin creating for all locations at all companies
      allLocationsObj = [{
        companyId: '*',
      }];
    } else if (
      adminCompanyId !== companyId
      && allLocations
      && isOtherAdmin
      && this.sysAdmin
    ) {
      // Admin creating for all locations at one company
      allLocationsObj = [{
        companyId,
        locationId: '*',
      }];
    } else if (
      allLocations
    ) {
      // User creating for all locations at one company
      allLocationsObj = [{
        companyId,
        locationId: '*',
      }];
    }

    locations.forEach((location) => {
      if (location.company) {
        delete location.company;
      }

      if (location.location) {
        delete location.location;
      }
    });

    const locationData = allLocations
      ? allLocationsObj
      : locations;

    return locationData;
  }

  /**
   * Get location function to fetch user locations or company locations
   *
   * @param {string} companyId - Company ID to compare against
   */
  getLocationFunc(companyId) {
    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    let locationFunc;

    if (
      (this.sysAdmin && companyId === adminCompanyId)
      || !this.sysAdmin
    ) {
      locationFunc = 'user';
      // locationFunc = API.User.getLocations;
    } else if (this.sysAdmin && companyId !== adminCompanyId) {
      locationFunc = 'company';
      // locationFunc = API.Company.getLocations;
    }

    return locationFunc;
  }

  /**
   * Get the Root URL for the user
   */
  getRootUrl() {
    // user cannot view locations
    if (!this.can('location.view')) {
      // send to messages
      if (this.can('message.view') || this.can('advertisement.view') || this.can('voice.talent')) {
        return '/messages';
      }

      // partner user, send to reporting
      if (this.accountType === 'partner' && this.can('partner.view_report')) {
        return '/reporting';
      }
    }

    const locations = this.locationsAllowed;
    const singleLocation = this.locationsAllowed.length === 1
      && locations[0].companyId !== '*'
      && locations[0].locationId !== '*';

    return singleLocation
      // user has access to one specific location, send them to the schedule view
      ? `/location/${locations[0].locationId}/schedule`
      // user has access to multiple locations
      : '/locations';
  }

  /**
   * Check if the users locations exceeds the passed in locations
   *
   * @param {array} locations - Array of location objects to compare against
   */
  exceedsLocations(locations = []) {
    const userLocations = this.locationsAllowed;
    const hasAllLocations = find(userLocations, { companyId: '*' }) !== undefined;
    // const numCompanies = (locations.filter(location => location.locationId === '*') || []).length;

    if (hasAllLocations) {
      // Admin or Partner user with all locations automatically exceeds any locations
      return true;
    }

    // if (!this.sysAdmin && numCompanies > 1) {
    //   // Not an admin and multiple companies selected
    //   return false;
    // }

    // Check if the user has all the locations
    let userHasAllLocations = true;

    for (let i = 0; i < locations.length; i++) {
      const {
        companyId,
        locationId,
      } = locations[i];

      const userHasLocation = find(userLocations, { companyId, locationId }) !== undefined;

      if (!userHasLocation) {
        // User does not have this passed in location
        // Check if the user has access to all locations for that company
        const userHasAllLocationsForCompany = find(userLocations, { companyId, locationId: '*' }) !== undefined;

        if (!userHasAllLocationsForCompany) {
          // User does not have access to all locations for the company
          userHasAllLocations = false;
          break;
        }
      }
    }

    if (!userHasAllLocations) {
      // User does not have all the passed in locations
      return false;
    }

    // Default to true in case no locations are passed in
    return true;
  }
}

export default User;
