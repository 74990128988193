/**
 * List of supported Programmatic Buyers
 * @param options object of options to build the list
 * @param options.includeEmpty boolean to include an empty name/value pair at the beginning
 */
export function list(options = {}) {
  const list = [
    {
      label: 'Trade Desk',
      value: 'tradeDesk',
    },
    {
      label: 'Yahoo',
      value: 'yahoo',
    },
  ];

  if (options.includeEmpty === true) {
    list.unshift({
      label: '',
      value: '',
    });
  }

  return list;
}

export default {
  list,
};
