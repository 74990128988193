import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  API,
  MediaFormatHelper,
  Field2 as Field,
} from 'vibeguide';
import { Grid } from '@mui/material';

class Settings extends PureComponent {
  /**
   * When a field is changed
   */
  onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const {
      onUpdate,
    } = this.props;

    onUpdate({
      [name]: value,
    });
  };

  onChangeAdProgram = () => {
    const {
      adProgramEnabled,
      onUpdate,
    } = this.props;

    onUpdate({
      adProgramEnabled: !adProgramEnabled,
    });
  };

  onDropdownChange = (e) => {
    const {
      onUpdate,
    } = this.props;

    const {
      name,
      value,
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  onChangeAdNetwork = () => {
    const {
      adNetworkEnabled,
      onUpdate,
    } = this.props;

    onUpdate({
      adNetworkEnabled: !adNetworkEnabled,
    });
  };

  titleCase = (string) => {
    return string
      ? string.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
      : string;
  };

  render() {
    const {
      user,
      contentRating,
      mediaFormat,
      adNetworkOwner,
      adDeliverySystem,
      contentNetworkOwner,
      contentDeliverySystem,
      adProgramEnabled,
      adNetworkEnabled,
      companyAdProgramEnabled,
      companyAdNetworkEnabled,
      disableInput,
      isNew,
      canEdit,
    } = this.props;

    const canAdminEdit = user.sysAdmin && !disableInput;
    const canEnableAdPrograms = user.can('location-ad-program.modify');

    return (
      <div className="Settings location">
        <div className="details-title">
          Ad Information
        </div>

        <Grid spacing={2} container>
          <Grid xs={12} lg={6} item>
            <Field
              type="toggle"
              label="Ad Network"
              toggleProps={{
                label: 'Enable Ad Network',
                helpText: !companyAdNetworkEnabled
                  ? 'Company ad network is disabled'
                  : null,
              }}
              value={adNetworkEnabled
                ? 'on'
                : 'off'}
              tabIndex={13}
              disabled={!canAdminEdit || !companyAdNetworkEnabled || adProgramEnabled}
              onChange={this.onChangeAdNetwork}
            />
          </Grid>

          {canEnableAdPrograms && (
            <Grid xs={12} lg={6} item>
              <Field
                type="toggle"
                label="Enable Ad Program"
                toggleProps={{
                  label: 'Enable Ad Program',
                  helpText: !companyAdProgramEnabled
                    ? 'Company ad program is disabled'
                    : null,
                }}
                value={adProgramEnabled
                  ? 'on'
                  : 'off'}
                tabIndex={13}
                disabled={!canAdminEdit || !companyAdProgramEnabled || !adNetworkEnabled}
                onChange={this.onChangeAdProgram}
              />
            </Grid>
          )}

          <Grid xs={12} lg={6} item>
            <Field
              type="dropdown"
              label="Content Network Owner"
              name="contentNetworkOwner"
              value={this.titleCase(contentNetworkOwner)}
              tabIndex={10}
              dropdownProps={{
                type: 'network owner',
                attr: 'value',
                fetch: API.LookupList.contentNetworkOwners.search,
              }}
              disabled={!canEdit}
              required
              onChange={this.onDropdownChange}
            />
          </Grid>

          <Grid xs={12} lg={6} item>
            <Field
              type="dropdown"
              label="Content Delivery System"
              name="contentDeliverySystem"
              value={this.titleCase(contentDeliverySystem)}
              tabIndex={11}
              dropdownProps={{
                type: 'delivery system',
                attr: 'value',
                fetch: API.LookupList.contentDeliverySystems.search,
              }}
              disabled={!canEdit}
              required
              onChange={this.onDropdownChange}
            />
          </Grid>

          {adNetworkEnabled && (
            <>
              <Grid xs={12} lg={6} item>
                <Field
                  type="dropdown"
                  label="Ad Delivery System"
                  name="adDeliverySystem"
                  value={this.titleCase(adDeliverySystem)}
                  tabIndex={12}
                  dropdownProps={{
                    type: 'delivery system',
                    attr: 'value',
                    fetch: API.LookupList.adDeliverySystems.search,
                  }}
                  disabled={!canEdit}
                  required={adNetworkEnabled}
                  onChange={this.onDropdownChange}
                />
              </Grid>
              <Grid xs={12} lg={6} item>
                <Field
                  type="dropdown"
                  label="Ad Network Owner"
                  name="adNetworkOwner"
                  value={this.titleCase(adNetworkOwner)}
                  tabIndex={13}
                  dropdownProps={{
                    type: 'network owner',
                    attr: 'value',
                    fetch: API.LookupList.adNetworkOwners.search,
                  }}
                  disabled={!canEdit}
                  required={adNetworkEnabled}
                  onChange={this.onDropdownChange}
                />
              </Grid>
            </>
          )}

          <Grid xs={12} lg={6} item>
            <Field
              type="select"
              label="Max Allowed Content Rating"
              name="contentRating"
              value={contentRating}
              options={[
                {
                  label: '',
                  value: '',
                },
                {
                  label: 'Radio Edit',
                  value: 'radio-edit',
                },
                {
                  label: 'G',
                  value: 'g',
                },
                {
                  label: 'Explicit',
                  value: 'explicit',
                },
              ]}
              tabIndex={12}
              disabled={disableInput}
              required
              onChange={this.onChange}
            />
          </Grid>

          <Grid xs={12} lg={6} item>
            <Field
              type="select"
              label="Media Format"
              name="mediaFormat"
              value={mediaFormat}
              options={MediaFormatHelper.list({ includeEmpty: true })}
              disabled={!isNew || !canAdminEdit}
              tabIndex={15}
              onChange={this.onChange}
              required={isNew}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Settings.propTypes = {
  contentRating: PropTypes.string,
  mediaFormat: PropTypes.string,
  adProgramEnabled: PropTypes.bool,
  companyAdProgramEnabled: PropTypes.bool,
  adNetworkEnabled: PropTypes.bool,
  companyAdNetworkEnabled: PropTypes.bool,
  disableInput: PropTypes.bool,
  isNew: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Settings.defaultProps = {
  contentRating: '',
  mediaFormat: '',
  adProgramEnabled: false,
  companyAdProgramEnabled: false,
  adNetworkEnabled: false,
  companyAdNetworkEnabled: false,
  disableInput: false,
  isNew: false,
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Settings);
