import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  clone,
  pullAt,
  findLastIndex,
  isArray,
} from 'lodash';
import API from '../../api';
import TableCompanies from '../VibeTable/Tables/TableCompanies';
import './CompaniesUnassigned.scss';

class CompaniesUnassigned extends PureComponent {
  constructor(props) {
    super(props);

    const {
      selected,
    } = props;

    this.state = {
      selected,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      selected,
    } = this.props;

    const {
      selected: prevSelected,
    } = prevProps;

    if (JSON.stringify(selected) !== JSON.stringify(prevSelected)) {
      this.setState({
        selected,
      });
    }
  }

  onAdd = (items) => {
    const {
      onChange,
    } = this.props;

    if (!isArray(items)) {
      items = [items];
    }

    const newItems = items.map((item) => {
      const newItem = clone(item);

      // remove the row ID to give it a unique identifier
      delete newItem._rowId;
      return newItem;
    });

    this.setState((state) => {
      const items = [
        ...state.selected,
        ...newItems,
      ];

      onChange(items);

      return {
        selected: items,
      };
    });
  };

  onRemove = (item) => {
    const {
      onChange,
    } = this.props;

    this.setState((state) => {
      const {
        selected,
      } = state;

      const lastIndex = findLastIndex(selected, { _id: item._id });

      // only remove one item in case there are duplicates
      pullAt(selected, lastIndex);
      onChange(selected);

      return {
        selected,
      };
    });

    // needed because the third panel table isn't re-rendered on a prop/state change
    document.dispatchEvent(new Event('onRenderTable'));
    document.dispatchEvent(new Event('onUpdateTableAssignedCompanies'));
  };

  render() {
    const {
      user,
      customToolbar,
      filters,
      onChange,
    } = this.props;

    const {
      selected,
    } = this.state;

    return (
      <div className="CompaniesUnassigned">
        <div className="title-container">
          <div className="title">
            Available Companies
          </div>

          {customToolbar}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <TableCompanies
            columns={[
              'Add/Remove',
              'Company',
              'Category',
              'Address 1',
              'Address 2',
              'Country',
              'City',
              'State/Province',
              'Postal Code',
              'Tags',
              '...',
            ]}
            fetch={user.accountType === 'partner'
              ? API.PartnerUser.getCompanies
              : API.User.getCompanies}
            fetchProps={{
              filters: {
                _id: user._id,
              },
            }}
            filters={filters}
            defaultSortBy={{
              label: 'Company',
              attr: 'name',
              direction: 'asc',
            }}
            paginator
            paginatorProps={{
              label: 'Companies',
              urlPaging: false,
              urlFilters: false,
            }}
            menuItems={[
              { name: 'Archive', userCan: 'company.delete' },
            ]}
            available
            highlight={selected}
            onAdd={this.onAdd}
            onRemove={this.onRemove}
            onChange={onChange}
          />
        </div>
      </div>
    );
  }
}

CompaniesUnassigned.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    messageType: PropTypes.string,
    url: PropTypes.string,
    active: PropTypes.bool,
  })),
  /** Filters for the fetch method */
  filters: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Custom toolbar */
  customToolbar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** When the messages are changed */
  onChange: PropTypes.func,
};

CompaniesUnassigned.defaultProps = {
  filters: null,
  selected: [],
  customToolbar: null,
  onChange: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(CompaniesUnassigned);
