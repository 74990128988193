// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardAlerts {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 80px;
}
.DashboardAlerts .alerts-content {
  flex-grow: 1;
  padding: 30px;
  overflow: auto;
}
.DashboardAlerts .alerts-content .alerts-row {
  display: inline-flex;
  margin-bottom: 24px;
}
.DashboardAlerts .alerts-content .alert-card {
  margin-right: 16px;
}
.DashboardAlerts .alerts-content .alert-card.card-all-alerts {
  border: 1px solid #cf0838;
}

.AlertSidebarConfig {
  letter-spacing: normal;
}
.AlertSidebarConfig .section-title {
  font-size: 18px;
  font-weight: 900;
}
.AlertSidebarConfig .section-content {
  margin: 24px 0;
}
.AlertSidebarConfig .section-content .setting-container {
  margin-top: 16px;
}
.AlertSidebarConfig .section-content .setting {
  display: flex;
  align-items: center;
  user-select: none;
}
.AlertSidebarConfig .section-content .setting .setting-check {
  padding-left: 0;
}
.AlertSidebarConfig .section-content .setting .text {
  flex-grow: 1;
  font-size: 12px;
}
.AlertSidebarConfig .section-content .setting .setting-input {
  width: 66px;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Dashboard/DashboardAlerts.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,iBAAA;AAuFF;AArFE;EACE,YAAA;EACA,aAAA;EACA,cAAA;AAuFJ;AArFI;EACE,oBAAA;EACA,mBAAA;AAuFN;AApFI;EACE,kBAAA;AAsFN;AApFM;EACE,yBAAA;AAsFR;;AAhFA;EACE,sBAAA;AAmFF;AAjFE;EACE,eAAA;EACA,gBAAA;AAmFJ;AAhFE;EACE,cAAA;AAkFJ;AAhFI;EACE,gBAAA;AAkFN;AA/EI;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;AAiFN;AA/EM;EACE,eAAA;AAiFR;AA9EM;EACE,YAAA;EACA,eAAA;AAgFR;AA7EM;EACE,WAAA;AA+ER","sourcesContent":["@import '~vibeguide/sass/color.scss';\n\n.DashboardAlerts {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  margin-left: 80px;\n\n  .alerts-content {\n    flex-grow: 1;\n    padding: 30px;\n    overflow: auto;\n\n    .alerts-row {\n      display: inline-flex;\n      margin-bottom: 24px;\n    }\n\n    .alert-card {\n      margin-right: 16px;\n\n      &.card-all-alerts {\n        border: 1px solid $fireBrick;\n      }\n    }\n  }\n}\n\n.AlertSidebarConfig {\n  letter-spacing: normal;\n\n  .section-title {\n    font-size: 18px;\n    font-weight: 900;\n  }\n\n  .section-content {\n    margin: 24px 0;\n\n    .setting-container {\n      margin-top: 16px;\n    }\n\n    .setting {\n      display: flex;\n      align-items: center;\n      user-select: none;\n\n      .setting-check {\n        padding-left: 0;\n      }\n\n      .text {\n        flex-grow: 1;\n        font-size: 12px;\n      }\n\n      .setting-input {\n        width: 66px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aquaForest": `#00a474`,
	"aquaForest8": `rgba(0, 164, 116, 0.08)`,
	"aquaForest16": `rgba(0, 164, 116, 0.16)`,
	"aquaForest75": `rgba(0, 164, 116, 0.75)`,
	"alertYellow": `#FDB022`,
	"black": `#000000`,
	"boxRed": `#cf0838`,
	"boxPink": `#f57a7a`,
	"boxOrange": `#ff892e`,
	"boxYellow": `#ffd22e`,
	"boxGreen": `#95c653`,
	"boxDarkGreen": `#00a474`,
	"boxBlue": `#33ace1`,
	"boxDarkBlue": `#3361e1`,
	"boxPurple": `#6d6db5`,
	"boxDarkPurple": `#332a4b`,
	"carrot": `#ff892e`,
	"carrot16": `rgba(255, 137, 46, 0.16)`,
	"cerulean": `#33ace1`,
	"cerulean16": `rgba(51, 172, 225, 0.16)`,
	"cosmicVibe": `#332a4b`,
	"cosmicVibe25": `rgba(51, 42, 75, 0.25)`,
	"cosmicVibe50": `rgba(51, 42, 75, 0.5)`,
	"cosmicVibe75": `rgba(51, 42, 75, 0.75)`,
	"crimson": `#c90a31`,
	"darkGray": `#a4a7b7`,
	"darkSlateGray": `#2f2947`,
	"deleteRed": `#BE2A3D`,
	"dimGray": `#545454`,
	"fireBrick": `#cf0838`,
	"fireBrick8": `rgba(207, 8, 56, 0.08)`,
	"fireBrick10": `rgba(207, 8, 56, 0.1)`,
	"fireBrick16": `rgba(207, 8, 56, 0.16)`,
	"fireBrick20": `rgba(207, 8, 56, 0.2)`,
	"fireBrick50": `rgba(207, 8, 56, 0.5)`,
	"fireBrick75": `rgba(207, 8, 56, 0.75)`,
	"fireBrick85": `rgba(207, 8, 56, 0.85)`,
	"flamingo": `#f66733`,
	"flamingo16": `rgba(246, 103, 51, 0.16)`,
	"flamingo75": `rgba(246, 103, 51, 0.75)`,
	"grainsboro": `#d5d8e1`,
	"graphBlue": `#1570EF`,
	"gray": `#757575`,
	"gray50": `#F9FAFB`,
	"gray200": `#EAECF0`,
	"gray300": `#D0D5DD`,
	"gray500": `#667085`,
	"gray700": `#344054`,
	"gray900": `#101828`,
	"lavendarVibe": `#eae9fc`,
	"lightBlue": `#ccdfe8`,
	"lightDimGray": `#6b6b6b`,
	"lighterGray": `#e3e7f4`,
	"lightGray": `#f6f6f6`,
	"lightSlateGray": `#8a8fa3`,
	"lightSmoke": `#f5f4f5`,
	"manatee": `#959aad`,
	"manatee16": `rgba(149, 154, 173, 0.16)`,
	"manatee50": `rgba(149, 154, 173, 0.5)`,
	"mediumGray": `#e0e0e0`,
	"mediumOrchid": `#9932cc`,
	"lightOrchid": `#7F56D9`,
	"obsidian": `#383838`,
	"periwinkle": `#e7e7ff`,
	"rainboots": `#3361e1`,
	"rainboots16": `rgba(51, 97, 225, 0.16)`,
	"royalBlue": `#4085de`,
	"royalBlue16": `rgba(64, 133, 222, 0.16)`,
	"sushi": `#95c653`,
	"teal": `#029a69`,
	"vibeLightPurple": `#e2e1fe`,
	"vibeMediumPurple": `#e7e7ff`,
	"violetVibe": `#6d6db5`,
	"violetVibe16": `rgba(109, 109, 181, 0.16)`,
	"violetVibe40": `rgba(109, 109, 181, 0.4)`,
	"violetVibe50": `rgba(109, 109, 181, 0.5)`,
	"violetVibe60": `rgba(109, 109, 181, 0.6)`,
	"warning50": `#FFFAEB`,
	"warning100": `#FEF0C7`,
	"white": `#ffffff`,
	"whiteSmoke": `#E1E1E9`,
	"yellow": `#ffc107`,
	"yellow16": `rgba(255, 193, 7, 0.16)`,
	"yellow75": `rgba(255, 193, 7, 0.75)`,
	"lemon": `#ffd22e`
};
export default ___CSS_LOADER_EXPORT___;
