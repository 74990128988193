/**
 * List of supported Spot Selection Strategies
 * @param options object of options to build the list
 * @param options.includeEmpty boolean to include an empty name/value pair at the beginning
 */
export function list(options = {}) {
  const list = [
    {
      label: 'Minimize Frequency',
      value: 'minimize-frequency',
    },
    {
      label: 'Maximize Frequency',
      value: 'maximize-frequency',
    },
    {
      label: 'Even Pacing',
      value: 'even-pacing',
    },
    {
      label: 'Spots Per Hour',
      value: 'spots-per-hour',
    },
  ];

  if (options.includeEmpty === true) {
    list.unshift({
      label: '',
      value: '',
    });
  }

  return list;
}

export default {
  list,
};
