/**
 * List of supported Ad Categories
 * @param options object of options to build the list
 * @param options.includeEmpty boolean to include an empty name/value pair at the beginning
 */
export function list(options = {}) {
  const list = [
    { value: 'Adult Incontinence' },
    { value: 'Air Fresheners' },
    { value: 'All Other Breakfast Food' },
    { value: 'All Other Sauces' },
    { value: 'All Other Tobacco Products' },
    { value: 'Anti-Smoking Products' },
    { value: 'Appliances' },
    { value: 'Aseptic Juices' },
    { value: 'Asian Food' },
    { value: 'Automobile Fluids/Antifreeze' },
    { value: 'Baby Accessories' },
    { value: 'Baby Food' },
    { value: 'Baby Formula/Electrolytes' },
    { value: 'Baby Needs' },
    { value: 'Baked Beans/Canned Bread' },
    { value: 'Baked Goods – Refrigerated' },
    { value: 'Bakery Snacks' },
    { value: 'Baking Cups/Paper' },
    { value: 'Baking Mixes' },
    { value: 'Baking Needs' },
    { value: 'Baking Nuts' },
    { value: 'Barbeque Sauce' },
    { value: 'Batteries' },
    { value: 'Beer/Ale/Alcoholic Cider' },
    { value: 'Blades' },
    { value: 'Bleach' },
    { value: 'Bottled Juices' },
    { value: 'Bottled Water' },
    { value: 'Breadcrumbs/Batters' },
    { value: 'Breakfast Meats' },
    { value: 'Butter' },
    { value: 'Candles' },
    { value: 'Canned/Bottled Fruit' },
    { value: 'Canned Juices' },
    { value: 'Carbonated Beverages' },
    { value: 'Cat/Dog Litter' },
    { value: 'Cat Food' },
    { value: 'Charcoal' },
    { value: 'Charcoal Lighter Fluids' },
    { value: 'Cheesecakes' },
    { value: 'Childrens Art Supplies' },
    { value: 'Chocolate Candy' },
    { value: 'Cigarettes' },
    { value: 'Cigars' },
    { value: 'Cleaning Tools/Mops/Brooms' },
    { value: 'Cocktail Mixes' },
    { value: 'Coffee' },
    { value: 'Coffee Creamer' },
    { value: 'Coffee Filters' },
    { value: 'Cold/Allergy/Sinus Liquids' },
    { value: 'Cold/Allergy/Sinus Tablets' },
    { value: 'Cold Cereal' },
    { value: 'Contraceptives' },
    { value: 'Cookies' },
    { value: 'Cosmetics – Eye' },
    { value: 'Cosmetics – Facial' },
    { value: 'Cosmetics – Lip' },
    { value: 'Cosmetics – Nail' },
    { value: 'Cottage Cheese' },
    { value: 'Cotton Balls/Swabs' },
    { value: 'Cough Drops' },
    { value: 'Cough Syrup' },
    { value: 'Crackers' },
    { value: 'Cream Cheese/Cheese Spread' },
    { value: 'Creams/Creamers' },
    { value: 'Croutons' },
    { value: 'Cups & Plates' },
    { value: 'Denture Products' },
    { value: 'Deodorant' },
    { value: 'Desserts -Refrigerated' },
    { value: 'Dessert Toppings' },
    { value: 'Diapers' },
    { value: 'Dinner Sausage' },
    { value: 'Dip/Dip Mixes' },
    { value: 'Dish Detergent' },
    { value: 'Disposable Tableware' },
    { value: 'Dog Food' },
    { value: 'Dough/Biscuit Dough – Refrigerated' },
    { value: 'Dried Fruit' },
    { value: 'Dried Meat Snacks' },
    { value: 'Drink Mixes' },
    { value: 'Dry Beans/Vegetables' },
    { value: 'Dry Fruit Snacks' },
    { value: 'Dry Packaged Dinners' },
    { value: 'Energy Drinks' },
    { value: 'English Muffins' },
    { value: 'Entertainment-Concerts' },
    { value: 'Entertainment Movie' },
    { value: 'Entertainment TV' },
    { value: 'Evaporated/Condensed Milk' },
    { value: 'External Analgesic Rubs' },
    { value: 'Eye/Contact Lens Care Product' },
    { value: 'Fabric Softener Liquid' },
    { value: 'Fabric Softener Sheets' },
    { value: 'Facial Tissue' },
    { value: 'Family Planning' },
    { value: 'Feminine Needs' },
    { value: 'Financial Services' },
    { value: 'Firelog/Firestarter/Firewood' },
    { value: 'First Aid Accessories' },
    { value: 'First Aid Treatment' },
    { value: 'Floor Cleaners/Wax Removers' },
    { value: 'Flour/Meal' },
    { value: 'Foil Pans' },
    { value: 'Foils & Wraps' },
    { value: 'Food & Trash Bags' },
    { value: 'Foot Care Products' },
    { value: 'Fragrances – Women’s' },
    { value: 'Frankfurters' },
    { value: 'Fresh Bread & Rolls' },
    { value: 'Fresh Fruit' },
    { value: 'Fresh Produce' },
    { value: 'Frosting' },
    { value: 'Frozen/Refrigerated Poultry' },
    { value: 'Frozen Appetizers/Snack Rolls' },
    { value: 'Frozen Bread/Frozen Dough' },
    { value: 'Frozen Breakfast Food' },
    { value: 'Frozen Corn On The Cob' },
    { value: 'Frozen Desserts/Topping' },
    { value: 'Frozen Dinners/Entrees' },
    { value: 'Frozen Fruit' },
    { value: 'Frozen Meat' },
    { value: 'Frozen Novelties' },
    { value: 'Frozen Pasta' },
    { value: 'Frozen Pies' },
    { value: 'Frozen Pizza' },
    { value: 'Frozen Plain Vegetables' },
    { value: 'Frozen Potatoes/Onions' },
    { value: 'Frozen Pot Pies' },
    { value: 'Frozen Prepared Vegetables' },
    { value: 'Frozen Seafood' },
    { value: 'Frozen Side Dishes' },
    { value: 'Furniture Polish' },
    { value: 'Gastrointestinal – Liquid' },
    { value: 'Gastrointestinal – Tablets' },
    { value: 'Gelatin/Pudding Prd And Mixes' },
    { value: 'Glass Bottle (Beverages)' },
    { value: 'Gloves' },
    { value: 'Gravy/Sauce Mixes' },
    { value: 'Greeting Cards' },
    { value: 'Gum' },
    { value: 'Hair Accessories' },
    { value: 'Hair Coloring' },
    { value: 'Hair Conditioner' },
    { value: 'Hair Spray/Spritz' },
    { value: 'Hair Styling Gel/Mousse' },
    { value: 'Hand & Body Lotion' },
    { value: 'Healthcare' },
    { value: 'Higher Education' },
    { value: 'Hot Cereal' },
    { value: 'Household Cleaner' },
    { value: 'Household Cleaner Cloths' },
    { value: 'Ice Cream/Sherbet' },
    { value: 'Ice Cream Cones/Mixes' },
    { value: 'Instant Potatoes' },
    { value: 'Internal Analgesics' },
    { value: 'Jellies/Jams/Honey' },
    { value: 'Juices – Frozen' },
    { value: 'Kitchen Storage' },
    { value: 'Laundry Care' },
    { value: 'Laundry Detergent' },
    { value: 'Lawn Care' },
    { value: 'Light Bulbs' },
    { value: 'Lighters' },
    { value: 'Lottery' },
    { value: 'Luncheon Meats' },
    { value: 'Lunches – Refrigerated' },
    { value: 'Margarine/Spreads/Butter Blen' },
    { value: 'Marshmallows' },
    { value: 'Mayonnaise' },
    { value: 'Mexican Foods' },
    { value: 'Mexican Sauce' },
    { value: 'Milk' },
    { value: 'Milk Flavoring/Cocoa Mixes' },
    { value: 'Misc. Snacks' },
    { value: 'Misc Health Remedies' },
    { value: 'Moist Towelettes' },
    { value: 'Motor Oil' },
    { value: 'Mouthwash' },
    { value: 'Mustard & Ketchup' },
    { value: 'Nasal Products' },
    { value: 'Natural Cheese' },
    { value: 'Non-Chocolate Candy' },
    { value: 'Non-Fruit Drinks' },
    { value: 'Office Products' },
    { value: 'Online Gaming' },
    { value: 'Other Frozen Foods' },
    { value: 'Other Refrigerated Products' },
    { value: 'Pancake Mixes' },
    { value: 'Pantyhose/Nylons' },
    { value: 'Paper Napkins' },
    { value: 'Paper Towels' },
    { value: 'Pasta' },
    { value: 'Pasta – Refrigerated' },
    { value: 'Pastry/Doughnuts' },
    { value: 'Peanut Butter' },
    { value: 'Pest Control' },
    { value: 'Pet Supplies' },
    { value: 'Pickles/Relish/Olives' },
    { value: 'Pickles/Relish – Refrigerated' },
    { value: 'Pies & Cakes' },
    { value: 'Pizza Products' },
    { value: 'Pizza – Refrigerated' },
    { value: 'Popcorn/Popcorn Oil' },
    { value: 'Premixed Cocktails/Coolers' },
    { value: 'Processed Cheese' },
    { value: 'Processed Frozen/Refrigerated Poultry' },
    { value: 'Programmatic' },
    { value: 'Razors' },
    { value: 'Refrigerated Dips' },
    { value: 'Refrigerated Entrees' },
    { value: 'Refrigerated Fresh Eggs' },
    { value: 'Refrigerated Juices/Drinks' },
    { value: 'Refrigerated Meat' },
    { value: 'Refrigerated Salad/Coleslaw' },
    { value: 'Refrigerated Side Dishes' },
    { value: 'Refrigerated Teas/Coffee' },
    { value: 'Refrigerated Tortlla/Eggrll/Wontn Wrap' },
    { value: 'Refrigerated Whipped Toppings' },
    { value: 'Rice' },
    { value: 'Rice/Popcorn Cakes' },
    { value: 'Rug/Upholstery/Fabric Treatmt' },
    { value: 'Salad Dressing – Refrigerated' },
    { value: 'Salad Dressings' },
    { value: 'Salad Toppings' },
    { value: 'Salty Snacks' },
    { value: 'Sanitary Napkins/Tampons' },
    { value: 'Seafood' },
    { value: 'Seafood – Refrigerated' },
    { value: 'Shampoo' },
    { value: 'Shaving Cream' },
    { value: 'Shaving Lotion/Mens Fragrance' },
    { value: 'Shortening & Oil' },
    { value: 'Skin Care' },
    { value: 'Sleeping Remedies' },
    { value: 'Smokeless Tobacco' },
    { value: 'Snack Bars/Granola Bars' },
    { value: 'Snack Nuts/Seeds/Corn Nuts' },
    { value: 'Soap' },
    { value: 'Socks' },
    { value: 'Soup' },
    { value: 'Sour Cream' },
    { value: 'Spaghetti/Italian Sauce' },
    { value: 'Specialty Nut Butter' },
    { value: 'Spices/Seasonings' },
    { value: 'Spirits/Liquor' },
    { value: 'Sponges & Scouring Pads' },
    { value: 'Sporting Goods' },
    { value: 'Sports Drinks' },
    { value: 'Spreads – Refrigerated' },
    { value: 'Ss Dinners' },
    { value: 'Ss Meat & Refrigerated Ham' },
    { value: 'Stamps' },
    { value: 'Steak/Worcestershire Sauce' },
    { value: 'Stuffing Mixes' },
    { value: 'Sugar' },
    { value: 'Sugar Substitutes' },
    { value: 'Suntan Products' },
    { value: 'Syrup/Molasses' },
    { value: 'Tea/Coffee Ready-To-Drink' },
    { value: 'Tea – Bags/Loose' },
    { value: 'Tea – Instant Tea Mixes' },
    { value: 'Toaster Pastries/Tarts' },
    { value: 'Toilet Tissue' },
    { value: 'Tomato Products' },
    { value: 'Toothbrush/Dental Accesories' },
    { value: 'Toothpaste' },
    { value: 'Utilities' },
    { value: 'Vegetables' },
    { value: 'Vinegar' },
    { value: 'Vitamins' },
    { value: 'Water Filters/Devices' },
    { value: 'Water Softeners/Treatment' },
    { value: 'Weight Con/Nutrition Liq/Pwd' },
    { value: 'Wine' },
    { value: 'Writing Instruments' },
    { value: 'Yogurt' },
  ];

  if (options.includeEmpty === true) {
    list.unshift({ value: '' });
  }

  return list;
}

export default {
  list,
};
