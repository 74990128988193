import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  set,
  isEmpty,
  isEqual,
  isUndefined,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  updateParams,
} from '../../../helpers/Navigation';
import {
  saveSortBy,
  removeSetting,
} from '../../../helpers/Table';
import API from '../../../api';
import AdvancedSearch from './AdvancedSearch';
import Field from '../../Field/Field2';
import VibeIcon from '../../VibeIcon/VibeIcon';
import viArrowUpDown from '../../../icons/viArrowUpDown';
import viArrowUp from '../../../icons/viArrowUp';
import viArrowDown from '../../../icons/viArrowDown';
import viClose from '../../../icons/viClose';
import color from '../../../sass/color.scss';
// import defaultCompanyImage from '../../../assets/default_company.png';
import './HeaderCell.scss';

class HeaderCell extends PureComponent {
  constructor(props) {
    super(props);

    const {
      column: {
        sortAttr,
        searchAttr,
      },
      filters,
      bulkEdit,
      bulkEditData,
    } = props;

    this.onSearchTimeout = null;

    if (filters[searchAttr] === true || filters[searchAttr] === false) {
      filters[searchAttr] = filters[searchAttr]
        ? 'true'
        : 'false';
    }

    this.state = {
      sortAttr: sortAttr || searchAttr,
      searchValue: bulkEdit
        ? bulkEditData[searchAttr] || ''
        : filters[searchAttr] || '',
      selectorValue: '',
    };
  }

  componentDidUpdate(prevProps) {
    const {
      column: {
        name,
        sortAttr,
        searchAttr,
      },
      filters,
    } = this.props;

    const {
      column: {
        name: prevName,
      },
      filters: prevFilters,
    } = prevProps;

    const {
      searchValue,
    } = this.state;

    // a column most likely changed its visibility rendering a different column with the old content
    if (name !== prevName) {
      // console.log('Column Changed', prevName, 'to', name);

      this.setState({
        sortAttr: sortAttr || searchAttr,
        searchValue: filters[searchAttr] || '',
        selectorValue: filters[searchAttr] || '',
      });
    }

    // clear the search value textbox when a filter no longer exists
    if (searchValue
      && !filters[searchAttr]
      && prevFilters[searchAttr]
    ) {
      console.log('Filter Cleared:', searchAttr);

      this.setState({
        searchValue: '',
        selectorValue: '',
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.onSearchTimeout);
  }

  onToggleSort = () => {
    const {
      tableId,
      column,
      sortBy: currSortBy,
      defaultSortBy,
      onUpdate,
      onToggleSort,
    } = this.props;

    const {
      sortAttr,
    } = this.state;

    const sortBy = {
      label: column.name,
      attr: sortAttr,
      direction: currSortBy.direction === 'asc' && currSortBy.attr === sortAttr
        ? 'desc'
        : 'asc',
    };

    if (isEqual(sortBy, defaultSortBy)) {
      // sorting by the table default, remove the custom setting
      removeSetting({
        tableId,
        setting: 'sortBy',
      });
    } else {
      // save the custom sort setting
      saveSortBy({
        tableId,
        sortBy,
      });
    }

    onUpdate({
      refresh: true,
      data: {
        sortBy,
      },
    });

    onToggleSort();
  };

  /**
   * When a field is blurred
   */
  onBlur = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const {
      column,
      onChangeBulkEditColumn,
    } = this.props;

    let fieldValue = value;

    if (column.dataType === 'array') {
      // keep value an array
      fieldValue = value.split(',').map(item => item.trim()).filter(item => item !== '');

      const searchBehavior = this.getSearchBehavior();

      // only when bulk editing do we need to keep the data type an array
      if (searchBehavior === 'edit') {
        onChangeBulkEditColumn({
          columnName: name,
          value: fieldValue,
        });
      }
    }
  };

  onChangeSearch = (e) => {
    const {
      urlFilters,
      filters,
      column: {
        name,
        searchAttr,
      },
      history,
      onChangeBulkEditColumn,
      onUpdate,
    } = this.props;

    const {
      target: {
        value,
      },
    } = e;

    const searchBehavior = this.getSearchBehavior();

    // trim all spaces from the phone number field
    const useValue = name === 'Phone #'
      ? value.replace(/ /g, '')
      : value;

    this.setState({
      searchValue: useValue,
      // when the clear search icon is clicked we need this to clear the value if its a selector cell
      // need to only update when the clear search icon is clicked
      // selectorValue: value,
    });

    if (searchBehavior === 'search') {
      // searching against the table data
      clearTimeout(this.onSearchTimeout);

      this.onSearchTimeout = setTimeout(() => {
        if (urlFilters) {
          const path = updateParams({
            [searchAttr]: useValue,
          });

          history(path);
        }

        const noValue = isEmpty(useValue);

        if (noValue) {
          // remove the filter key
          delete filters[searchAttr];

          // remove the selector value to clear the header search input box
          this.setState({
            selectorValue: '',
          });
        }

        onUpdate({
          refresh: true,
          data: {
            pageNumber: 1,
            filters: noValue
              ? filters
              : {
                ...filters,
                [searchAttr]: useValue,
              },
          },
        });
      }, 500);
    } else if (searchBehavior === 'edit') {
      // prepping the bulk edit data
      onChangeBulkEditColumn({
        columnName: name,
        value: useValue,
      });
    }
  };

  onChangeDate = (searchDate) => {
    const {
      urlFilters,
      filters,
      column: {
        name,
        searchAttr,
      },
      history,
      onChangeBulkEditColumn,
      onUpdate,
    } = this.props;

    const searchBehavior = this.getSearchBehavior();

    this.setState({
      searchValue: searchDate,
    });

    if (searchBehavior === 'search') {
      clearTimeout(this.onSearchTimeout);
      this.onSearchTimeout = setTimeout(() => {
        if (urlFilters) {
          const path = updateParams({
            [searchAttr]: searchDate,
          });

          history(path);
        }

        const noValue = isEmpty(searchDate);

        if (noValue) {
          // remove the filter key
          delete filters[searchAttr];
        }

        onUpdate({
          refresh: true,
          data: {
            pageNumber: 1,
            filters: noValue
              ? filters
              : {
                ...filters,
                [searchAttr]: searchDate,
              },
          },
        });
      }, 500);
    } else if (searchBehavior === 'edit') {
      // prepping the bulk edit data
      onChangeBulkEditColumn({
        columnName: name,
        value: searchDate,
      });
    }
  };

  /**
   * When the tag field is changed
   */
  onChangeTag = (data) => {
    const {
      column: {
        name: columnName,
      },
      onChangeBulkEditColumn,
    } = this.props;

    // only allow tags to be added, not removed in bulk
    const addedTags = data.tags.filter(tag => tag.status === 'add');

    onChangeBulkEditColumn({
      columnName,
      value: addedTags,
    });
  };

  /**
   * When the country field is changed
   */
  // onChangeCountry = (e) => {
  //   const {
  //     target: {
  //       value,
  //     },
  //   } = e;

  //   this.setState({
  //     searchValue: value,
  //   });
  // };

  /**
   * When the selector field input is changed
   */
  onChangeSelector = (data) => {
    const {
      id,
      type,
      value,
      // only updated the search when a field item is selected or cleared
      changed = true,
    } = data;

    this.setState((state) => {
      return {
        searchValue: type === 'category'
          || type === 'banner'
          || type === 'delivery system'
          || type === 'network owner'
          || type === 'advertiser'
          // || type === 'partner'
          // use the value of the selector for search query (company category, company banner)
          // this is so the user can type in the input box
          ? value
          // use the existing search query until a selection is made
          : state.searchValue,
        selectorValue: value,
      };
    });

    const e = {};

    // only update the search on the table when an ID is provided
    // or when the search input is cleared
    if (
      (id && changed)
      || (changed && isEmpty(id) && isEmpty(value))
    ) {
      const useIdOrValue = type === 'category'
        || type === 'banner'
        || type === 'delivery system'
        || type === 'network owner'
        || type === 'advertiser'
        // || type === 'partner'
        // use the value of the selector for search query (company category, company banner)
        ? value
        // use the ID of the selector for the search query
        : id;

      set(e, 'target.value', useIdOrValue);
      this.onChangeSearch(e);
    }
  };

  /**
   * When searching using the advanced search modal
   */
  onSearchAdvanced = ({
    columnName,
    searchValues,
  }) => {
    const e = {};
    set(e, 'target.value', searchValues.join(','));

    if (columnName === 'DMA' && searchValues.length > 1) {
      this.setState({
        searchValue: '',
        selectorValue: '',
      });
    }

    this.onChangeSearch(e);
  };

  onClearSearch = () => {
    const e = {};
    set(e, 'target.value', '');

    this.onChangeSearch(e);
  };

  /**
   * Get the search behavior. Are we searching the data or bulk editing?
   */
  getSearchBehavior = () => {
    const {
      column,
      bulkEdit,
    } = this.props;

    if (!bulkEdit && column.searchable) {
      return 'search';
    }

    if (bulkEdit && column.editable) {
      return 'edit';
    }

    return null;
  };

  /**
   * Get the Search Element (text, dropdown, datepicker, etc)
   */
  getSearchElement = (searchBehavior) => {
    const {
      user,
      column: {
        name,
        dataType,
        searchAttr,
        searchAdvanced,
        searchSelector,
        datepicker,
        dropdownItems = [],
      },
      bulkEdit,
      bulkEditData,
      companyIds,
      permissionPrefix,
      autoFocus,
      columnVisibilityChanged,
      partnerId,
    } = this.props;

    const {
      searchValue,
      selectorValue,
    } = this.state;

    const placeholder = searchBehavior === 'edit'
      ? 'Edit...'
      : 'Search...';

    // ensure no clashing with other tab indexes (i.e. fields in sidebars)
    const tabIndex = 100;

    // Render a datepicker calendar when selected
    if (datepicker) {
      return (
        <Field
          type="datepicker"
          placeholder={placeholder}
          value={searchValue}
          dateProps={{
            startOfDay: true,
            format: 'YYYY-MM-DD',
          }}
          multipleValues={searchAdvanced}
          tabIndex={tabIndex}
          onChange={this.onChangeDate}
        />
      );
    }

    // Render a multi-select dropdown
    if (dropdownItems.length > 0 && dataType === 'array-multiple') {
      return (
        <Field
          type="select-multiple"
          placeholder={placeholder}
          value={searchValue}
          options={dropdownItems}
          multipleValues={searchAdvanced}
          tabIndex={tabIndex}
          onChange={this.onChangeSearch}
        />
      );
    }

    // Render a dropdown of selectable items (max 1 selected item)
    if (dropdownItems.length > 0) {
      return (
        <Field
          type="select"
          selectProps={{
            // do not show dropdown arrow for the select input if allowing advanced search
            hideArrow: searchAdvanced,
          }}
          placeholder={placeholder}
          value={!columnVisibilityChanged
            ? searchValue
            : ''}
          options={dropdownItems}
          multipleValues={searchAdvanced}
          tabIndex={tabIndex}
          onChange={this.onChangeSearch}
        />
      );
    }

    if (bulkEdit && name === 'Tags') {
      // automatically allow admin tags
      const allowTypes = ['admin'];

      if (companyIds.length === 1) {
        // only allow client tags if only editing data assigned to the same company
        allowTypes.push('client');
      }

      return (
        <Field
          type="tags"
          label="Tags"
          name="tags"
          tagProps={{
            companyId: companyIds[0],
            tags: get(bulkEditData, 'tags', []),
            allowTypes,
            showAs: 'list',
            assign: user.can(`${permissionPrefix}.assign_tag`),
          }}
          hideLabel
          multipleValues={searchAdvanced}
          onChange={this.onChangeTag}
          tabIndex={tabIndex}
        />
      );
    }

    // Render Ad Network Owner
    if (name === 'Ad Network Owner') {
      return (
        <Field
          type="dropdown"
          name="adNetworkOwner"
          value={searchValue}
          dropdownProps={{
            type: 'network owner',
            attr: 'value',
            fetch: API.LookupList.adNetworkOwners.search,
          }}
          onChange={this.onChangeSelector}
        />
      );
    }

    // Render Ad Delivery System
    if (name === 'Ad Delivery System') {
      return (
        <Field
          type="dropdown"
          name="adDeliverySystem"
          value={searchValue}
          dropdownProps={{
            type: 'delivery system',
            attr: 'value',
            fetch: API.LookupList.adDeliverySystems.search,
          }}
          onChange={this.onChangeSelector}
        />
      );
    }

    // Render Content Network Owner
    if (name === 'Content Network Owner') {
      return (
        <Field
          type="dropdown"
          name="contentNetworkOwner"
          value={searchValue}
          dropdownProps={{
            type: 'network owner',
            attr: 'value',
            fetch: API.LookupList.contentNetworkOwners.search,
          }}
          onChange={this.onChangeSelector}
        />
      );
    }

    // Render Content Delivery System
    if (name === 'Content Delivery System') {
      return (
        <Field
          type="dropdown"
          name="contentDeliverySystem"
          value={searchValue}
          dropdownProps={{
            type: 'delivery system',
            attr: 'value',
            fetch: API.LookupList.contentDeliverySystems.search,
          }}
          onChange={this.onChangeSelector}
        />
      );
    }

    // Render a Company selector
    if (searchSelector === 'company') {
      return (
        <Field
          type="company"
          value={!columnVisibilityChanged
            ? selectorValue
            : ''}
          companyProps={{
            companyId: !columnVisibilityChanged
              ? searchValue
              : '',
            partnerId,
            inputStyle: {
              paddingRight: searchAdvanced
                ? 32
                : 8,
            },
          }}
          multipleValues={searchAdvanced}
          tabIndex={tabIndex}
          onChange={this.onChangeSelector}
        />
      );
    }

    // Render a DMA selector
    if (searchSelector === 'dma') {
      return (
        <Field
          type="dma"
          value={!columnVisibilityChanged
            ? selectorValue
            : ''}
          dmaProps={{
            dmaId: !columnVisibilityChanged
              ? searchValue
              : '',
            inputStyle: {
              paddingRight: searchAdvanced
                ? 32
                : 8,
            },
          }}
          multipleValues={searchAdvanced}
          tabIndex={tabIndex}
          onChange={this.onChangeSelector}
        />
      );
    }

    if (searchSelector === 'partner' && user.can('partner.view')) {
      return (
        <Field
          type="dropdown"
          name="partnerName"
          value={!columnVisibilityChanged
            ? selectorValue
            : ''}
          tabIndex={tabIndex}
          dropdownProps={{
            type: 'partner',
            attr: 'name',
            // imageAttr: 'imageUrl',
            // defaultImage: defaultCompanyImage,
            fetch: API.Partner.list,
            inputStyle: {
              paddingRight: searchAdvanced
                ? 32
                : 8,
            },
          }}
          multipleValues={searchAdvanced}
          onChange={this.onChangeSelector}
        />
      );
    }

    if (searchSelector === 'company-category' && user.can('company_category.view')) {
      return (
        <Field
          type="dropdown"
          name="category"
          value={!columnVisibilityChanged
            ? searchValue
            : ''}
          tabIndex={tabIndex}
          dropdownProps={{
            type: 'category',
            attr: 'value',
            fetch: API.Company.Category.list,
            create: API.Company.Category.create,
            // canCreate: user.can('company_category.create'),
            // successMessage: 'COMPANYCATEGORY.CREATED',
            inputStyle: {
              paddingRight: searchAdvanced
                ? 32
                : 8,
            },
          }}
          multipleValues={searchAdvanced}
          onChange={this.onChangeSelector}
        />
      );
    }

    if (searchSelector === 'delivery-system' && user.can('content_delivery_system.view')) {
      return (
        <Field
          type="dropdown"
          name="deliverySystem"
          value={!columnVisibilityChanged
            ? searchValue
            : ''}
          tabIndex={tabIndex}
          dropdownProps={{
            type: 'delivery system',
            attr: 'value',
            fetch: API.Flight.DeliverySystem.list,
            create: API.Flight.DeliverySystem.create,
            // canCreate: user.can('company_category.create'),
            // successMessage: 'COMPANYCATEGORY.CREATED',
            style: {
              paddingRight: searchAdvanced
                ? 32
                : 8,
            },
          }}
          multipleValues={searchAdvanced}
          onChange={this.onChangeSelector}
        />
      );
    }

    if (searchSelector === 'advertiser' && user.can('advertiser.view')) {
      return (
        <Field
          type="dropdown"
          name="advertiser"
          value={!columnVisibilityChanged
            ? searchValue
            : ''}
          tabIndex={tabIndex}
          dropdownProps={{
            type: 'advertiser',
            attr: 'name',
            fetch: API.Advertiser.list,
            create: API.Advertiser.create,
            // canCreate: user.can('advertiser.create'),
            // successMessage: 'ADVERTISER.CREATED',
            inputStyle: {
              paddingRight: searchAdvanced
                ? 32
                : 8,
            },
          }}
          multipleValues={searchAdvanced}
          onChange={this.onChangeSelector}
        />
      );
    }

    if (name === 'Country') {
      return (
        <Field
          type="country"
          name="country"
          value={searchValue}
          disabled={!searchAttr}
          onChange={this.onChangeSearch}
        />
      );
    }

    if (name === 'Phone #') {
      return (
        <Field
          type="phone"
          name="phone"
          value={searchValue}
          tabIndex={6}
          disabled={!searchAttr}
          onChange={this.onChangeSearch}
        />
      );
    }

    // Render a plain text field
    return (
      <Field
        type="text"
        placeholder={placeholder}
        name={name}
        value={searchValue}
        autoFocus={autoFocus}
        disabled={!searchAttr}
        multipleValues={searchAdvanced}
        tabIndex={tabIndex}
        onBlur={this.onBlur}
        onChange={this.onChangeSearch}
      />
    );
  };

  render() {
    const {
      className,
      column,
      sortBy,
    } = this.props;

    const {
      sortAttr,
      searchValue,
    } = this.state;

    const searchBehavior = this.getSearchBehavior();
    const searchHtml = searchBehavior
      ? this.getSearchElement(searchBehavior)
      : null;

    const active = sortBy.attr === sortAttr;
    let sortIcon = viArrowUpDown;

    if (active && sortBy.direction === 'asc') {
      sortIcon = viArrowUp;
    } else if (active && sortBy.direction === 'desc') {
      sortIcon = viArrowDown;
    }

    // use the column label if one is defined
    const columnName = isUndefined(column.label)
      ? column.name
      : column.label;

    return (
      <div className={classNames('HeaderCell', className)}>
        <div className={classNames('label-container', { active })}>
          <div className="label">
            {column.icon ? (
              <VibeIcon
                icon={column.icon}
                color={color.obsidian}
                size={16}
                tooltip={columnName}
                tooltipProps={{
                  placement: 'right',
                }}
              />
            ) : columnName}
          </div>

          {column.sortable ? (
            <VibeIcon
              className="cell-sort-icon"
              icon={sortIcon}
              color={active
                ? color.violetVibe
                : color.obsidian}
              hoverColor={color.violetVibe}
              tooltip={`Sort by ${columnName}`}
              tooltipProps={{
                placement: 'left',
              }}
              size={16}
              onClick={this.onToggleSort}
            />
          ) : null}
        </div>

        <div className="search-container">
          {/* Check if the column can search for multiple values */}
          {column.searchAdvanced ? (
            <div
              style={{
                position: 'relative',
              }}
            >
              {searchHtml}

              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 4,
                  height: '100%',
                }}
              >
                <AdvancedSearch
                  column={column}
                  currentSearch={searchValue}
                  onSearch={this.onSearchAdvanced}
                />
              </div>
            </div>
          ) : searchHtml}

          {searchValue
            // not a dropdown
            && get(column, 'dropdownItems.length', 0) <= 0
            // not a multi-value search
            && !column.searchAdvanced
            && !column.name === 'Country'
            ? (
              <VibeIcon
                className="search-clear"
                icon={viClose}
                color={color.manatee}
                hoverColor={color.obsidian}
                size={16}
                onClick={this.onClearSearch}
              />
            )
            : null}
        </div>
      </div>
    );
  }
}

HeaderCell.propTypes = {
  className: PropTypes.string,
  tableId: PropTypes.string.isRequired,
  column: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  sortBy: PropTypes.shape({
    label: PropTypes.string,
    attr: PropTypes.string,
    direction: PropTypes.oneOf([
      'asc',
      'desc',
    ]),
  }).isRequired,
  defaultSortBy: PropTypes.shape({
    label: PropTypes.string,
    attr: PropTypes.string,
    direction: PropTypes.oneOf([
      'asc',
      'desc',
    ]),
  }).isRequired,
  autoFocus: PropTypes.bool,
  urlFilters: PropTypes.bool,
  filters: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Cell is in the bulk edit header */
  bulkEdit: PropTypes.bool,
  bulkEditData: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Company IDs for the selected rows */
  companyIds: PropTypes.arrayOf(PropTypes.string),
  /** Permission prefix for checking a users permission */
  permissionPrefix: PropTypes.string,
  /** When the column is hidden or shown clear the search values until rendered */
  columnVisibilityChanged: PropTypes.bool,
  onUpdate: PropTypes.func,
  onToggleSort: PropTypes.func,
  /** When a bulk edit column is edited */
  onChangeBulkEditColumn: PropTypes.func,
};

HeaderCell.defaultProps = {
  className: '',
  autoFocus: false,
  urlFilters: false,
  filters: {},
  bulkEdit: false,
  bulkEditData: {},
  companyIds: [],
  permissionPrefix: '',
  columnVisibilityChanged: false,
  onUpdate: () => {},
  onToggleSort: () => {},
  onChangeBulkEditColumn: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default withRouter(connect(mapStateToProps)(HeaderCell));
