/**
 * List of supported Segment Types
 * @param options object of options to build the list
 * @param options.includeEmpty boolean to include an empty name/value pair at the beginning
 */
export function list(options = {}) {
  const list = [
    {
      label: 'General',
      value: 'general',
    },
    {
      label: 'Endemic',
      value: 'endemic',
    },
    {
      label: 'Non Endemic',
      value: 'non-endemic',
    },
    {
      label: 'Adult Beverage',
      value: 'adult-beverage',
    },
  ];

  if (options.includeEmpty === true) {
    list.unshift({
      label: '',
      value: '',
    });
  }

  return list;
}

export default {
  list,
};
