import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  API,
  GlobalActions,
  ExpandedLocationsHelper,
  LoadingContent,
} from 'vibeguide';
import PartnerDetails from './PartnerDetails';

function PartnerSidebar({
  className,
  style,
  partnerId,
  isNew,
  setPanel,
  user,
}) {
  const [partner, setPartner] = useState({});

  const getPartner = async () => {
    const partner = await API.Partner.get(partnerId);

    if (user.can('partner.view_companies')) {
      const expandedCompanies = await API.Partner.getExpandedLocations({
        _id: partnerId,
      });
      partner.expandedCompanies = ExpandedLocationsHelper.getLocationsData(expandedCompanies).companies;
    }

    setPartner(partner);
  };

  useEffect(() => {
    if (partnerId) {
      getPartner();
    }
  }, [partnerId]);

  const onUpdate = (data) => {
    setPartner({
      ...partner,
      ...data,
    });
  };

  const onClose = () => {
    setPanel({
      show: false,
    });
  };

  return (
    <div
      className={classNames('PartnerSidebar', className)}
      style={{
        height: '100%',
        ...style,
      }}
    >
      <PartnerDetails
        partner={partner}
        isNew={isNew}
        onClose={onClose}
        onUpdate={onUpdate}
      />

      {partnerId && !partner._id && (
        <LoadingContent />
      )}
    </div>
  );
}

PartnerSidebar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  partnerId: PropTypes.string,
  isNew: PropTypes.bool,
};

PartnerSidebar.defaultProps = {
  className: null,
  style: {},
  partnerId: null,
  isNew: false,
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerSidebar);
