import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VibeIcon from '../VibeIcon/VibeIcon';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import {
  setDrag,
  resetDrag,
} from '../../actions/Global/GlobalActions';
import API from '../../api';
import TableBaselines from '../VibeTable/Tables/TableBaselines';
import TablePlaylists from '../VibeTable/Tables/TablePlaylists';
import TableStations from '../VibeTable/Tables/TableStations';
import TableMessageBlocks from '../VibeTable/Tables/TableMessageBlocks';
import TableMessages from '../VibeTable/Tables/TableMessages';
import TableSongs from '../VibeTable/Tables/TableSongs';
import color from '../../sass/color.scss';
import './DrawerItem.scss';

function renderBaselines(fetch, filters) {
  return (
    <TableBaselines
      columns={[
        'Name',
        'Company',
        'Description',
        'Locations',
        'Modified',
        'Tags',
        '...',
      ]}
      fetch={fetch}
      filters={filters}
      defaultSortBy={{
        label: 'Name',
        attr: 'name',
        direction: 'asc',
      }}
      paginator
      paginatorProps={{
        label: 'Baselines',
        urlPaging: false,
        urlFilters: false,
      }}
      menuItems={[
        { name: 'Archive', userCan: 'baseline.delete' },
      ]}
      draggable
      dragProps={{
        dragMultiple: false,
        duplicateOnDrop: true,
        contentDrawer: true,
      }}
    />
  );
}

function renderPlaylists(fetch, filters, onSetHeaderContent) {
  return (
    <TablePlaylists
      columns={[
        'Name',
        'Company',
        'Songs',
        'Duration',
        'Avg. Song',
        'Genre',
        'Tags',
        '...',
      ]}
      fetch={fetch}
      filters={filters}
      defaultSortBy={{
        label: 'Name',
        attr: 'name',
        direction: 'asc',
      }}
      paginator
      paginatorProps={{
        label: 'Playlists',
        urlPaging: false,
        urlFilters: false,
      }}
      menuItems={[
        { name: 'Archive', userCan: 'playlist.delete' },
      ]}
      draggable
      dragProps={{
        dragMultiple: false,
        duplicateOnDrop: true,
        contentDrawer: true,
      }}
      onClickRow={async (playlist) => {
        const playlistResponse = await API.Playlist.getById(playlist._id);
        const songs = playlistResponse.musicItems;

        onSetHeaderContent((
          <div>
            {playlistResponse.name}
          </div>
        ), (
          <TableSongs
            columns={[
              'Song Title',
              'Artist',
              'Album',
              'Genre',
              'ISRC',
              'ISWC',
              'Record Label',
              'Source',
              'Release Year',
              'Duration',
              'Tempo',
              'Ranking',
              'Rating',
              'Reason',
              'Playlists',
              'Modified',
              'Tags',
              '...',
            ]}
            collection={songs}
            defaultSortBy={{
              label: 'Song Title',
              attr: 'name',
              direction: 'asc',
            }}
            paginator
            paginatorProps={{
              label: 'Songs',
              urlPaging: false,
              urlFilters: false,
            }}
            menuItems={[
              { name: 'Archive', userCan: 'music.delete' },
            ]}
          />
        ));
      }}
    />
  );
}

function renderStations(fetch, filters) {
  return (
    <TableStations
      columns={[
        'Name',
        'Description',
        'Company',
        'Duration',
        'Playlists',
        'Modified',
        'Tags',
        '...',
      ]}
      fetch={fetch}
      filters={filters}
      defaultSortBy={{
        label: 'Name',
        attr: 'name',
        direction: 'asc',
      }}
      paginator
      paginatorProps={{
        label: 'Stations',
        urlPaging: false,
        urlFilters: false,
      }}
      menuItems={[
        { name: 'Archive', userCan: 'mix.delete' },
      ]}
      draggable
      dragProps={{
        dragMultiple: false,
        duplicateOnDrop: true,
        contentDrawer: true,
      }}
    />
  );
}

function renderMessageBlocks(fetch, filters) {
  return (
    <TableMessageBlocks
      columns={[
        'Name',
        'Company',
        'Integration',
        'Frequency',
        'Consecutive',
        'Start Type',
        'Sequence',
        'Locations',
        'Tags',
        '...',
      ]}
      fetch={fetch}
      filters={filters}
      defaultSortBy={{
        label: 'Name',
        attr: 'name',
        direction: 'asc',
      }}
      paginator
      paginatorProps={{
        label: 'Message Blocks',
        urlPaging: false,
        urlFilters: false,
      }}
      menuItems={[
        { name: 'Archive', userCan: 'messagelist.delete' },
      ]}
      draggable
      dragProps={{
        dragMultiple: false,
        duplicateOnDrop: true,
        contentDrawer: true,
      }}
    />
  );
}

function renderMessages(fetch, filters, label) {
  return (
    <TableMessages
      columns={[
        'Message Name',
        'Company',
        'Last Updated',
        'Requester',
        'Start Date',
        'End Date',
        'Duration',
        'Script',
        'Assigned Voice Talent',
        'Tags',
        '...',
      ]}
      fetch={fetch}
      filters={filters}
      defaultSortBy={{
        label: 'Name',
        attr: 'name',
        direction: 'asc',
      }}
      paginator
      paginatorProps={{
        label,
        urlPaging: false,
        urlFilters: false,
      }}
      menuItems={(row) => {
        const isAd = row.messageType === 'ad'
          || row.messageType === 'ext-ad';

        return [{
          name: 'Archive',
          userCan: isAd
            ? 'advertisement.delete'
            : 'message.delete',
        }];
      }}
      draggable
      dragProps={{
        dragMultiple: false,
        duplicateOnDrop: true,
        contentDrawer: true,
      }}
    />
  );
}

function DrawerItem({
  className,
  label,
  icon,
  tooltip,
  draggable,
  active,
  disabled,
  fetch,
  filters,
  setDrag,
  resetDrag,
  onSetHeaderContent,
  onSetContent,
}) {
  const [hover, setHover] = useState(false);
  const [hideTooltip, setHideTooltip] = useState(false);

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  const onDragStart = (e) => {
    const {
      dataTransfer,
    } = e;

    // Set drag data
    dataTransfer.setData('schedule:data', JSON.stringify({
      type: label,
    }));

    // hide the tooltip for the drawer so it doesn't stay active while dragging
    setHideTooltip(true);

    // set the drag data
    setDrag({
      dragging: true,
      dragType: label,
    });
  };

  const onDragEnd = () => {
    // allow the tooltip to be seen once dragging has stopped
    setHideTooltip(false);
    resetDrag();
  };

  const onClick = async () => {
    if (!fetch) {
      return;
    }

    if (active) {
      // drawer item is already active, close the drawer
      onSetContent(label, null);
      return;
    }

    let content;

    switch (label) {
      case 'Baselines':
        content = renderBaselines(fetch, filters);
        break;

      case 'Playlists':
        content = renderPlaylists(fetch, filters, onSetHeaderContent);
        break;

      case 'Stations':
        content = renderStations(fetch, filters);
        break;

      case 'Message Blocks':
        content = renderMessageBlocks(fetch, filters);
        break;

      case 'Interrupts':
        content = renderMessages(fetch, filters, 'Interrupts');
        break;

      case 'Ads':
        content = renderMessages(fetch, filters, 'Ads');
        break;

      default:
        content = (
          <div>
            Unknown
          </div>
        );
        break;
    }

    onSetContent(label, content);
  };

  return (
    <VibeTooltip
      title={!hideTooltip
        ? tooltip
        : ''}
      placement="top"
      enterDelay={500}
      show={!hideTooltip}
      arrow
    >
      <div
        className={classNames('DrawerItem', className, { active, disabled })}
        draggable={draggable}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onClick={onClick}
      >
        {icon ? (
          <VibeIcon
            className="item-icon"
            icon={icon}
            // eslint-disable-next-line no-nested-ternary
            color={disabled
              ? color.manatee
              : hover
                ? color.white
                : color.violetVibe}
            size={16}
          />
        ) : null}

        {label}
      </div>
    </VibeTooltip>
  );
}

DrawerItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  icon: PropTypes.func,
  tooltip: PropTypes.string,
  draggable: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  fetch: PropTypes.func,
  filters: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onSetHeaderContent: PropTypes.func,
  onSetContent: PropTypes.func,
};

DrawerItem.defaultProps = {
  className: '',
  icon: null,
  tooltip: '',
  draggable: false,
  active: false,
  disabled: false,
  fetch: null,
  filters: {},
  onSetHeaderContent: () => {},
  onSetContent: () => {},
};

const mapDispatchToProps = {
  setDrag,
  resetDrag,
};

export default connect(null, mapDispatchToProps)(DrawerItem);
