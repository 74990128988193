import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  API,
  TableMessages,
  NavigationHelper,
  GlobalActions,
  viAdd,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../../Header/SubNavigation';
import MessageSidebar from '../NewMessageRequest/Sidebar/MessageSidebar';
import './LibraryContainer.scss';

class LibraryContainer extends Component {
  constructor(props) {
    super(props);

    this.sidebarTimeout = null;
  }

  componentDidMount() {
    const qs = NavigationHelper.getParams();
    const isNew = qs.type === 'new';
    const isView = qs.messageId && qs.type !== 'new';

    if (isNew || isView) {
      this.sidebarTimeout = setTimeout(() => {
        this.sidebar(qs.type, qs.messageId);
      }, 1500);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        search,
      },
    } = this.props;

    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.messageId || qs.type === 'new') {
        this.sidebar(qs.type, qs.messageId);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.sidebarTimeout);
  }

  /**
   * When the sidebar is closed
   */
  onCloseSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      messageId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  /**
   * Get the columns for Ad Type Messages based on user context
   */
  getAdColumns = () => {
    const {
      user,
    } = this.props;

    const isISMAdmin = user.accountType !== 'partner' && user.can('admin.ism_admin');
    const isContentCurator = user.can('message.content_curator');
    const isClientUser = user.accountType !== 'partner';

    if (isISMAdmin || isContentCurator) {
      return [
        '.',
        'Message Name',
        'Company',
        'Partner',
        'Advertiser',
        'Last Updated',
        'Requester',
        'Start Date',
        'End Date',
        'Duration',
        'Script',
        'Assigned Voice Talent',
        'Tags',
        '...',
      ];
    }

    if (isClientUser) {
      return [
        '.',
        'Message Name',
        'Company',
        'Advertiser',
        'Last Updated',
        'Requester',
        'Start Date',
        'End Date',
        'Duration',
        'Script',
        'Assigned Voice Talent',
        'Tags',
        '...',
      ];
    }

    return [
      '.',
      'Message Name',
      'Advertiser',
      'Last Updated',
      'Requester',
      'Start Date',
      'End Date',
      'Duration',
      'Script',
      'Assigned Voice Talent',
      'Tags',
      '...',
    ];
  };

  /**
   * Determine whether or not to show or hide the sidebar
   */
  sidebar = (type, messageId = null) => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: true,
      backdrop: type === 'new',
      children: (
        <MessageSidebar
          type={type === 'new' && !messageId
            // creating a new message request
            ? 'request'
            // possibly re-requesting a message from the library
            : 'library'}
          messageId={messageId}
          isNew={type === 'new'}
          isDuplicate={type === 'new' && messageId !== null}
        />
      ),
      onClose: this.onCloseSidebar,
    });
  };

  render() {
    const {
      user,
    } = this.props;

    return (
      <div className="LibraryContainer">
        <SubNavigation
          title="Message Library"
          buttons={[
            {
              text: 'New Message',
              icon: viAdd,
              url: NavigationHelper.updateParams({
                type: 'new',
              }),
              userAnyOf: [
                'message.request',
                'advertisement.request',
              ],
            },
          ]}
        />

        <TableMessages
          columns={[
            '.',
            'Message Name',
            'Company',
            'Last Updated',
            'Requester',
            'Start Date',
            'End Date',
            'Duration',
            'Script',
            'Assigned Voice Talent',
            'Tags',
            '...',
          ]}
          fetch={API.Message.list}
          defaultSortBy={{
            label: 'Last Updated',
            attr: 'modifiedDate',
            direction: 'desc',
          }}
          paginator
          paginatorProps={{
            label: 'Messages',
            urlPaging: true,
            urlFilters: true,
          }}
          toggle
          toggleProps={{
            label: 'Show Archived Messages',
            tooltip: true,
          }}
          bulk
          bulkProps={{
            edit: true,
            add: false,
            block: false,
            remove: false,
            archive: true,
          }}
          submenu
          submenuProps={{
            rootUrl: '/messages/library',
            items: [
              {
                name: 'Regular',
                show: user.can('message.view'),
                enabled: true,
                paginatorLabel: 'Messages',
              },
              {
                name: 'Ads',
                show: user.can('advertisement.view'),
                enabled: true,
                filters: {
                  messageType: 'ad',
                },
                columns: this.getAdColumns(),
              },
              {
                name: 'External Ads',
                show: user.can('advertisement.view'),
                enabled: true,
                filters: {
                  messageType: 'ext-ad',
                },
                columns: this.getAdColumns(),
              },
              {
                name: 'Sounders',
                show: user.isAdminCompany && user.can('message.view'),
                enabled: false,
                filters: {
                  messageType: 'sounder',
                },
              },
              {
                name: 'Air Checks',
                show: user.isAdminCompany && user.can('message.view'),
                enabled: true,
                filters: {
                  messageType: 'air',
                },
              },
              {
                name: 'Spec Spots',
                show: user.isAdminCompany && user.can('message.view'),
                enabled: true,
                filters: {
                  messageType: 'spec',
                },
              },
            ],
          }}
          rowLink={{
            messageId: '_id',
          }}
          menuItems={(row) => {
            const isAd = row.messageType === 'ad'
              || row.messageType === 'ext-ad';

            return [{
              name: 'Archive',
              userCan: isAd
                ? 'advertisement.delete'
                : 'message.delete',
            }];
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LibraryContainer));
